<template>
	<div class="main">
		<PageComponent :title_nav="'Integrações'">
			<div slot="slot-pages" class="content-pages">
				<div class="products">
					<div class="products-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'dashboard' }">
								<font-awesome-icon :icon="['fas', 'arrow-left']" />
								Início
							</b-breadcrumb-item>
							<b-breadcrumb-item active>Processos em lote</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->

						<b-row>
							<b-col md="9">
								<h5>Processos em lote</h5>
							</b-col>
							<b-col md="3"  v-if="userLoggedPermission.permissionsyncprocessm == 1 || userLogged.role == 'Administrador'">
								<!-- <b-card class="col-add-programming">
									<h4>Tipo</h4>
									<b-button class="add-lead" @click="showModal()">
										<font-awesome-icon :icon="['fas', 'plus']" class="" />
										Importar Compras
									</b-button>
									<b-button class="add-lead" @click="showModalSell()">
										<font-awesome-icon :icon="['fas', 'plus']" class="" />
										Importar Vendas
									</b-button>
									<b-button class="add-lead" @click="showModalStock()">
										<font-awesome-icon :icon="['fas', 'plus']" class="" />
										Importar estoque
									</b-button>
								</b-card> -->
							</b-col>
						</b-row>

						<b-row>
							<div class="col-md-12 card">
								<b-table :items="paginatedCards" :fields="fieldsProduct" responsive="" class="table-programming">
									<template #cell(type)="data">
										{{ data.item.type }}
									</template>

									<template #cell(user_name)="data">
										{{ data.item.user_name }}
									</template>

									<template #cell(dt_import)="data">
										{{ data.item.import_date }}
									</template>

									<template #cell(dt_approved)="data">
										<span v-if="data.item.approved_date"> {{ data.item.approved_date }} </span>
										<span v-else> - </span>
									</template>

									<template #cell(actions)="data">
										<b-button class="btn-view" @click="editDolar(data.item)" style="text-decoration: none; color: black"  v-if="userLoggedPermission.permissionsyncprocessm == 1 || userLogged.role == 'Administrador'">
											<h6><font-awesome-icon :icon="['fas', 'eye']" /></h6>
										</b-button>

										<b-button @click="removeDolar(data.item)" style="text-decoration: none; color: black"  v-if="userLoggedPermission.permissionsyncprocessm == 1 || userLogged.role == 'Administrador'">
											<h6><font-awesome-icon :icon="['fas', 'trash-alt']" /></h6>
										</b-button>
									</template>
								</b-table>

								<p class="line"></p>
							</div>

							<b-col md="1"></b-col>

							<b-col md="6"></b-col>
						</b-row>

						<b-row class="paginations-options1 mt-4">
							<!-- <b-col md="6">
								<span class="mt-3">Resultados {{ currentPage }} de {{ numeroDePaginas }}</span>
							</b-col> -->
							<b-col md="12">
								<b-pagination  v-model="currentPage" :total-rows="processes.length" :per-page="perPage" style="float: right;">
								</b-pagination>
							</b-col>
						</b-row>

						<!-- IMPORTAÇÃO PROCESSO -->
						<b-modal ref="my-modal" hide-footer title="Filtrar importação de Compras" hide-header-close>
							<b-row>
								<b-col md="6">
									<b-form-group id="input-group-1" label="Número do Embarque:" label-for="input-1">
										<b-form-input v-model="numEmb" placeholder="Número do Embarque"></b-form-input>
									</b-form-group>
								</b-col>

								<b-col md="6">
									<b-form-group id="input-group-1" label="Número da OC:" label-for="input-1">
										<b-form-input v-model="numOcp" placeholder="Número da OC"></b-form-input>
									</b-form-group>
								</b-col>

								<b-col md="6" class="mt-3">
									<label for="example-datepicker">Data ETD Inicial</label>
									<b-form-datepicker placeholder="" id="example-datepicker" v-model="etdIni" class="mb-2" locale="pt"
										:date-format-options="{
											year: 'numeric',
											month: 'numeric',
											day: 'numeric',
										}"></b-form-datepicker>
								</b-col>

								<b-col md="6" class="mt-3">
									<label for="example-datepicker">Data ETD Final</label>
									<b-form-datepicker placeholder="" id="example-datepicker1" v-model="etdFim" class="mb-2" locale="pt"
										:date-format-options="{
											year: 'numeric',
											month: 'numeric',
											day: 'numeric',
										}"></b-form-datepicker>
								</b-col>

								<b-col md="6" class="mt-3">
									<label for="example-datepicker">Data ETA Inicial</label>
									<b-form-datepicker placeholder="" id="example-datepicker2" v-model="etaIni" class="mb-2" locale="pt"
										:date-format-options="{
											year: 'numeric',
											month: 'numeric',
											day: 'numeric',
										}"></b-form-datepicker>
								</b-col>

								<b-col md="6" class="mt-3">
									<label for="example-datepicker">Data ETA Final</label>
									<b-form-datepicker placeholder="" id="example-datepicker3" v-model="etaFim" class="mb-2" locale="pt"
										:date-format-options="{
											year: 'numeric',
											month: 'numeric',
											day: 'numeric',
										}"></b-form-datepicker>
								</b-col>

								<b-col md="6" class="mt-3">
									<label for="example-datepicker">Data de Embarque Inicial</label>
									<b-form-datepicker placeholder="" id="example-datepicker4" v-model="embIni" class="mb-2" locale="pt"
										:date-format-options="{
											year: 'numeric',
											month: 'numeric',
											day: 'numeric',
										}"></b-form-datepicker>
								</b-col>

								<b-col md="6" class="mt-3">
									<label for="example-datepicker">Data de Embarque Final</label>
									<b-form-datepicker placeholder="" id="example-datepicker5" v-model="embFim" class="mb-2" locale="pt"
										:date-format-options="{
											year: 'numeric',
											month: 'numeric',
											day: 'numeric',
										}"></b-form-datepicker>
								</b-col>

								<b-col md="6" class="mt-3">
									<label for="example-datepicker">Data Atracação Inicial</label>
									<b-form-datepicker placeholder="" id="example-datepicker6" v-model="atrIni" class="mb-2" locale="pt"
										:date-format-options="{
											year: 'numeric',
											month: 'numeric',
											day: 'numeric',
										}"></b-form-datepicker>
								</b-col>

								<b-col md="6" class="mt-3">
									<label for="example-datepicker">Data Atracação Final</label>
									<b-form-datepicker placeholder="" id="example-datepicker7" v-model="atrFim" class="mb-2" locale="pt"
										:date-format-options="{
											year: 'numeric',
											month: 'numeric',
											day: 'numeric',
										}"></b-form-datepicker>
								</b-col>

								<b-col md="6" class="mt-3">
									<label for="example-datepicker">Data Registro DI Inicial</label>
									<b-form-datepicker placeholder="" id="example-datepicker8" v-model="rdiIni" class="mb-2" locale="pt"
										:date-format-options="{
											year: 'numeric',
											month: 'numeric',
											day: 'numeric',
										}"></b-form-datepicker>
								</b-col>

								<b-col md="6" class="mt-3">
									<label for="example-datepicker">Data Registro DI Final</label>
									<b-form-datepicker placeholder="" id="example-datepicker9" v-model="rdiFim" class="mb-2" locale="pt"
										:date-format-options="{
											year: 'numeric',
											month: 'numeric',
											day: 'numeric',
										}"></b-form-datepicker>
								</b-col>

								<!-- <b-col md="12" class="mt-3">
									<b-form-group id="input-group-2" label="Status:" label-for="input-2">
										<b-form-select v-model="desSta" :options="optionsdesSta" style="border-color: #c4c4c4; padding: 5px; width: 100%;"></b-form-select>
									</b-form-group>
								</b-col> -->

								<b-col md="12"> </b-col>

								<b-col md="6">
									<b-button class="mt-3" variant="outline-danger" block @click="hideModal">Cancelar</b-button>
								</b-col>

								<b-col md="6">
									<!-- <b-button class="mt-3" variant="outline-success" style="float: right">Importar</b-button> -->
									<b-button class="mt-3" variant="outline-success" @click="sincronizedProcess()"
										style="float: right">Importar</b-button>
								</b-col>
							</b-row>
						</b-modal>

						<!-- IMPORTAÇÃO ESTOQUE -->
						<b-modal ref="import-stock" hide-footer title="Filtrar importação de estoque" hide-header-close>
							<b-row>
								<b-col md="6">
									<b-form-group id="input-group-1" label="Código da Empresa:" label-for="input-1">
										<b-form-input v-model="codEmp" placeholder="Código da Empresa"></b-form-input>
									</b-form-group>
								</b-col>

								<b-col md="6">
									<b-form-group id="input-group-1" label="Código do produto:" label-for="input-1">
										<b-form-input v-model="codPro" placeholder="Código do produto"></b-form-input>
									</b-form-group>
								</b-col>

								<b-col md="6" class="mt-4">
									<b-form-group id="input-group-1" label="Código da derivação:" label-for="input-1">
										<b-form-input v-model="codDer" placeholder="Código da derivação"></b-form-input>
									</b-form-group>
								</b-col>

								<b-col md="6" class="mt-4">
									<b-form-group id="input-group-1" label="Código do depósito:" label-for="input-1">
										<b-form-input v-model="codDep" placeholder="Código do depósito"></b-form-input>
									</b-form-group>
								</b-col>

								<!-- <b-col md="6" class="mt-4">
										<label for="example-datepicker">Data Início Mov.</label>
										<b-form-datepicker placeholder="" id="example-datepicker8" v-model="iniMov" class="mb-2" locale="pt"
											:date-format-options="{
												year: 'numeric',
												month: 'numeric',
												day: 'numeric',
											}"></b-form-datepicker>
								</b-col>

								<b-col md="6" class="mt-4">
										<label for="example-datepicker">Data Fim Mov.</label>
										<b-form-datepicker placeholder="" id="example-datepicker9" v-model="fimMov" class="mb-2" locale="pt"
											:date-format-options="{
												year: 'numeric',
												month: 'numeric',
												day: 'numeric',
											}"></b-form-datepicker>
								</b-col> -->

								<!-- <b-col md="12" class="mt-3">
									<label for="example-datepicker">Depósitos</label>
									<v-select :options="depositos" label="name" v-model="select_deposito" multiple>
										<template slot="option" slot-scope="option">
											{{ option.name }}
										</template>
									</v-select>
								</b-col> -->

								<!-- <b-col md="12" class="mt-4">
									<b-form-group label="Filtrar" v-slot="{ ariaDescribedby }">
										<b-form-radio-group id="radio-group-2" v-model="tipoIntegracao" :aria-describedby="ariaDescribedby"
											plain @change="integrationDate">
											<b-form-radio value="T">Todos</b-form-radio>
											<b-form-radio value="E">Específico</b-form-radio>
										</b-form-radio-group>
									</b-form-group>
								</b-col> -->

								<b-col md="12"></b-col>

								<b-col md="3">
									<b-button class="mt-3" variant="outline-danger" block @click="hideModalStock">Cancelar</b-button>
								</b-col>

								<b-col md="4">
									<b-button class="mt-3" variant="outline-success" @click="sincronizedStock()">Importar</b-button>
								</b-col>

								<!-- <b-col md="5">
									<b-button class="mt-3 import_manual" variant="outline-success" @click="uploadFileStock()">Importar
										Manualmente</b-button>
								</b-col> -->

							</b-row>
						</b-modal>

						<!-- IMPORTAÇÃO VENDAS -->
						<b-modal ref="import-sell" hide-footer title="Filtrar importação de vendas" hide-header-close>
							<b-row>
								<b-col md="6" class="mt-3">
									<label for="example-datepicker">Data Inicial</label>
									<b-form-datepicker placeholder="" id="example-datepicker8" v-model="sellIni" class="mb-2" locale="pt"
										:date-format-options="{
											year: 'numeric',
											month: 'numeric',
											day: 'numeric',
										}"></b-form-datepicker>
								</b-col>

								<b-col md="6" class="mt-3">
									<label for="example-datepicker">Data Final</label>
									<b-form-datepicker placeholder="" id="example-datepicker9" v-model="sellFim" class="mb-2" locale="pt"
										:date-format-options="{
											year: 'numeric',
											month: 'numeric',
											day: 'numeric',
										}"></b-form-datepicker>
								</b-col>
								<b-col md="12"> </b-col>

								<b-col md="6">
									<b-button class="mt-3" variant="outline-danger" block @click="hideModalSell">Cancelar</b-button>
								</b-col>

								<b-col md="6">
									<b-button class="mt-3" variant="outline-success" @click="sincronizedSells()"
										style="float: right">Importar</b-button>
								</b-col>
							</b-row>
						</b-modal>

						<b-modal ref="import-sell-progress" hide-footer title="Download" hide-header-close id="ModalProgress">
							<!-- <div v-if="progresso < 100"> -->
							<div>
								<p> <span class="loader"></span> Processando...</p>
								<div class="progress-bar" style="background-color:rgb(156, 157, 160)">
									<div :style="{ width: progresso + '%' }" style="background-color:rgb(75, 114, 221)"> <span> {{ progresso
									}}% </span> </div>
								</div>
							</div>
						</b-modal>
					</div>

				</div>
			</div>
		</PageComponent>
	</div>
</template>
<style>
.loader {
	width: 20px !important;
	height: 20px !important;
	border: 5px solid rgb(105, 68, 240) !important;
	border-bottom-color: rgb(243, 243, 243) !important;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

/* Estilo para a barra de progresso */
.progress-bar {
	width: 100%;
	height: 20px;
	background-color: #f0f0f0;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-top: 10px;
}

/* Estilo para a parte preenchida da barra de progresso */
.progress-bar div {
	height: 100%;
	background-color: #007bff;
	border-radius: 5px;
	transition: width 0.3s ease-in-out;
	color: white;
}

.import_manual {
	width: 100%;
	font-size: .9rem;
}

#import_stock {
	/* padding: 15px 10px; */
	/* padding: 5px !important; */
	cursor: pointer !important;
	border-radius: 5px;
	border: 1px solid #198754;
	width: 100%;
	color: #198754;
	padding: 6px 2px;
}

#file-small {
	display: none;
	padding: 5px 4px;
	cursor: pointer !important;
}

.custom-file-input {
	cursor: pointer !important;
}

.form-file-text {
	cursor: pointer !important;
	pointer-events: visible !important;
}

.label_import {
	padding: 6px 10px;
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid #198754;
	color: #198754;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>
<script>
import PageComponent from "../../page/PageComponent.vue";
import moment from "moment";
// import vSelect from 'vue-select'

export default {
	name: "BatchProcessesIndexComponent",
	components: {
		PageComponent,
		// vSelect
	},
	data() {
		return {
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			processes: [],
			perPage: 6,
			currentPage: 1,
			search: "",
			// filtro importação processo
			numEmb: "",
			numOcp: "",
			etdIni: "",
			etdFim: "",
			etaIni: "",
			etaFim: "",
			embIni: "",
			embFim: "",
			atrIni: "",
			atrFim: "",
			rdiIni: "",
			rdiFim: "",

			// filtro importação estoque
			codEmp: "10",
			codPro: "",
			codDer: "",
			codDep: "",
			typeIntegration: false,
			sellIni: "",
			sellFim: "",
			itens: ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'], // Seu array de dados
			progresso: 0,
			itensProcessados: [],
			processando: false,
			importId: '',
			file1: null,
			translatedData: null,
			sincronizedProcessSell: [],
			sincronizedProcessStock: [],
			sincronizedProcessPurchase: [],
			select_deposito: [], 
			depositos: [],
			iniMov: '',
			fimMov: '',
			userLoggedPermission: localStorage.getItem("userPermission")
            ? JSON.parse(localStorage.getItem("userPermission"))
            : null, 
			desSta: null,
			optionsdesSta: [
				{ value: null, text: 'Todos' },
				{ value: 'Em Transito', text: 'Em Transito' },
				{ value: 'A Embarcar', text: 'A Embarcar' },
				{ value: 'Aguardando Desembaraço', text: 'Aguardando Desembaraço' },
				{ value: 'Aguardando PI', text: 'Aguardando PI' },
				{ value: 'Com Booking', text: 'Com Booking' },
				{ value: 'Em Produção', text: 'Em Produção' },
			]
		};
	},

	mounted() {
		this.getProcess();
		this.getDepositos();
	},

	methods: {
		async getDepositos() {
			await this.$http
				.get(`/stocks/all/accepteds`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.depositos = response.data.stocks_accepted;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		// INTEGRAÇÃO ESTOQUE MANUALMENTE
		async uploadFileStock() {
			let formData = new FormData();
			this.showModalSellImpo();
			this.hideModalStock();

			await this.$http.post('/processes/stock/manual/sincronized', formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.sincronizedProcessStock = response.data.params.estoque.line;
						this.saveImport('Estoque', this.sincronizedProcessStock, false)
					}
				})

				.catch(function (error) {
					console.log(error);
				});
		},

		async syncEstoque(importId, translatedData) {
			this.progresso = 0;
			this.itensProcessados = [];
			this.processando = true;

			const totalItens = translatedData.length;
			const processarItem = (index) => {
				if (index >= totalItens) {
					this.progresso = 100;
					this.processando = false;
					return;
				}

				setTimeout(() => {
					this.itensProcessados.push(translatedData[index]);
					this.progresso = Math.round(((index + 1) / totalItens) * 100);
					processarItem(index + 1);

					if (this.progresso == 100) {
						this.$refs["import-sell-progress"].hide();
						this.goSuccess("Download concluido com sucesso!")
						this.getProcess();
						this.$swal.hideLoading();
					}
					const stock = translatedData[index];
					this.downloadStock(stock, importId);

				}, 1000);
			};

			processarItem(0);
			// for (let i = 0; i < translatedData.length; i++) {
			// 	this.downloadStock(translatedData[i], importId);
			// }
		},

		async downloadStock(stock, importId) {
			console.log(importId)

			const formData = new FormData();
			formData.append("codDep", stock.codDep != 'DRIVE-IN 2' ? stock.codDep : "");
			formData.append("codDer", stock.codDer != 'DRIVE-IN 2' ? stock.codDer : "");
			formData.append("codEmp", stock.codEmp != 'DRIVE-IN 2' ? stock.codEmp : "");
			formData.append("codPro", stock.codPro != 'DRIVE-IN 2' ? stock.codPro : "");
			formData.append("prmEst", stock.prmEst != 'DRIVE-IN 2' ? stock.prmEst : "");
			formData.append("qtdEst", stock.qtdEst != 'DRIVE-IN 2' ? stock.qtdEst : "");
			formData.append("importId", importId ? importId : "");

			await this.$http
				.post(`/processes/stock/manual/download`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response);
					} else {
						console.log(response.data);
					}
				})
				.catch(function (error) {
					console.log(error);
				});

		},
		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		async getProcess() {
			await this.$http
				.get(`/processes`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.processes = response.data.processes;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		showModal() {
			this.$refs["my-modal"].show();
		},

		hideModal() {
			this.$refs["my-modal"].hide();
			this.selectedProduct = "";
		},

		showModalStock() {
			this.$refs["import-stock"].show();
		},

		hideModalStock() {
			this.$refs["import-stock"].hide();
			this.selectedProduct = "";
		},

		showModalSell() {
			this.$refs["import-sell"].show();
		},

		showModalSellImpo() {
			this.$refs["import-sell-progress"].show();
		},


		hideModalSell() {
			this.$refs["import-sell"].hide();
		},
		// COMPRAS
		async sincronizedProcess() {
			const formData = new FormData();
			formData.append("numEmb", this.numEmb ? this.numEmb : "");
			formData.append("numOcp", this.numOcp ? this.numOcp : "");
			formData.append("etdIni", this.etdIni ? this.etdIni : "");
			formData.append("etdFim", this.etdFim ? this.etdFim : "");
			formData.append("etaIni", this.etaIni ? this.etaIni : "");
			formData.append("etaFim", this.etaFim ? this.etaFim : "");
			formData.append("embIni", this.embIni ? this.embIni : "");
			formData.append("embFim", this.embFim ? this.embFim : "");
			formData.append("atrIni", this.atrIni ? this.atrIni : "");
			formData.append("atrFim", this.atrFim ? this.atrFim : "");
			formData.append("rdiIni", this.rdiIni ? this.rdiIni : "");
			formData.append("rdiFim", this.rdiFim ? this.rdiFim : "");
			// formData.append("desSta", this.desSta ? this.desSta : "");

			formData.append("useImp", this.userLogged.id ? this.userLogged.id : "");

			this.$http
				.post(`/processes/sincronized`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response);
						this.$swal.hideLoading();
					} else {
						this.sincronizedProcessPurchase =
							response.data.Body.ExportarProcessoResponse.result;
						if (response.data.Body.ExportarProcessoResponse.result.tipoRetorno == "1") {
							this.goError(response.data.Body.ExportarProcessoResponse.result.mensagemRetorno);
							this.$refs["import-sell-progress"].hide();
						}

						if(this.sincronizedProcessPurchase.processo.length > 0){
							this.saveImport('Compra', this.sincronizedProcessPurchase, true)
						}

					}
				})
				.catch(function (error) {
					console.log(error);
				});

			this.showModalSellImpo();

		},

		async sincPurchases(importId, sincronizedProcess) {

			this.progresso = 0;
			this.itensProcessados = [];
			this.processando = true;

			const totalItens = sincronizedProcess.processo.length;

			const processarItem = (index) => {
				if (index >= totalItens) {
					this.progresso = 100;
					this.processando = false;
					return;
				}

				setTimeout(() => {
					this.itensProcessados.push(sincronizedProcess.processo[index]);
					this.progresso = Math.round(((index + 1) / totalItens) * 100);
					processarItem(index + 1);
					if (this.progresso == 100) {
						this.$refs["import-sell-progress"].hide();
						this.$refs["my-modal"].hide();
						this.goSuccess("Download concluido com sucesso!")
						this.getProcess();
						this.$swal.hideLoading();
					}

					const purchase = sincronizedProcess.processo[index];
					this.downloadProcesses(purchase, importId, index);

				}, 1000);
			};

			processarItem(0);

			// for (let i = 0; i < totalItens; i++) {
			// 	this.downloadProcesses(sincronizedProcess.processo[i], importId);
			// }
		},
		async downloadProcesses(process, importId, index) {
			const formData = new FormData();
			formData.append(
				"nomImp",
				process.nomImp != "[object Object]" ? process.nomImp : null
			);
			formData.append(
				"desSta",
				process.desSta != "[object Object]" ? process.desSta : null
			);
			formData.append(
				"nomDes",
				process.nomDes != "[object Object]" ? process.nomDes : null
			);
			formData.append(
				"numEmb",
				process.numEmb != "[object Object]" ? process.numEmb : null
			);
			formData.append(
				"nomOpe",
				process.nomOpe != "[object Object]" ? process.nomOpe : null
			);
			formData.append(
				"nomFor",
				process.nomFor != "[object Object]" ? process.nomFor : null
			);
			formData.append(
				"paiOri",
				process.paiOri != "[object Object]" ? process.paiOri : null
			);
			formData.append(
				"ptoOri",
				process.ptoOri != "[object Object]" ? process.ptoOri : null
			);
			formData.append(
				"codMoe",
				process.codMoe != "[object Object]" ? process.codMoe : null
			);
			formData.append(
				"viaTra",
				process.viaTra != "[object Object]" ? process.viaTra : null
			);
			formData.append(
				"datEpi",
				process.datEpi != "[object Object]" ? process.datEpi : null
			);
			formData.append(
				"ageCar",
				process.ageCar != "[object Object]" ? process.ageCar : null
			);
			formData.append(
				"navPro",
				process.navPro != "[object Object]" ? process.navPro : null
			);
			formData.append(
				"datEtd",
				process.datEtd != "[object Object]" ? process.datEtd : null
			);
			formData.append(
				"datEmb",
				process.datEmb != "[object Object]" ? process.datEmb : null
			);
			formData.append(
				"datEta",
				process.datEta != "[object Object]" ? process.datEta : null
			);
			formData.append(
				"datAtr",
				process.datAtr != "[object Object]" ? process.datAtr : null
			);
			formData.append(
				"datRdi",
				process.datRdi != "[object Object]" ? process.datRdi : null
			);
			formData.append(
				"numDii",
				process.numDii != "[object Object]" ? process.numDii : null
			);
			formData.append(
				"canDii",
				process.canDii != "[object Object]" ? process.canDii : null
			);
			formData.append(
				"datEtv",
				process.datEtv != "[object Object]" ? process.datEtv : null
			);
			formData.append(
				"datDes",
				process.datDes != "[object Object]" ? process.datDes : null
			);
			formData.append(
				"datEnt",
				process.datEnt != "[object Object]" ? process.datEnt : null
			);
			formData.append("useImp", this.userLogged.id ? this.userLogged.id : "");
			formData.append("importId", importId ? importId : "");

				await this.$http
					.post(`/processes/download`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response);
						} else {
							if (process.itemProcesso.length > 1) {
								const purchase = process.itemProcesso[index];
								this.downloadProcesseItem(purchase, response.data.process_id);

								// process.itemProcesso.map((process) => {
								// 	this.downloadProcesseItem(process, response.data.process_id);
								// });
							} else {
								this.downloadProcesseItem(process.itemProcesso, response.data.process_id);
							}

							if (process.container.numCon) {
								this.downloadProcesseContainer(process, response.data.process_id);
							}
						}
					})
					.catch(function (error) {
						console.log(error);
					});
		},

		async downloadProcesseItem(process, process_id) {
			const formData = new FormData();

			if (process && process.numOcp !== undefined) {
				formData.append("numOcp", process.numOcp != "[object Object]" ? process.numOcp : null);
			}

			if (process && process.seqOcp !== undefined) {
				formData.append("seqOcp", process.seqOcp != "[object Object]"  ? process.seqOcp : null);
			}

			if (process && process.datOcp !== undefined) {
				formData.append("datOcp", process.datOcp != "[object Object]"  ? process.datOcp : null);
			}

			if (process && process.codLot !== undefined) {
				formData.append("codLot", process.codLot != "[object Object]"  ? process.codLot : null);
			}
			if (process && process.codPro !== undefined) {
				formData.append("codPro", process.codPro != "[object Object]"  ? process.codPro : null);
			}
			if (process && process.codDer !== undefined) {
				formData.append("codDer", process.codDer != "[object Object]"  ? process.codDer : null);
			}
			if (process && process.desPro !== undefined) {
				formData.append("desPro", process.desPro != "[object Object]"  ? process.desPro : null);
			}
			if (process && process.desIng !== undefined) {
				formData.append("desIng", process.desIng != "[object Object]"  ? process.desIng : null);
			}
			if (process && process.codNcm !== undefined) {
				formData.append("codNcm", process.codNcm != "[object Object]"  ? process.codNcm : null);
			}
			if (process && process.qtdIte !== undefined) {
				formData.append("qtdIte", process.qtdIte != "[object Object]"  ? process.qtdIte : null);
			}
			if (process && process.vlrUni !== undefined) {
				formData.append("vlrUni", process.vlrUni != "[object Object]"  ? process.vlrUni : null);
			}
			if (process && process.pesLiq !== undefined) {
				formData.append("pesLiq", process.pesLiq != "[object Object]"  ? process.pesLiq : null);
			}
			if (process && process.pesbru !== undefined) {
				formData.append("pesbru", process.pesbru != "[object Object]"  ? process.pesbru : null);
			}
			// formData.append(
			// 	"seqOcp", process.seqOcp != "[object Object]" ? process.seqOcp : null
			// );
			// formData.append(
			// 	"datOcp", process.datOcp != "[object Object]" ? process.datOcp : null
			// );
			// formData.append(
			// 	"codLot", process.codLot != "[object Object]" ? process.codLot : null
			// );
			// formData.append(
			// 	"codPro", process.codPro != "[object Object]" ? process.codPro : null
			// );
			// formData.append(
			// 	"codDer", process.codDer != "[object Object]" ? process.codDer : null
			// );
			// formData.append(
			// 	"desPro", process.desPro != "[object Object]" ? process.desPro : null
			// );
			// formData.append(
			// 	"desIng", process.desIng != "[object Object]" ? process.desIng : null
			// );
			// formData.append(
			// 	"codNcm", process.codNcm != "[object Object]" ? process.codNcm : null
			// );
			// formData.append(
			// 	"qtdIte", process.qtdIte != "[object Object]" ? process.qtdIte : null
			// );
			// formData.append(
			// 	"vlrUni", process.vlrUni != "[object Object]" ? process.vlrUni : null
			// );
			// formData.append(
			// 	"pesLiq", process.pesLiq != "[object Object]" ? process.pesLiq : null
			// );
			// formData.append(
			// 	"pesbru", process.pesbru != "[object Object]" ? process.pesbru : null
			// );
			formData.append("useImp", this.userLogged.id ? this.userLogged.id : "");
			formData.append("process_id", process_id ? process_id : "");

			// if(process.codPro == '188811201'){
				await this.$http
				.post(`/processes/download/items`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response);
					} else {
						console.log(response.data);
						if (process.invoice) {
							this.downloadProcesseItemInvoice(process.invoice, process_id);
						}
					}
				})
				.catch(function (error) {
					console.log(error);
				});
			// }

			
		},

		async downloadProcesseItemInvoice(process, process_id) {
			const formData = new FormData();
			formData.append("process_id", process_id ? process_id : "");

			formData.append(
				"numInv",
				process.numInv != "[object Object]"
					? process.numInv
					: "-"
			);
			formData.append(
				"datInv",
				process.datInv != "[object Object]"
					? process.datInv
					: null
			);
			formData.append(
				"desFgt",
				process.desFgt != "[object Object]"
					? process.desFgt
					: "-"
			);
			formData.append("useImp", this.userLogged.id ? this.userLogged.id : "");

			await this.$http
				.post(`/processes/download/items/invoice`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response);
					} else {
						console.log(response.data);

					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async downloadProcesseContainer(process, process_id) {
			const formData = new FormData();
			formData.append("process_id", process_id ? process_id : "");

			formData.append(
				"numCon",
				process.container.numCon != "[object Object]"
					? process.container.numCon
					: "-"
			);
			formData.append("useImp", this.userLogged.id ? this.userLogged.id : "");

			await this.$http
				.post(`/processes/download/container`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response);
					} else {
						console.log(response.data);
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async editDolar(item) {
			if (item.type == 'Venda') {
				this.$router.push(`/processes/import/${item.id}/sells`);
			}
			if (item.type == 'Compra') {
				this.$router.push(`/processes/import/${item.id}/purchases`);
			}
			if (item.type == 'Estoque') {
				this.$router.push(`/processes/import/${item.id}/stocks`);
			}
			// this.$router.push(`/processes/import/${item}/process`);
		},

		async removeDolar(item) {
			const formData = new FormData();
			formData.append("id", item.id);
			this.$swal
				.fire({
					title: "Deletar Importação?",
					text: "Tem certeza que deseja deletar a importação?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#d33",
					cancelButtonColor: "#b4b4b4",
					confirmButtonText: "Deletar",
					cancelButtonText: "Cancelar",
					position: "center",
					timer: 4000,
				})
				.then((result) => {
					if (result.isConfirmed) {
						this.$http
							.post(`/processes/import/delete`, formData)
							.then((response) => {
								if (response.data.error) {
									this.goError(response.data.message);
								} else {
									this.getProcess();
								}
							})
							.catch(function (error) {
								console.log(error);
							});

						this.$swal.fire("Importação removida com sucesso!");
					}
				});
		},
		// INTEGRAÇÃO ESTOQUE
		async sincronizedStock() {
			this.hideModalStock();
			this.showModalSellImpo();

			const formData = new FormData();
			// formData.append("codEmp", this.codEmp ? this.codEmp : "");
			formData.append("codPro", this.codPro ? this.codPro : "");
			formData.append("codDer", this.codDer ? this.codDer : "");
			formData.append("codDep", this.codDep ? this.codDep : "");

			// https://fontawesomeicons.com/fa/vue-js-tostring-method

			// if (this.codEmp != "") {
				this.$http
					.post(`/processes/stock/sincronized`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response);
							this.$swal.hideLoading();
						} else {
							this.sincronizedProcessStock = response.data.Body.ExportarEstoqueResponse.result.estoque;
							if ( response.data.Body.ExportarEstoqueResponse.result.tipoRetorno == "1") {
								this.goError(response.data.Body.ExportarEstoqueResponse.result.mensagemRetorno);
								this.$refs["import-sell-progress"].hide();
							} else {

								if(this.sincronizedProcessStock.length > 0){
									//se tiver dados grava a importação
									this.saveImport('Estoque', this.sincronizedProcessStock, true);
								}
							}


							// this.saveImport('Estoque', this.sincronizedProcessStock, true)

							// console.log(this.sincronizedProcessStock)
							// this.progresso = 0;
							// this.itensProcessados = [];
							// this.processando = true;

							// const totalItens = this.sincronizedProcessStock.length;

							// const processarItem = (index) => {
							// 	if (index >= totalItens) {
							// 		this.progresso = 100;
							// 		this.processando = false;
							// 		return;
							// 	}

							// 	setTimeout(() => {
							// 		this.itensProcessados.push(this.sincronizedProcessStock[index]);
							// 		this.progresso = Math.round(((index + 1) / totalItens) * 100);
							// 		processarItem(index + 1);
							// 	}, 1000);
							// 	if (this.progresso == 100) {
							// 		this.$refs["import-sell-progress"].hide();
							// 		this.goSuccess("Download concluido com sucesso!")
							// 	}
							// };

							// processarItem(0);
							// this.sincronizedProcessStock.map((sell) => {
							// 	this.downloadProcessesStock(sell);
							// });
						}
					})
					.catch(function (error) {
						console.log(error);
					});
			// }
		},

		async syncEstoqueAutomatico(importId, translatedData) {
			this.progresso = 0;
			this.itensProcessados = [];
			this.processando = true;

			const totalItens = translatedData.length;
			const processarItem = (index) => {
				if (index >= totalItens) {
					this.progresso = 100;
					this.processando = false;
					return;
				}

				setTimeout(() => {
					this.itensProcessados.push(translatedData[index]);
					this.progresso = Math.round(((index + 1) / totalItens) * 100);
					processarItem(index + 1);

					if (this.progresso == 100) {
						this.$refs["import-sell-progress"].hide();
						this.goSuccess("Download concluido com sucesso!")
						this.getProcess();
					}
				}, 1000);

			};

			processarItem(0);
			for (let i = 0; i < translatedData.length; i++) {
				this.downloadProcessesStock(translatedData[i], importId);
			}
		},

		async downloadProcessesStock(process, importId) {
			const formData = new FormData();

			formData.append("codDep", process.codDep ? process.codDep : "");
			formData.append("codDer", process.codDer ? process.codDer : "");
			formData.append("codEmp", process.codEmp ? process.codEmp : "");
			formData.append("codPro", process.codPro ? process.codPro : "");
			formData.append("prmEst", process.prmEst ? process.prmEst : "");
			formData.append("qtdEst", process.qtdEst ? process.qtdEst : "");
			formData.append("useImp", this.userLogged.id ? this.userLogged.id : "");
			formData.append("importId", importId ? importId : "");

			await this.$http
				.post(`/processes/download`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response);
					} else {
						// this.teste = response;
						console.log(response.data);
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async downloadProcessesStockManual(process) {
			console.log(process);
			const formData = new FormData();

			formData.append(
				"nomImp",
				process.nomImp != "[object Object]" ? process.nomImp : "-"
			);
			formData.append(
				"desSta",
				process.desSta != "[object Object]" ? process.desSta : "-"
			);
			formData.append(
				"nomDes",
				process.nomDes != "[object Object]" ? process.nomDes : "-"
			);
			formData.append(
				"numEmb",
				process.numEmb != "[object Object]" ? process.numEmb : "-"
			);
			formData.append(
				"nomOpe",
				process.nomOpe != "[object Object]" ? process.nomOpe : "-"
			);
			formData.append(
				"nomFor",
				process.nomFor != "[object Object]" ? process.nomFor : "-"
			);
			formData.append(
				"paiOri",
				process.paiOri != "[object Object]" ? process.paiOri : "-"
			);
			formData.append(
				"ptoOri",
				process.ptoOri != "[object Object]" ? process.ptoOri : "-"
			);
			formData.append(
				"codMoe",
				process.codMoe != "[object Object]" ? process.codMoe : "-"
			);
			formData.append(
				"viaTra",
				process.viaTra != "[object Object]" ? process.viaTra : "-"
			);
			formData.append(
				"datEpi",
				process.datEpi != "[object Object]" ? process.datEpi : "-"
			);
			formData.append(
				"ageCar",
				process.ageCar != "[object Object]" ? process.ageCar : "-"
			);
			formData.append(
				"navPro",
				process.navPro != "[object Object]" ? process.navPro : "-"
			);
			formData.append(
				"datEtd",
				process.datEtd != "[object Object]" ? process.datEtd : "-"
			);
			formData.append(
				"datEmb",
				process.datEmb != "[object Object]" ? process.datEmb : "-"
			);
			formData.append(
				"datEta",
				process.datEta != "[object Object]" ? process.datEta : "-"
			);
			formData.append(
				"datAtr",
				process.datAtr != "[object Object]" ? process.datAtr : "-"
			);
			formData.append(
				"datRdi",
				process.datRdi != "[object Object]" ? process.datRdi : "-"
			);
			formData.append(
				"numDii",
				process.numDii != "[object Object]" ? process.numDii : "-"
			);
			formData.append(
				"canDii",
				process.canDii != "[object Object]" ? process.canDii : "-"
			);
			formData.append(
				"datEtv",
				process.datEtv != "[object Object]" ? process.datEtv : "-"
			);
			formData.append(
				"datDes",
				process.datDes != "[object Object]" ? process.datDes : "-"
			);
			formData.append(
				"datEnt",
				process.datEnt != "[object Object]" ? process.datEnt : "-"
			);
			formData.append("useImp", this.userLogged.id ? this.userLogged.id : "");


				await this.$http
				.post(`/processes/download`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response);
					} else {
						console.log(response.data);
					}
				})
				.catch(function (error) {
					console.log(error);
				});

		},
		// IMPORTAÇÃO DE VENDAS  
		async sincronizedSells() {
			const formData = new FormData();
			formData.append("datIni", this.sellIni ? this.sellIni : "");
			formData.append("datFim", this.sellFim ? this.sellFim : "");
			if (this.sellIni == "" || this.sellFim == "") {
				this.goError("Selecione uma data inicial e final");
			} else {
				// this.$refs["import-sell"].hide();
				this.showModalSellImpo();

				this.$http
					.post(`/processes/sell/sincronized`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response);
						} else {
							this.sincronizedProcessSell =
								response.data.Body.ExportarPedidoNFResponse.result.pedidosNF;

							if (response.data.Body.ExportarPedidoNFResponse.result.tipoRetorno == "1") {
								this.goError(response.data.Body.ExportarPedidoNFResponse.result.mensagemRetorno);
								this.$refs["import-sell-progress"].hide();
							}


							if(this.sincronizedProcessSell.length > 0){
								//se tiver dados grava a importação
								this.saveImport('Venda', this.sincronizedProcessSell, true);
							}
						}
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		},

		async syncSell(importId, sincronizedProcessSell) {
			this.progresso = 0;
			this.itensProcessados = [];
			this.processando = true;

			const totalItens = sincronizedProcessSell.length;

			const processarItem = (index) => {
				if (index >= totalItens) {
					this.progresso = 100;
					this.processando = false;
					return;
				}

				setTimeout(() => {
					this.itensProcessados.push(sincronizedProcessSell[index]);
					this.progresso = Math.round(((index + 1) / totalItens) * 100);
					processarItem(index + 1);
					if (this.progresso == 100) {
						this.$refs["import-sell-progress"].hide();
						this.$refs["my-modal"].hide();
						this.goSuccess("Download concluido com sucesso!")
						this.getProcess();
						this.$swal.hideLoading();
						location.reload();
					}
					console.log(index)
					// for (let i = 0; i < this.progresso; i++) {
						const sell = sincronizedProcessSell[index];
						this.downloadProcessesSell(sell, importId);
					// }
				}, 1000);
			};

			processarItem(0);
			// for (let i = 0; i < 100; i++) {
			// 	const sell = sincronizedProcessSell[i];
			// 	this.downloadProcessesSell(sell, importId);
			// }
		},

		async saveImport(type, automatico) {
			const formData = new FormData();
			formData.append("user_id", this.userLogged.id ? this.userLogged.id : "");
			formData.append("type", type ? type : "");
			await this.$http
				.post(`/processes/import`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response);
					} else {
						this.importId = response.data.importId;
						if (type == 'Compra') {
							this.sincPurchases(this.importId, this.sincronizedProcessPurchase);
						}
						if (type == 'Venda') {
							this.syncSell(this.importId, this.sincronizedProcessSell);
						}
						if (type == 'Estoque') {
							this.syncEstoque(this.importId, this.sincronizedProcessStock);
						}
						if(type == 'Estoque' && automatico == true) {
							this.syncEstoqueAutomatico(this.importId, this.sincronizedProcessStock);
						}

					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async downloadProcessesSell(process, importId) {
			const formData = new FormData();

			formData.append("user_id", this.userLogged.id ? this.userLogged.id : "");
			formData.append("codEmp", process.codEmp ? process.codEmp : "-");
			formData.append("codFil", process.codFil ? process.codFil : "-");
			formData.append("numPed", process.numPed ? process.numPed : "-");
			formData.append("import_id", importId ? importId : "-");

			await this.$http
				.post(`/processes/download/sells`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response);
					} else {
						let sellid = response.data.sell_id;

						if (process.produtos) {

							if (process.produtos.length > 1) {
								let prod = process.produtos;

								prod.map((sell) => {
									this.downloadProcessesSellProduct(sell, sellid, importId);
								});

							} else {
								let prod = process.produtos;
								this.downloadProcessesSellProduct(prod, sellid, importId);
							}
						}
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async downloadProcessesSellProduct(produto, sellid, importId) {
			const formData = new FormData();

			let cod = produto.codPro || produto.codProd;
			delete produto.codPro;
			delete produto.codProd;
			console.log('UM SÓ')
			console.log(produto);

			let datEmi = produto.datEmi != "[object Object]" ? produto.datEmi : null
			formData.append("user_id", this.userLogged.id ? this.userLogged.id : "");
			formData.append("sell_product_id", sellid ? sellid : "-");
			formData.append("codDer", produto.codDer != "[object Object]" ? produto.codDer : "-");
			formData.append("codProd", cod ? cod : "-");
			formData.append("codSnf", produto.codSnf != "[object Object]" ? produto.codSnf : "-");
			formData.append("datEmiPed", produto.emiPed != "[object Object]" ? produto.emiPed : "-");

			formData.append("datEmi", datEmi);
			formData.append("datEnt", produto.datEnt != "[object Object]" ? produto.datEnt : "-");
			formData.append("numNfv", produto.numNfv != "[object Object]" ? produto.numNfv : "-");
			formData.append("qtdFat", produto.qtdFat != "[object Object]" ? produto.qtdFat : "-");
			formData.append("qtdPed", produto.qtdPed != "[object Object]" ? produto.qtdPed : "-");
			formData.append("seqIpd", produto.seqIpd != "[object Object]" ? produto.seqIpd : "-");
			formData.append("seqIpv", produto.seqIpv != "[object Object]" ? produto.seqIpv : "-");
			formData.append("staPed", produto.staPed != "[object Object]" ? produto.staPed : "-");
			formData.append("vlrLiq", produto.vlrLiq != "[object Object]" ? produto.vlrLiq : "-");
			formData.append("vlrLnf", produto.vlrLnf != "[object Object]" ? produto.vlrLnf : "-");
			formData.append("import_id", importId ? importId : "-");

			await this.$http
				.post(`/processes/download/sell/products`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response);
					} else {
						// this.goSuccess("Download concluido com sucesso!")
						// this.$swal.hideLoading();
						// location.reload();
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

	},

	computed: {
		fieldsProduct: function () {
			let fields = [
				{
					key: "type",
					label: "Tipo",
				},

				{
					key: "dt_import",
					label: "Data Importação",
				},

				{
					key: "dt_approved",
					label: "Data aprovação",
				},

				{
					key: "user_name",
					label: "Usuário",
				},
				{
					key: "actions",
					label: "",
				},
			];
			return fields;
		},

		paginatedCards() {
			const { currentPage, perPage } = this;
			const start = (currentPage - 1) * perPage;
			const end = currentPage * perPage;

			return this.processes.slice(start, end);
		},

		numeroDePaginas() {
			return Math.ceil(this.processes.length / this.perPage);
		},
	},

	filters: {
		dateFormat: function (date) {
			return moment(date).format("DD/MM/YYYY");
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
