<template>
    <div class="main">
        <PageComponent :title_nav="'S&OP'">
            <div slot="slot-pages" class="content-pages" style="width: 100% !important;">
                <div class="products">
                    <div class="products-header">
                        <!-- breadcrumb -->
                        <b-breadcrumb>
                            <b-breadcrumb-item :to="{ name: 'dashboard' }">
                                <font-awesome-icon :icon="['fas', 'arrow-left']" />
                                Início
                            </b-breadcrumb-item>
                        </b-breadcrumb>
                        <!-- breadcrumb -->
                        <b-row>
                            <b-col md="4" class="col-search">
                                <b-card>
                                    <div class="filter_date row">
                                        <div class="col col-md-6 datepicker1">
                                            <b-form-input id="type-date" class="datepicker" type="month" v-model="date"
                                                placeholder="" @change="selectDate()"></b-form-input>
                                        </div>
                                        <div class="col col form">
                                            <b-form-input placeholder="Procurar" v-model="search" @input="closeProducts()"> </b-form-input>
                                            <font-awesome-icon :icon="['fas', 'search']" class="icon1" />
                                        </div>
                                    </div>
                                    <!-- <p class="mt-3">
                                        <input type="checkbox" @change="allSelect()" name="" id="" v-model="all_select" />
                                        Todos
                                    </p> -->
                                </b-card>
                            </b-col>
                            <!-- {{ result_forecast[0] }} -->
                            <b-col md="8" class="" :style="contentStyles">

                                <div class="checkbox-container">
                                    <input type="checkbox" id="Estoque" @change="selectEstoque()" v-model="estoque">
                                    <label for="Estoque" class="estoque">Estoque</label>

                                    <input type="checkbox" id="prog" @change="selectProgramacao()" v-model="prog">
                                    <label for="prog" class="prog">Programação</label>

                                    <input type="checkbox" id="Embalagem" @change="selectEmbalagem()" v-model="embal">
                                    <label for="Embalagem" class="embal">Embalagem</label>

                                    <input type="checkbox" id="info" @change="selectInfo()" v-model="infos">
                                    <label for="info" class="info">Mais
                                        informações</label>
                                </div>
                            </b-col>

                            <b-col md="12">
                                <div class="table-responsive table-products">
                                    <b-table :fields="updatedFields" :items="itemsFiltradosPaginados" class="table" :per-page="perPage"
                                        :current-page="currentPage">
                                        <template #head(produtos)="">
                                            <p class="newColumn"></p>
                                        </template>

                                        <!-- forecast -->
                                        <template #head(1)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- Fat.líquido -->
                                        <template #head(2)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- A_realizar -->
                                        <template #head(3)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- Ped_não_fechado -->
                                        <template #head(7)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- Pedaberto -->
                                        <template #head(8)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- Logística -->
                                        <template #head(9)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- Estplanejamento -->
                                        <template #head(12)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- Entrada -->
                                        <template #head(16)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- Estfinal -->
                                        <template #head(17)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- EFMA -->
                                        <template #head(20)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- Mméd -->
                                        <template #head(18)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- Mfor -->
                                        <template #head(19)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- Estdisponível -->
                                        <template #head(10)="data">
                                            <p class="estoque estoque-ativo">{{ data.label }}</p>
                                        </template>
                                        <!-- Poder_terceiros -->
                                        <template #head(4)="data">
                                            <p class="estoque estoque-ativo">{{ data.label }}</p>
                                        </template>
                                        <!-- Rejeitado -->
                                        <template #head(5)="data">
                                            <p class="estoque estoque-ativo">{{ data.label }}</p>
                                        </template>
                                        <!-- Embalagem -->
                                        <template #head(6)="data">
                                            <p class="estoque estoque-ativo">{{ data.label }}</p>
                                        </template>
                                        <!-- Quarentena -->
                                        <template #head(11)="data">
                                            <p class="estoque estoque-ativo">{{ data.label }}</p>
                                        </template>
                                        <!-- Volume -->
                                        <template #head(13)="data">
                                            <p class="prog prog-ativo">{{ data.label }}</p>
                                        </template>
                                        <!-- Data -->
                                        <template #head(14)="data">
                                            <p class="prog prog-ativo">{{ data.label }}</p>
                                        </template>
                                        <!-- M_H -->
                                        <template #head(15)="data">
                                            <p class="prog prog-ativo">{{ data.label }}</p>
                                        </template>
                                        <!-- Largura -->
                                        <template #head(23)="data">
                                            <p class="embal"> {{ data.label }}</p>
                                        </template>
                                        <!-- Comprimento -->
                                        <template #head(25)="data">
                                            <p class="embal">{{ data.label }}</p>
                                        </template>
                                        <!-- Peso -->
                                        <template #head(26)="data">
                                            <p class="embal">{{ data.label }}</p>
                                        </template>
                                        <!-- Peso_cubado -->
                                        <template #head(27)="data">
                                            <p class="embal"> {{ data.label }}</p>
                                        </template>
                                        <!-- Quantidade -->
                                        <template #head(28)="data">
                                            <p class="embal"> {{ data.label }}</p>
                                        </template>
                                        <!-- Altura -->
                                        <template #head(24)="data">
                                            <p class="embal">{{ data.label }}</p>
                                        </template>

                                        <!-- Estsegurança -->
                                        <template #head(21)="data">
                                            <p class="infos infos-ativo"> {{ data.label }}</p>
                                        </template>
                                        <!-- Lead_time -->
                                        <template #head(22)="data">
                                            <p class="infos infos-ativo"> {{ data.label }}</p>
                                        </template>

                                        <template v-slot:head()="data">
                                            <p v-if="data.field.key > '28'" class="estoque estoque-ativo">{{ data.label }}
                                            </p>
                                        </template>

                                        <template #cell(produtos)="data">
                                            <td style="max-width: 300px !important;">
                                                <label class="span_button" :for="data.item.id">
                                                    <input type="checkbox" :id="data.item.id" @change="historyProduct(data.item, data.index, $event)">
                                                   <font-awesome-icon :icon="['fas', 'history']" />
                                                </label> 
                                                <label :for="data.item.id" style="text-align: left; display: block" >
                                                    <b-button v-b-toggle="'prod-' + data.index" class="" v-b-tooltip.hover
                                                        @click="findProduct(data.item, data.index, true)">
                                                           {{ data.item.name }}
                                                    </b-button>
                                                </label>
                                            </td>
                                                <div class="item-content" :class="{ 'open': isOpen[data.index] }">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> {{ deri.code }} {{
                                                    deri.derivation
                                                }}</p>

                                                <p> <strong> Totais: </strong> </p>
                                            </div>
                                        </template>
                                        <!-- forecast -->
                                        <template #cell(1)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div  class="container">
                                                    <div v-if="result_forecast" class="container grid-list">
                                                        <p v-for="item in result_forecast" :key="item">
                                                            <span v-if="item.forecast != 0">
                                                                {{ obterQuantidadeForecast(item.derivation, item.forecast) }}
                                                            </span>
                                                            <span v-else>
                                                                0
                                                            </span>

                                                        </p>
                                                        <p> <strong> {{ calcularTotalForecast(result_forecast) }} </strong> </p>

                                                    </div>
                                                    <div v-else class="container grid-list">
                                                        <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                        <p> <strong> 0 </strong> </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- fat liquido -->
                                        <template #cell(2)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div  class="container">
                                                    <div v-if="result_forecast" class="container grid-list">
                                                        <p v-for="item in result_forecast" :key="item">
                                                            <span v-if="item.liquido != 0">
                                                                {{ obterQuantidadeLiquido(item.derivation, item.liquido) }}
                                                            </span>
                                                            <span v-else>
                                                                0
                                                            </span>

                                                        </p>
                                                        <p> <strong> {{ calcularTotalLiquido(result_forecast) }} </strong> </p>

                                                    </div>
                                                    <div v-else class="container grid-list">
                                                        <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                        <p> <strong> 0 </strong> </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- a realizar -->
                                        <template #cell(3)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div  class="container">
                                                    <div v-if="result_forecast" class="container grid-list">
                                                        <p v-for="item in result_forecast" :key="item">
                                                            <span v-if="item.realizar != 0">
                                                                {{ obterQuantidadeRealizar(item.derivation, item.realizar) }}
                                                            </span>
                                                            <span v-else>
                                                                0
                                                            </span>
                                                        </p>
                                                        <p> <strong> {{ calcularTotalRealizar(result_forecast) }} </strong> </p>

                                                    </div>
                                                    <div v-else class="container grid-list">
                                                        <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                        <p> <strong> 0 </strong> </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- poder terceiros -->
                                        <template #cell(4)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- rejeitado -->
                                        <template #cell(5)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- embalagem -->
                                        <template #cell(6)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- ped nao fechado -->
                                        <template #cell(7)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">

                                                        <span v-if="item.pedFechado != 0">
                                                            {{ obterQtdeFechado(item.derivation, item.pedFechado) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>

                                                    </p>
                                                    <p> <strong> {{ calcularTotalFechado(result_forecast) }} </strong> </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- ped aberto -->
                                        <template #cell(8)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                        <span v-if="item.pedAberto != 0">
                                                            {{ obterQuantidadeAberto(item.derivation, item.pedAberto) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>
                                                    </p>
                                                    <p> <strong> {{ calcularTotalPedAberto(result_forecast) }} </strong>
                                                    </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>


                                            </div>
                                        </template>
                                        <!-- logistica -->
                                        <template #cell(9)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                        <span v-if="item.logistica != 0">
                                                          {{ obterQuantidadeLogistica(item.derivation, item.logistica) }}  
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>
                                                    </p>
                                                    <p> <strong> {{ calcularTotalLogistica(result_forecast) }} </strong>
                                                    </p>

                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- est disponivel -->
                                        <template #cell(10)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- quarentena -->
                                        <template #cell(11)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- est planejamento -->
                                        <template #cell(12)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                        <span v-if="item.estplanejamento != 0">
                                                            {{ obterQuantidadeDerivacao(item.derivation, item.estplanejamento) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>
                                                        <!-- <span v-for="est in item.estplanejamento" :key="est.codDer">
                                                            <span v-if="est.codDer == item.derivation">
                                                                {{ est.qtdEst }}
                                                            </span>
                                                        </span> -->

                                                     </p>
                                                    <p > <strong> {{ calcularTotalPlanejamento(result_forecast) }} </strong> </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- volume -->
                                        <template #cell(13)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                        <span v-if="item.volume != 0">
                                                            {{ obterQtdeDerVolume(item.derivation, item.volume) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>
                                                    </p>
                                                    <p> <strong> {{ calcularTotalVolume(result_forecast) }} </strong> </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- data -->
                                        <template #cell(14)="data">
                                            <div class="item-content" :class="{ 'container text-end  open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                        <span v-if="item.data != 0">
                                                            {{ obterQtdeDerData(item.derivation, item.data) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>
                                                    </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- M_H -->
                                        <template #cell(15)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- entrada -->
                                        <template #cell(16)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                        <span v-if="item.entrada != 0">
                                                            {{ obterQtdeDerEntrada(item.derivation, item.entrada) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>
                                                    </p>
                                                    <p> <strong> {{ calcularTotalEntrada(result_forecast) }} </strong>
                                                    </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!--Estfinal  -->
                                        <template #cell(17)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                        <span v-if="item.estfinal != 0">
                                                            {{ obterQtdeDerFinal(item.derivation, item) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>
                                                    </p>
                                                    <p> <strong> {{ calcularTotalFinal(result_forecast)}} </strong> </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>

                                            </div>
                                        </template>
                                         <!-- EFMA -->
                                         <template #cell(20)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                        <span v-if="item.efma != 0">
                                                            {{ obterQtdeDerEfma(item.derivation, item.efma) }} 
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>
                                                    </p>
                                                    <p> <strong> {{ calcularTotalEFMAT(result_forecast) }} </strong> </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <!-- <div class="container grid-list"> -->
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- Mméd M.méd-->
                                        <template #cell(18)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                        <span v-if="item.mmedia != 0">
                                                            {{ obterQtdeDerMedio(item.derivation, item.mmedia) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>

                                                    </p>
                                                    <p> <strong> {{ calcularTotalMMedia(result_forecast) }} </strong> </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <!-- <div class="container grid-list"> -->
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- Mfor -->
                                        <template #cell(19)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                            <!-- {{ est.forecast_budget }}
                                                            {{ est.planejamento }} -->
                                                            <span v-if="item.mfor != 0">
                                                                {{ obterQtdeDerMfor(item.derivation, item.mfor) }}
                                                            </span>
                                                            <span v-else>
                                                                0
                                                            </span>
                                                    </p>
                                                    <p> <strong> {{ calcularTotalMFOR(result_forecast) }}</strong> </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                <!-- <div class="container grid-list"> -->
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- Estsegurança -->
                                        <template #cell(21)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                    
                                                        <span v-if="item.estSeguranca != 0">
                                                            {{ obterQtdeDerSeguranca(item.derivation, item.estSeguranca) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>
                                                    </p>
                                                    <p> <strong> {{ calcularTotalEstoque(result_forecast) }}
                                                        </strong> </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!--  Lead_time-->
                                        <template #cell(22)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                    
                                                        <span v-if="item.leadTime != 0">
                                                            {{ obterQuantidadeLeadTime(item.derivation, item.leadTime) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>

                                                    </p>
                                                    <p><strong>{{ calcularTotalLeadTime(result_forecast) }}</strong></p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- Largura -->
                                        <template #cell(23)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- Altura -->
                                        <template #cell(24)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- Comprimento -->
                                        <template #cell(25)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!--Peso  -->
                                        <template #cell(26)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- Peso_cubado -->
                                        <template #cell(27)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>

                                        <!-- Quantidade -->
                                        <template #cell(28)="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }">

                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>

                                        <template v-for="(field, index) in parametersNameStock"
                                            #[`cell(${field.id})`]="data">
                                            <div class="item-content" :class="{ 'container text-end open': isOpen[data.index] }" :key="index">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                        <span v-if="item.stock != 0">
                                                            {{ obterQtdeDerStock(item.derivation, item.stock, field.name) }}
                                                            <!-- <span v-if="hasValidStock(item.stock, item.derivation, field.name)">
                                                            <span v-for="stock in item.stock" :key="stock.codDer">
                                                            <span v-if="stock.total_qtd_ped > 0 && stock.codDer == item.derivation && stock.name == field.name">
                                                                {{ stock.total_qtd_ped }}
                                                            </span>
                                                            </span>
                                                    </span> -->
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>

                                                    <!-- Variável para controlar se algum item atende à condição -->
                                                    <!-- <span v-if="hasValidStock(item.stock, item.derivation, field.name)">
                                                        <span v-for="stock in item.stock" :key="stock.codDer">
                                                        <span v-if="stock.total_qtd_ped > 0 && stock.codDer == item.derivation && stock.name == field.name">
                                                            {{ stock.total_qtd_ped }}
                                                        </span>
                                                        </span>
                                                    </span>
                                                    <span v-else-if="item.stock && item.stock.length == 0">
                                                        0
                                                    </span> -->
                                                    </p>
                                                    <p v-if="calcularTotalStocks(result_forecast, field.name)">
                                                        <strong>{{ calcularTotalStocks(result_forecast, field.name) }}</strong>
                                                    </p>
                                                    <p v-else>
                                                        <strong>0</strong>
                                                    </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id">0</p>
                                                    <p><strong>0</strong></p>
                                                </div>
                                                </div>


                                        </template>


                                    </b-table>

                                </div>
                            </b-col>
                        </b-row>

                        <b-row class="paginations-options1 mt-4">
                            <!-- <b-col md="6">
                                <span class="mt-3">Resultados {{ currentPage }} de {{ numeroDePaginas }}</span>
                            </b-col> -->
                            <b-col md="12">
								<b-pagination v-model="paginaAtual" :total-rows="totalItensFiltrados" :per-page="itensPorPagina" @change="paginaMudada" style="float: right;"></b-pagination>

                            </b-col>
                        </b-row>

                        <b-row v-for="(item, index) in products_history" :key="index">

                            <div class="items-container" style="width: 100%; overflow-x: scroll; white-space: nowrap;">
                                <div  style="display: inline-flex; padding: 5px;">
                                    <div class="agrupador_card agrupadorPrevious">
                                        <p class="name_product"><strong> {{ item.name }} </strong> </p>
                                        <p v-for="der in item.items" :key="der">
                                        {{ item.code }} - {{ der.derivation }}
                                        </p>
                                        <p> <strong>Totais</strong> </p>
                                    </div>

                                    <!-- AGRUPADOR ULTIMOS 3 MESES -->
                                    <b-card class="agrupadorPrevious name_product">
                                        <p> <strong> 3 ÚLTIMOS MESES </strong> </p>
                                        <b-row class="row_months_3">
                                            <b-col md="6">Soma Desvio % - 3 meses</b-col>
                                            <b-col md="3">M_Desvio %</b-col>
                                            <b-col md="3">M. Linear</b-col>
                                        </b-row>
                                        <div>
                                            
                                            <b-row v-for="(months, indexMon) in item.items" :key="indexMon" class="row_3months_results">
                                                <b-col  md="6">
                                                    <p>
                                                        {{ obterQtdeDesvio3MesesPorcentGroup(months.derivation, item)}}
                                                    </p>
                                                    <p class="sum_agr" v-if="isUltimoItem(indexMon, item.items)">
                                                       <strong>{{ obterQtdeDesvio3MesesPorcentTotalGroup(item)}}</strong>
                                                    </p>

                                                </b-col>

                                                <b-col md="3">
                                                    <p>
                                                        {{ obterQtdeDesvio3MesesMPorcentGroup(months.derivation, item)}}
                                                    </p>
                                                    <p class="sum_agr" v-if="isUltimoItem(indexMon, item.items)">
                                                       <strong>{{ obterQtdeDesvio3MesesMPorcentTotalGroup(item)}}</strong>
                                                    </p>
                                                </b-col>

                                                <b-col md="3">
                                                    <p>
                                                        {{ obterQtdeDerMLinear(months.derivation, item.resultadosLinear)}}
                                                    </p>

                                                    <p class="sum_agr" v-if="isUltimoItem(indexMon, item.items)">
                                                       <strong> {{ calcularTotalArrayMLinear(item.resultadosLinear) }} </strong> 
                                                    </p>
                                                </b-col>
                                            </b-row>
                                        </div>
                                        
                                    </b-card>

                                    <!-- AGRUPADOR ULTIMOS 14 MESES -->
                                    <b-card class="agrupadorPrevious name_product" v-for="itemMonth in monthsPrevious" :key="itemMonth">
                                        <p> <strong> {{ itemMonth }} </strong> </p>

                                        <div v-for="(itemV, indexV) in item.values" :key="indexV">
                                            <b-row v-if="itemV.data.toLowerCase() == itemMonth"  class="name_month">
                                                <b-col>Forecasting</b-col>
                                                <b-col>Fat.líquido</b-col>
                                                <b-col>Desvio</b-col>
                                                <b-col>Desvio %</b-col>
                                            </b-row>

                                            <div v-for="(itemS, indexD) in itemV.derivacao" :key="itemS">
                                                <div v-if="itemV.data.toLowerCase() == itemMonth">
                                                    <b-row class="row_values_agrupador">
                                                     
                                                        <b-col>
                                                           <p>
                                                               {{ obterQtdeDerForecast(itemS, itemV.resultadoForecast) }}
                                                           </p>

                                                           <p class="sum_agr" v-if="isUltimoItem(indexD, itemV.derivacao)">
                                                                <strong> {{ calcularTotalArrayForecast(itemV.resultadoForecast) }} </strong> 

                                                            </p>
                                                        </b-col>

                                                        <b-col>
                                                            <p>
                                                                {{ obterQtdeDerivacoes(itemS, itemV.resultadoFat, 2) }}
                                                            </p>

                                                            <p class="sum_agr" v-if="isUltimoItem(indexD, itemV.derivacao)">
                                                                <strong> {{ calcularTotalArrayFat(itemV.resultadoFat, 2) }} </strong> 
                                                            </p>
                                                        </b-col>

                                                        <b-col>
                                                            <p>
                                                                {{ obterQtdeDesvioGroup(itemS, itemV) }}
                                                            </p>

                                                            <p class="sum_agr" v-if="isUltimoItem(indexD, itemV.derivacao)">
                                                                <strong> {{ calcularTotalArrayDesvioGroup(itemV) }} </strong> 
                                                            </p>

                                                        </b-col>
                                                        <b-col>
                                                            <p>
                                                                {{ obterQtdeDesvioPorcentGroup(itemS, itemV) }}
                                                            </p>

                                                            <p class="sum_agr" v-if="isUltimoItem(indexD, itemV.derivacao)">
                                                                <strong> {{ calcularTotalArrayDesvioPorcentGroup(itemV) }} </strong> 
                                                            </p>
                                                        </b-col>
                                                    </b-row>

                                                </div>
                                            </div>
                                        </div>
                                    </b-card>
                                    
                                </div>

                            <div>

                                <div  style="display: inline-flex; padding: 5px;">
                                    <div class="agrupador_card agrupadorPrevious">
                                        <p class="name_product"><strong> {{ item.name }} </strong> </p>
                                        <p v-for="der in item.items" :key="der">
                                        {{ item.code }} - {{ der.derivation }}
                                        </p>
                                        <p> <strong>Totais</strong> </p>
                                    </div>

                                    <!-- AGRUPADOR PROXIMOS 14 MESES -->
                                    <b-card class="agrupadorPrevious name_product" v-for="itemMonth in monthsNext" :key="itemMonth">
                                        <p> <strong> {{ itemMonth }} </strong> </p>

                                        <div v-for="(itemV, indexV) in item.values_next" :key="indexV">
                                            <b-row v-if="itemV.data.toLowerCase() == itemMonth"  class="name_month_next">
                                                <b-col md="2">Forecasting</b-col>
                                                <b-col md="2">Entrada</b-col>
                                                <b-col md="2">Est Final</b-col>
                                                <b-col md="2">EFMA</b-col>
                                                <b-col md="2">M Méd</b-col>
                                                <b-col md="2">M For</b-col>
                                            </b-row>

                                            <div v-for="(itemS, indexD) in itemV.derivacao" :key="itemS">
                                                <div v-if="itemV.data.toLowerCase() == itemMonth">
                                                    <b-row class="row_values_agrupador">
                                                        <b-col md="2">
                                                           <p>
                                                                {{ obterQtdeDerForecast(itemS, itemV.resultadoForecast) }}
                                                           </p>

                                                           <p class="sum_agr" v-if="isUltimoItem(indexD, itemV.derivacao)">
                                                            <strong> {{ calcularTotalArrayForecast(itemV.resultadoForecast) }} </strong> 
                                                        </p>
                                                        </b-col>

                                                        <b-col md="2">
                                                            <p>
                                                                {{ obterQtdeDerEntrada(itemS, itemV.resultadoEntrada) }}
                                                            </p>

                                                            <p class="sum_agr" v-if="isUltimoItem(indexD, itemV.derivacao)">
                                                                <strong> {{ calcularTotalArrayEntrada(itemV.resultadoEntrada) }} </strong> 
                                                            </p>
                                                        </b-col>

                                                        <b-col md="2">
                                                            <p>
                                                                {{ obterQtdeDerFinalGroup(itemS, itemV, itemMonth ) }}
                                                            </p>

                                                            <p class="sum_agr" v-if="isUltimoItem(indexD, itemV.derivacao)">
                                                                <strong> {{ calcularTotalArrayEstFinalGroup(itemV) }} </strong> 
                                                            </p>
                                                        </b-col>

                                                        <b-col md="2">
                                                            <p>
                                                              {{ obterQtdeDerEfmaGroup(itemS, itemV) }}
                                                            </p>
                                                            <p class="sum_agr" v-if="isUltimoItem(indexD, itemV.derivacao)">
                                                                <strong> {{calcularTotalArrayEFMAGroup(itemV)}} </strong>
                                                            </p>
                                                        </b-col>

                                                        <b-col md="2">
                                                            <p>
                                                                {{ obterQtdeDerMediaGroup(itemS, itemV) }}
                                                            </p>

                                                            <p class="sum_agr" v-if="isUltimoItem(indexD, itemV.derivacao)">
                                                                <strong> {{ calcularTotalArrayMediaGroup(itemV) }} </strong> 
                                                            </p>
                                                        </b-col>

                                                        <b-col md="2">
                                                            <p>
                                                                {{ obterQtdeDerMforGroup(itemS, itemV.forecastProximoMes) }}
                                                            </p>

                                                            <p class="sum_agr" v-if="isUltimoItem(indexD, itemV.derivacao)">
                                                                <strong> {{ calcularTotalMForGroup(itemV) }} </strong> 
                                                            </p>
                                                        </b-col>
                                                    </b-row>

                                                </div>
                                            </div>

                                        </div>
                                    </b-card>
                                </div>
                                
                                </div>
                                
                            </div>
                        </b-row>
                    </div>
                </div>
            </div>
        </PageComponent>
    </div>
</template>

<script>
import PageComponent from "../page/PageComponent.vue";
import moment from "moment";
import { format } from 'date-fns'; // Importe as funções necessárias do date-fns

export default {
    name: "S&opComponent",
    components: {
        PageComponent,
    },
    data() {
        return {
            userLogged: localStorage.getItem("userLogged")
                ? JSON.parse(localStorage.getItem("userLogged"))
                : null,
            perPage: 10,
            currentPage: 1,
            search: "",
            products: [],
            date: format(new Date(), 'yyyy-MM'),
            all_select: false,
            select_product: [],
            teste: '',
            months: '',
            months_history: '',
            months_history2: '',
            months_history3: '',
            qtd_months: 3,
            parameters: [],
            estoque: false,
            embal: false,
            prog: false,
            infos: false,
            winWidth: 0,
            items: [],
            countEstFin: 0,
            additionalColumn: { key: 'produtos', label: 'produtos' },
            itensSelecionados: [],
            itemsPerPage: 10,
            items1: ["Item 1", "Item 2", "Item 3", "Item 4"],
            showIndex: -1,
            isOpen: [],
            parametersNameStock: [],
            progresso: 0,
			itensProcessados: [],
			processando: false,
            isProgress: false,
            loadingIndex: null,
            loadingIndexes: [],
            loadingPercentages: Array.from({ length: 3 }, () => 0), // Inicializa a porcentagem para cada item
            result_forecast: [],
            totalStocks: 0,
            paginaAtual: 1,
			itensPorPagina: 10,
            products_history: [],
            codes: [],
            histories_mes3: [],
            histories_mes2: [],
            histories_mes1: [],
            monthsPreviousQtd: 14,
            monthsPrevious: [],
            monthsNext: [],
        };
    },

    mounted() {
        this.$root.$emit('bv::toggle::collapse', 'sidebar', false);
        this.getParameters();
        this.getProducts();
        this.getItemsSeop();
        this.monthsPrevious = [...Array(18)].map((_, index) => {
                let currentDate = moment(this.date);
                return currentDate.subtract(index + 1, 'months').locale('pt').format("MMM, YYYY");
            })

        this.monthsNext = [...Array(19)].map((_, index) => {
                let currentDate = moment(this.date);
                return currentDate.add(index + 1, 'months').locale('pt').format("MMM, YYYY");
            })

        this.toggleCheckbox();

    },

    methods: {
        toFixedTrunc(x, n) {
            const v = (typeof x === 'string' ? x : x.toString()).split('.');
            if (n <= 0) return v[0];
            let f = v[1] || '';
            if (f.length > n) return `${v[0]}.${f.substr(0,n)}`;
            while (f.length < n) f += '0';
            return `${v[0]}.${f}`
        },
        formatNumber(value) {     
            if (value == 0){
                return value;
            }      
            // Pega apenas a parte inteira do valor
            let intValue = Math.floor(value);

            // Converte o valor inteiro para string
            let intValueStr = intValue.toString();

            // Usa uma expressão regular para adicionar os pontos
            let valorFormatado = intValueStr.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return valorFormatado;
        },
        formatNumberTotalForecast(value) {  
            let stringValue = value.toString();
    
            let [integerPart, decimalPart] = stringValue.split(".");
            
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            
            if (decimalPart) {
                decimalPart = decimalPart.slice(0, 3); 
                return `${integerPart},${decimalPart}`;
            } else {
                return integerPart; 
            }
            
            // return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        formatDecimal(value, decimalPlaces) {
            let formattedValue = value.toFixed(decimalPlaces);
            
            return formattedValue;
        },
        formatNumberEfma(value) {
        let number = parseFloat(value);

        if (number === 0) {
            return "0";
        }

        let roundedNumber = number.toFixed(3);

        return roundedNumber;
        },

        formatPercent(value) {
            if( value == 0){
                return 0;
            }
            else if (value < 10){
                value = value.toFixed(2).replace('.', ',');
                return value;
             } else if (value > 10 && value < 100) {
                value = value.toFixed(2).replace('.', ',');
                return value;
            } else if(value > 100 && value < 1000){
                value = (value / 100);

                value = Math.round(value * 10) / 10;

                value = value.toFixed(2).replace('.', ',');

                return value;
            }else if(value >= 1000){
                value = (value / 1000);

                value = Math.round(value * 100) / 100;

                value = value.toFixed(2).replace('.', ',');

                return value;
            }


        },
        scrollToSide() {
          // Rola para o lado
          const itemsContainer = document.querySelector('.items-container');
          itemsContainer.scrollLeft += 100; // Quantidade de rolagem (ajuste conforme necessário)
        },
        scrollToLeft() {
          // Rola para a esquerda
          const itemsContainer = document.querySelector('.items-container');
          itemsContainer.scrollLeft -= 100; // Quantidade de rolagem (ajuste conforme necessário)
        },
        scrollToRight() {
          // Rola para a direita
          const itemsContainer = document.querySelector('.items-container');
          itemsContainer.scrollLeft += 100; // Quantidade de rolagem (ajuste conforme necessário)
        },
        calcularTotalArrayFat(fatLiq, boolformatar = 0) {
            const total = fatLiq.reduce((acc, item) => {
                    return acc + (item.qtd_ped || 0); // Se qtd_ped não existir, considera como 0
            }, 0);
            if( boolformatar == 2){
                if(fatLiq.leadTotal > 0) {
                    return total.toLocaleString('pt-BR');
                } else {
                    return total.toLocaleString('pt-BR');
                }
            }
            if ( boolformatar == 3){
                if(fatLiq.leadTotal > 0) {
                    return total;
                } else {
                    return total;
                }
            }
            if(fatLiq.leadTotal > 0) {
                return total;
            } else {
                return total;
            }
        },

        calcularTotalArrayForecast(items) {
            const totalFinal = items.reduce((total, item) => {
                return parseFloat(total) + parseFloat(item.qtd_ped);
            }, 0);

            return this.formatNumber((totalFinal));
        },

        calcularTotalArrayMLinear(items) {
            const totalFinal = items.reduce((total, item) => {
                return parseFloat(total) + parseFloat(item.qtd_ped);
            }, 0);

            return this.formatNumber((totalFinal/3));
        },
        
        calcularTotalArrayEntrada(items) {
            const totalFinal = items.reduce((total, item) => {
                return parseFloat(total) + parseFloat(item.total);
            }, 0);

            return this.formatNumber((totalFinal));
        },

        calcularTotalArrayDesvioGroup(items) {
            let forecastTotal = 0;
            let fatLiquidoTotal = 0;
            let result = 0;

            for (const item of items.derivacao) {
                if(items.resultadoForecast != 0){
                    const forecastDer = items.resultadoForecast.find(est => est.codDer === item);
                    forecastTotal = forecastDer ? forecastDer.qtd_ped : 0;
                } else {
                    forecastTotal = 0;
                }

                if(items.resultadoFat != 0){
                    fatLiquidoTotal = this.obterQtdeDerivacoes(item, items.resultadoFat);
                } else {
                    fatLiquidoTotal = 0;
                }
                let total = parseFloat(fatLiquidoTotal) - parseFloat(forecastTotal);
                if (isNaN(total)) {
                    total = 0;
                }
                result += total;
            }

                   
            // if (result <= 0) {
            //     result = 0;
            // }

            return this.formatNumber(result);
        },

        calcularTotalArrayDesvioPorcentGroup(items) {
            let forecastTotal = 0;
            let desvioTotal = 0;
            let result = 0;
            let total = 0;

            for (const item of items.derivacao) {
                if(items.resultadoForecast != 0){
                    const forecastDer = items.resultadoForecast.find(est => est.codDer === item);
                    forecastTotal += forecastDer ? forecastDer.qtd_ped : 0;
                } else {
                    forecastTotal += 0;
                }
                
                desvioTotal += parseInt(localStorage.getItem("desvioAtual" + item));
            }

            total = parseInt(desvioTotal) / parseInt(forecastTotal);

            if (isNaN(total)) {
                total = 0;
            } 

            result += total;

            if (this.toFixedTrunc(result, 1) == 'Infinity.0') {
                result = 0;
            }

            return this.toFixedTrunc(result, 1);
        },

        calcularTotalArrayEstFinalGroup(items) {
            let res_entrada = 0;
            let res_est = 0;
            let res_forecast = 0;
            let res_entrada2 = 0;
            let res_forecast2 = 0;
            let result = 0;

            if (items.dataNumeral == items.dataInicialGroup) {
                for (const item of items.derivacao) {
                    if(items.estFinalMesAnterior != 0){
                        let estFinalAnterior = items.estFinalMesAnterior.find(est => est.codDer === item);
                        res_est += estFinalAnterior ? parseFloat(estFinalAnterior.total) : 0;
                    } else {
                        res_est += 0;
                    }

                    if(items.resultadoForecast != 0){
                        let forecast = items.resultadoForecast.find(est => est.codDer === item); 
                        res_forecast += forecast ? parseFloat(forecast.qtd_ped) : 0;
                    } else {
                        res_forecast += 0;
                    }

                    if(items.entrada != 0){
                        let entrada = items.entrada.find(est => est.codDer === item);
                        res_entrada += entrada ? parseFloat(entrada.total) : 0;
                    } else {
                        res_entrada += 0;
                    } 
                }
                result = ((res_est - res_forecast) + res_entrada);
                localStorage.setItem("estFinalAnteriorTotal", result);

                return this.formatNumber(result);
            } else {
                let estStorageTotal = localStorage.getItem("estFinalAnteriorTotal");
                if (estStorageTotal === null) {
                    estStorageTotal = 0;
                }

                for (const item of items.derivacao) {
                    if(items.resultadoForecast != 0){
                        let forecast = items.resultadoForecast.find(est => est.codDer === item); 
                        res_forecast2 += forecast ? parseFloat(forecast.qtd_ped) : 0;
                    } else {
                        res_forecast2 += 0;
                    }

                    if(items.entrada != 0){
                        let entrada = items.entrada.find(est => est.codDer === item);
                        res_entrada2 += entrada ? parseFloat(entrada.total) : 0;
                    } else {
                        res_entrada2 += 0;
                    } 
                }

                result = ((estStorageTotal - res_forecast2) + res_entrada2);
                localStorage.setItem("estFinalAnteriorTotal", result);

                return this.formatNumber(result);
            }
        },

        calcularTotalArrayEFMAGroup(items) {
            let efmaAnteriorTotal = 0;
            let media3UltimosMesesTotal = 0;
            let result = 0;
            let media3UltimosMesesTotal2 = 0;

            if (items.dataNumeral == items.dataInicialGroup) {
                for (const item of items.derivacao) {
                    if(items.efmaMesAnterior != 0){
                        let efmaA = items.efmaMesAnterior.find(efma => efma.codDer === item);
                        let calc = parseFloat(efmaA.qtd_ped.replaceAll(".",""));
                        efmaAnteriorTotal += efmaA ? calc : 0;
                    } else {
                        efmaAnteriorTotal += 0;
                    }

                    if(items.ultimos3Meses != 0){
                        let ultimos3Meses = items.ultimos3Meses.find(m3 => m3.codDer === item);
                        media3UltimosMesesTotal += ultimos3Meses ? ultimos3Meses.media_qtd_ped : 0;
                    } else {
                        media3UltimosMesesTotal += 0;
                    }
                }

                result = parseInt(efmaAnteriorTotal) - parseInt(media3UltimosMesesTotal);
                localStorage.setItem("efmaAnteriorTotal", result);
                return this.formatNumber(result);
            } else {
                let efmaStorageTotal = localStorage.getItem("efmaAnteriorTotal");
                if (efmaStorageTotal === null) {
                    efmaStorageTotal = 0;
                }

                for (const item of items.derivacao) {
                    if(items.ultimos3Meses != 0){
                        let ultimos3Meses = items.ultimos3Meses.find(m3 => m3.codDer === item);
                        media3UltimosMesesTotal2 += ultimos3Meses ? ultimos3Meses.media_qtd_ped : 0;
                    } else {
                        media3UltimosMesesTotal2 += 0;
                    }
                }

                result = parseInt(efmaStorageTotal) - parseInt(media3UltimosMesesTotal2);

                localStorage.setItem("efmaAnteriorTotal", result);
                return this.formatNumber(result);
            }
        },

        calcularTotalArrayMediaGroup(items) {
            let efmaAnteriorTotal = 0;
            let media3UltimosMesesTotal = 0;
            let media3UltimosMesesTotal2 = 0;
            let result = 0;

            if (items.dataNumeral == items.dataInicialGroup) {
                for (const item of items.derivacao) {
                    if(items.efmaMesAnterior != 0){
                        let efmaA = items.efmaMesAnterior.find(efma => efma.codDer === item);
                        let calc = parseFloat(efmaA.qtd_ped.replaceAll(".",""));
                        efmaAnteriorTotal += efmaA ? calc : 0;
                    } else {
                        efmaAnteriorTotal += 0;
                    }

                    if(items.ultimos3Meses != 0){
                        let ultimos3Meses = items.ultimos3Meses.find(m3 => m3.codDer === item);
                        media3UltimosMesesTotal += ultimos3Meses ? ultimos3Meses.media_qtd_ped : 0;
                    } else {
                        media3UltimosMesesTotal += 0;
                    }
                }

                result = parseInt(efmaAnteriorTotal);
                if (result != 0) {
                    result = result / media3UltimosMesesTotal;
                    return this.toFixedTrunc(parseFloat(result), 1); 
                } else {
                    return result;
                }
            } else {
                let efmaStorageTotal = localStorage.getItem("efmaAnteriorTotal");
                if (efmaStorageTotal === null) {
                    efmaStorageTotal = 0;
                }

                for (const item of items.derivacao) {
                    if(items.ultimos3Meses != 0){
                        let ultimos3Meses = items.ultimos3Meses.find(m3 => m3.codDer === item);
                        media3UltimosMesesTotal2 += ultimos3Meses ? ultimos3Meses.media_qtd_ped : 0;
                    } else {
                        media3UltimosMesesTotal2 += 0;
                    }
                }

                result = parseInt(efmaStorageTotal) - parseInt(media3UltimosMesesTotal2);
                if (result != 0) {
                    result = result / media3UltimosMesesTotal2;
                    return this.toFixedTrunc(parseFloat(result), 1); 
                } else {
                    return result;
                }
            }
            
        },

        calcularTotalArrayDesvio(desvio) {
            const total = desvio.reduce((acc, item) => {
                    return acc + (item || 0); // Se qtd_ped não existir, considera como 0
            }, 0);

            if(desvio.leadTotal > 0) {
                return this.formatNumber(total);
            } else {
                return this.formatNumber(total);
            }
        },


        desvioPercentual(forecasting, desvio) {
        if (forecasting === 0) {
            return 0;
        } else {
            // Calcular o percentual
            // forecasting = parseInt(forecasting.toString().replace('.', ''), 10);

            let percentual = (desvio / forecasting) * 100;

            percentual = percentual.toFixed(1).replace('.', ',');

            return percentual;

        }

        },

        sumDesvioPercentual(totalDesvio, totalForecast) {
            return ((totalDesvio / totalForecast)* 100).toFixed(0).replace('.', ',');
            
        },

        hasValidStock(stock, derivation, name) {
            return stock && stock.some(stockItem =>
                stockItem.total_qtd_ped > 0 &&
                stockItem.codDer == derivation &&
                stockItem.name == name
            );
        },

        toggleItem(index) {
            // Verificar se o card atual está aberto
            const isOpen = this.isOpen[index];

            // Fechar todos os cards se o card atual já estiver aberto
            if (isOpen) {
                this.isOpen = this.isOpen.map(() => false);
            } else {
                // Se o card atual não estiver aberto, fechar todos os cards e abrir o atual
                this.isOpen = this.isOpen.map(() => false);
                this.$set(this.isOpen, index, true);
            }
        },

        changePage(page) {
            this.currentPage = page;
        },

        calcularTotalLeadTime(items) {
            const totalArray = items.map(item => {

            if(item.leadTime != 0){
                return item.leadTime.reduce((leadTotal, lead) => {
                    if (lead.codDer === item.derivation) {
                        return parseInt(leadTotal) + parseInt(lead.qtd);
                    }
                    return leadTotal;
                    }, 0);
            } else {
                return 0;
            }
            });

            return parseFloat(this.formatNumber(totalArray.reduce((total, value) => total + value, 0)));
        },

        calcularTotal(items) {
            let total = 0;

            items.forEach(item => {
                if (item.forecast !== 0) {
                    item.forecast.forEach(forec => {
                        if (forec.codDer === item.derivation) {
                            total += forec.qtd;
                        }
                    });
                }
            });
            console.log("total: " + total)
            return total;
        },

        calcularTotalForecast(items) {
            const totalArray = items.map(item => {
            if(item.forecast != 0){
                return item.forecast.reduce((forecast, forec) => {
                if (forec.codDer === item.derivation) {
                    return parseInt(forecast) + parseInt(forec.qtd);
                }
                return forecast;
                }, 0);
            } else {
                return 0;
            }
            });

            return this.formatNumber(totalArray.reduce((total, value) => total + value, 0));

        },

        calcularTotalForecastFuturo(items) {
            const totalArray = items.map(item => {
            if(item.forecastFuturo != 0){
                return item.forecastFuturo.reduce((forecastFuturo, forec) => {
                if (forec.codDer === item.derivation) {
                    return parseInt(forecastFuturo) + parseInt(forec.qtd);
                }
                return forecastFuturo;
                }, 0);
            } else {
                return 0;
            }
            });

            return this.formatNumber(totalArray.reduce((total, value) => total + value, 0));

        },

        calcularTotalLiquido(items) {
            const totalArray = items.map(item => {
            if(item.liquido != 0){
                return item.liquido.reduce((forecast, forec) => {
                if (forec.codDer === item.derivation) {
                    return parseInt(forecast) + parseInt(forec.qtd_ped);
                }
                return forecast;
                }, 0);
            } else {
                return 0;
            }
            });

            return this.formatNumber(totalArray.reduce((total, value) => total + value, 0));
        },

        calcularTotalVolume(items) {
            const totalArray = items.map(item => {
            if(item.volume != 0){
                return item.volume.reduce((volume, vol) => {
                    if (vol.codDer === item.derivation) {
                        return parseInt(volume) + parseInt(vol.qtd_comprada);
                    }
                    return volume;
                    }, 0);
            } else {
                return 0;
            }
            });

            return parseFloat(this.formatNumber(totalArray.reduce((total, value) => total + value, 0)));
        },

        calcularTotalEntrada(items) {
            const totalArray = items.map(item => {
            if(item.entrada != 0){
                return item.entrada.reduce((entrada, ent) => {
                    if (ent.codDer === item.derivation) {
                        return parseInt(entrada) + parseInt(ent.total);
                    }
                    return entrada;
                    }, 0);
                } else {
                return 0;
            }
            });

            return this.formatNumber(totalArray.reduce((total, value) => total + value, 0));
        },

        calcularTotalEstoque(items) {
            const totalArray = items.map(item => {
                if(item.estSeguranca != 0){

                return item.estSeguranca.reduce((entrada, ent) => {
                if (ent.codDer === item.derivation) {
                    return parseInt(entrada) + parseInt(ent.qtd);
                }
                return entrada;
                }, 0);
            } else {
                return 0;
            }
            });

            return parseFloat(this.formatNumber(totalArray.reduce((total, value) => total + value, 0)));
        },


        calcularTotalStocks(result_forecast, field_name) {
            let total = 0;
            for (const item of result_forecast) {
                if(item.stock != 0){
                    for (const stock of item.stock) {
                        if (stock.codDer == item.derivation && stock.name == field_name) {
                            total += stock.total_qtd_ped;
                        }
                    }
                } else {
                    total = 0;
                }
                
            }
            return this.formatNumber(total);
        },

        calcularTotalPedAberto(items) {
            const totalArray = items.map(item => {
            if(item.pedAberto != 0){

            return item.pedAberto.reduce((entrada, ent) => {
                if (ent.codDer === item.derivation) {
                    return parseInt(entrada) + parseInt(ent.qtd_ped);
                }
                return entrada;
                }, 0);
            } else {
                return 0;
            }
            });

            return parseFloat(this.formatNumber(totalArray.reduce((total, value) => total + value, 0)));
        },

        calcularTotalLogistica(items) {
            const totalArray = items.map(item => {
            if(item.logistica != 0){

            return item.logistica.reduce((entrada, ent) => {
                if (ent.codDer === item.derivation) {
                    return parseInt(entrada) + parseInt(ent.qtd_ped);
                }
                return entrada;
                }, 0);
            } else {
                return 0;
            }
            });

            // return parseFloat(totalArray.reduce((total, value) => total + value, 0)).toFixed(2);
            return parseFloat(this.formatNumber(totalArray.reduce((total, value) => total + value, 0)));
        },

        calcularTotalPlanejamento(items) {
            const totalArray = items.map(item => {
                if(item.estplanejamento != 0){
                    return item.estplanejamento.reduce((entrada, ent) => {
                    if (ent.codDer === item.derivation) {
                        return parseFloat(entrada) + parseFloat(ent.qtdEst);
                    }
                    return entrada;
                    }, 0);
                } else {
                    return 0;
                }
            });

            return this.formatNumber(totalArray.reduce((total, value) => total + value, 0));
        },

        temDerivacao(derivation, estplanejamento) {
            if(estplanejamento){
                return estplanejamento.some(est => est.codDer === derivation);
            }
        },

        obterQuantidadeDerivacao(derivation, estplanejamento) {
            const item = estplanejamento.find(est => est.codDer === derivation);
            return item ? this.formatNumber(item.qtdEst) : 0;
        },

        obterQtdeDerEfma(derivation, efma) { 
            const item = efma.find(est => est.codDer === derivation);
            let result = (item ? item.qtd_ped : 0)
            return result;
        },

        obterQtdeDerMfor(derivation, mfor) {
            const item = mfor.find(est => est.codDer === derivation);
            return item ? this.toFixedTrunc(item.total, 1) : 0;
        },

        obterQtdeDerMforGroup(derivation, forecast) {
            const item = forecast.find(est => est.codDer === derivation);
            let estStorage = localStorage.getItem("estFinalAtual" + derivation);
            let result = 0;
            result = estStorage / (item ? item.qtd : 0);

            if (result == 0) {
                return 0;
            } else {
                return item ? this.toFixedTrunc(result, 1) : 0;
            }
        },

        obterQtdeDerMedio(derivation, mmed) {
            const item = mmed.find(est => est.codDer === derivation);
            return item ? this.toFixedTrunc(parseFloat(item.total), 1) : 0;
        },

        obterQtdeDerForecast(derivation, forecast) {
            const item = forecast.find(est => est.codDer === derivation);
            return this.formatNumber(item ? item.qtd_ped : 0);
        },

        obterQtdeDerMLinear(derivation, mlinear) {
            const item = mlinear.find(est => est.codDer === derivation);
            return this.formatNumber(item ? (item.qtd_ped/3) : 0);
        },

        obterQtdeDerEntrada(derivation, entrada) {
            const item = entrada.find(est => est.codDer === derivation);
            return this.formatNumber(item ? item.total : 0);
        },

        obterQtdeDerData(derivation, data) {
            const item = data.find(est => est.codDer === derivation);
            return item ? item.data : 0;
        },

        obterQtdeDerVolume(derivation, volume) {
            const item = volume.find(est => est.codDer === derivation);
            return this.formatNumber(item ? item.qtd_comprada : 0);
        },

        obterQuantidadeForecast(derivation, forecast){//CORRIGIR
            const item = forecast.find(est => est.codDer === derivation);
            return this.formatNumber(item ? item.qtd : 0);
        },

        obterQuantidadeAberto(derivation, pedAberto){
            const item = pedAberto.find(est => est.codDer === derivation);
            return this.formatNumber(item ? item.qtd_ped : 0);
        },

        obterQuantidadeLogistica(derivation, logistica){
            const item = logistica.find(est => est.codDer === derivation);
            return this.formatNumber(item ? item.qtd_ped : 0);
        },

        obterQuantidadeRealizar(derivation, realizar){
            const item = realizar.find(est => est.codDer === derivation);
            let result = 0;

            console.log('REALIZAR', item.total);

            if (item.total == 0) {
                result = item.forecast;
            } else {
                result = parseInt(item.total);
            }

            return this.formatNumber(result);
        },

        obterQuantidadeLiquido(derivation, liquido){
            const item = liquido.find(est => est.codDer === derivation);
            return this.formatNumber(item ? item.qtd_ped : 0);
        },

        obterQuantidadeLeadTime(derivation, leadTime){
            const item = leadTime.find(est => est.codDer === derivation);
            return this.formatNumber(item ? item.qtd : 0);
        },

        obterQtdeDerSeguranca(derivation, estSeguranca){
            const item = estSeguranca.find(est => est.codDer === derivation);
            return this.formatNumber(item ? item.qtd : 0);
        },

        obterQtdeDerStock(derivation, stock, name){
            const item = stock.find(est => est.codDer === derivation && est.name == name);
            return this.formatNumber(item ? item.total_qtd_ped : 0);
        },
        formatNumberQntDerFinal(number) {
                // Separar a parte inteira da parte decimal
                let [integerPart, decimalPart] = number.toFixed(2).split('.');

                // Adicionar o separador de milhar
                let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

                // Juntar a parte inteira formatada com a parte decimal
                return `${formattedIntegerPart},${decimalPart}`;
            },

        obterQtdeDerFinal(derivation, item){
            let res_entrada = 0;
            let res_item = 0;
            let res_realizar = 0;

            if(item.estplanejamento != 0){
                let item_pla = item.estplanejamento.find(est => est.codDer === derivation);
                res_item = item_pla ? item_pla.qtdEst : 0;
            } else {
                res_item = 0;
            }

            if(item.realizar != 0){
                let realizar = item.realizar.find(est => est.codDer === derivation); 
                //res_realizar = realizar ? realizar.total : 0;
                if(realizar.total == 0){
                    res_realizar = realizar.forecast;
                } else {
                    res_realizar = realizar ? Number(realizar.total.toString().replace('.', '')) : 0; //336.859
                }
            } else {
                res_realizar = 0;
            }

            if(item.entrada != 0){
                let entrada = item.entrada.find(est => est.codDer === derivation);
                res_entrada = entrada ? entrada.total : 0;
            } else {
                res_entrada = 0;
            }   
           
            return ((res_item - res_realizar) + res_entrada).toLocaleString('pt-BR');


        },
        
        obterQtdeDesvioGroup(itemS, item) {
            let forecast = 0;
            let fatLiquido = 0;
            let result = 0;

            const forecastDer = item.resultadoForecast.find(est => est.codDer === itemS);
            forecast = forecastDer ? forecastDer.qtd_ped : 0;
            
            fatLiquido = this.obterQtdeDerivacoes(itemS, item.resultadoFat);

            result = parseInt(fatLiquido) - parseInt(forecast);
            
            if (isNaN(result)) {
                result = 0;
            }

            localStorage.setItem("desvioAtual" + itemS, result);

            return this.formatNumber(result);
        },

        obterQtdeDesvioPorcentGroup(itemS, item) {
            let forecast = 0;
            let desvio = 0;
            let result = 0;
            

            const forecastDer = item.resultadoForecast.find(est => est.codDer === itemS);
            forecast = forecastDer ? forecastDer.qtd_ped : 0;
            
            desvio = localStorage.getItem("desvioAtual" + itemS);

            result = parseInt(desvio) / parseInt(forecast);

            if (isNaN(result)) {
                result = 0;
            } 

            if (this.toFixedTrunc(result, 1) == 'Infinity.0') {
                result = 0;
            } 

            if (this.toFixedTrunc(result, 1) == '-Infinity.0') {
                result = 0;
            } 
            
            return this.toFixedTrunc(result, 1);
        },

        obterQtdeDesvio3MesesPorcentGroup(derivation, item) {
            let desvioTotal = 0;
            let desvioPorcentTotal = 0;
            let forecastTotal = 0;
            let fatLiquidoTotal = 0;
            let somaDesvio = 0;
            let result = 0;

            for (const date of item.arrayMes) {
                    const itemsArray = Object.values(item.values);            
                    const mes = itemsArray.find(mes => mes.data === date);

                    const forecastMesDer = mes.resultadoForecast.find(forecast => forecast.codDer === derivation);
                    forecastTotal += forecastMesDer ? forecastMesDer.qtd_ped : 0;

                    const fatLiquidoMesDer = mes.resultadoFat.find(fat => fat.codDer === derivation);
                    fatLiquidoTotal += fatLiquidoMesDer ? fatLiquidoMesDer.qtd_ped : 0;
                    
                    desvioTotal = parseInt(fatLiquidoTotal) - parseInt(forecastTotal);
                    desvioPorcentTotal = parseInt(desvioTotal) / parseInt(forecastTotal)
    
                    somaDesvio += desvioPorcentTotal;
            }

            result = somaDesvio;

            if (isNaN(result)) {
                result = 0;
            } 

            return this.toFixedTrunc(result, 1);
        },

        obterQtdeDesvio3MesesPorcentTotalGroup(item) {
            let desvioTotal = 0;
            let desvioPorcentTotal = 0;
            let forecastTotal = 0;
            let fatLiquidoTotal = 0;
            let somaDesvio = 0;
            let somaDesvioTotal = 0;
            let result = 0;

            for (const date of item.arrayMes) {
                    const itemsArray = Object.values(item.values);        
                    const mes = itemsArray.find(mes => mes.data === date);
                    somaDesvio = 0;

                    for (const derivacao of mes.derivacao) {
                        const forecastMesDer = mes.resultadoForecast.find(forecast => forecast.codDer === derivacao);
                        forecastTotal += forecastMesDer ? forecastMesDer.qtd_ped : 0;

                        const fatLiquidoMesDer = mes.resultadoFat.find(fat => fat.codDer === derivacao);
                        fatLiquidoTotal += fatLiquidoMesDer ? fatLiquidoMesDer.qtd_ped : 0;
                       
                        desvioTotal = parseInt(fatLiquidoTotal) - parseInt(forecastTotal);
                        desvioPorcentTotal = parseInt(desvioTotal) / parseInt(forecastTotal)

                        somaDesvio += desvioPorcentTotal;
                    }

                    somaDesvioTotal += somaDesvio;
                    
            }

            result = somaDesvioTotal;

            if (isNaN(result)) {
                result = 0;
            } 

            return this.toFixedTrunc(result, 1);
        },

        obterQtdeDesvio3MesesMPorcentGroup(derivation, item) {
            let desvioTotal = 0;
            let desvioPorcentTotal = 0;
            let forecastTotal = 0;
            let fatLiquidoTotal = 0;
            let somaDesvio = 0;
            let result = 0;

            for (const date of item.arrayMes) {
                    const itemsArray = Object.values(item.values);
                    const mes = itemsArray.find(mes => mes.data === date);

                    const forecastMesDer = mes.resultadoForecast.find(forecast => forecast.codDer === derivation);
                    forecastTotal += forecastMesDer ? forecastMesDer.qtd_ped : 0;

                    const fatLiquidoMesDer = mes.resultadoFat.find(fat => fat.codDer === derivation);
                    fatLiquidoTotal += fatLiquidoMesDer ? fatLiquidoMesDer.qtd_ped : 0;
                    
                    desvioTotal = parseInt(fatLiquidoTotal) - parseInt(forecastTotal);
                    desvioPorcentTotal = parseInt(desvioTotal) / parseInt(forecastTotal)

                    somaDesvio += desvioPorcentTotal;
            }

            result = somaDesvio/3;

            if (isNaN(result)) {
                result = 0;
            } 

            return this.toFixedTrunc(result, 1);
        },

        obterQtdeDesvio3MesesMPorcentTotalGroup(item) {
            let desvioTotal = 0;
            let desvioPorcentTotal = 0;
            let forecastTotal = 0;
            let fatLiquidoTotal = 0;
            let somaDesvio = 0;
            let somaDesvioTotal = 0;
            let result = 0;

            for (const date of item.arrayMes) {
                const itemsArray = Object.values(item.values);
                const mes = itemsArray.find(mes => mes.data === date);
                somaDesvio = 0;

                for (const derivacao of mes.derivacao) {
                    const forecastMesDer = mes.resultadoForecast.find(forecast => forecast.codDer === derivacao);
                    forecastTotal += forecastMesDer ? forecastMesDer.qtd_ped : 0;

                    const fatLiquidoMesDer = mes.resultadoFat.find(fat => fat.codDer === derivacao);
                    fatLiquidoTotal += fatLiquidoMesDer ? fatLiquidoMesDer.qtd_ped : 0;

                    desvioTotal = parseInt(fatLiquidoTotal) - parseInt(forecastTotal);
                    desvioPorcentTotal = parseInt(desvioTotal) / parseInt(forecastTotal)

                    somaDesvio += desvioPorcentTotal;
                }

                somaDesvioTotal += somaDesvio; 
            }

            result = somaDesvioTotal/3;

            if (isNaN(result)) {
                result = 0;
            } 

            return this.toFixedTrunc(result, 1);
        },

        obterQtdeDerFinalGroup(derivation, item){
            let res_entrada = 0;
            let res_est = 0;
            let res_forecast = 0;
            let res_entrada2 = 0;
            let res_forecast2 = 0;
            let result = 0;

            if (item.dataNumeral == item.dataInicialGroup) {
                if(item.estFinalMesAnterior != 0){
                    let estFinalAnterior = item.estFinalMesAnterior.find(est => est.codDer === derivation);
                    res_est = estFinalAnterior ? parseFloat(estFinalAnterior.total) : 0;
                } else {
                    res_est = 0;
                }

                if(item.resultadoForecast != 0){
                    let forecast = item.resultadoForecast.find(est => est.codDer === derivation); 
                    res_forecast = forecast ? parseFloat(forecast.qtd_ped) : 0;
                } else {
                    res_forecast = 0;
                }

                if(item.entrada != 0){
                    let entrada = item.entrada.find(est => est.codDer === derivation);
                    res_entrada = entrada ? parseFloat(entrada.total) : 0;
                } else {
                    res_entrada = 0;
                } 
            
                result = ((res_est - res_forecast) + res_entrada);
                localStorage.setItem("estFinalAnterior" + derivation, result);
                localStorage.setItem("estFinalAtual" + derivation, result);

                return this.formatNumber(result);
            } else {
                let estStorage = localStorage.getItem("estFinalAnterior" + derivation);
                // console.log("Estoque final mes corrente ",estStorage );
                if (estStorage === null) {
                    estStorage = 0;
                }

                if(item.resultadoForecast != 0){
                    let forecast = item.resultadoForecast.find(est => est.codDer === derivation); 
                    res_forecast2 = forecast ? parseFloat(forecast.qtd_ped) : 0;
                } else {
                    res_forecast2 = 0;
                }

                if(item.entrada != 0){
                    let entrada = item.entrada.find(est => est.codDer === derivation);
                    res_entrada2 = entrada ? parseFloat(entrada.total) : 0;
                } else {
                    res_entrada2 = 0;
                } 
            
                result = ((estStorage - res_forecast2) + res_entrada2);
                localStorage.setItem("estFinalAnterior" + derivation, result);
                localStorage.setItem("estFinalAtual" + derivation, result);

                return this.formatNumber(result);
            }
        },

        obterQtdeDerFinalFuturo(derivation, item, mes){
            let forecasting = this.obterQtdeDerivacoes(derivation, item.resultadoForecast)
            forecasting = Number(forecasting.toString().replace('.', ''))
            let res_entrada = 0;
            let res_item = 0;
            let res_realizar = 0;
            if(item.estplanejamento != 0){
                let item_pla = item.estplanejamento.find(est => est.codDer === derivation);
                res_item = item_pla ? item_pla.qtdEst : 0;
            } else {
                res_item = 0;
            }
            
            
            if(item.realizar != 0){
                let realizar = item.realizar.find(est => est.codDer === derivation); 
                res_realizar = realizar ? Number(realizar.totalAnterior.toString().replace('.', '')) : 0; //336.859
            } else {
                res_realizar = 0;
            }
            
            if(item.entrada != 0){
                let entrada = item.entrada.find(est => est.codDer === derivation);
                res_entrada = entrada ? entrada.total : 0;
            } else {
                res_entrada = 0;
            }   
            let estMespassado = (res_item - res_realizar + res_entrada); 
            let result;
            
            let count = localStorage.getItem("counter")
            if (localStorage.getItem("DERIVATION_" + derivation) === null) {
                result = (estMespassado - forecasting + res_entrada);
            } else {
                
                result = (parseFloat(localStorage.getItem("DERIVATION_" + derivation)) - forecasting + res_entrada); 
            }
            count++;
            localStorage.setItem("counter", count);

            if( count > 75){
                
                for (let key in localStorage) {
                    if (key.startsWith("DERIVATION_")) {
                        localStorage.removeItem(key);
                    }
                }
            }

            if (count !== 75 ) {
                localStorage.setItem("DERIVATION_" + derivation, result);
            }
            if (localStorage.getItem(mes + "_" + derivation) === null){
                localStorage.setItem(mes + "_" + derivation,  result)
            }
            return result.toLocaleString('pt-BR');

        },

        obterQtdeFechado(derivation, pedido){
            const item = pedido.find(est => est.codDer === derivation);
            return item ? this.formatNumber(item.qtd_ped) : 0;
        },

        obterQtdeDerivacoes(derivation, leadTime, boolformatar = 0){
            const item = leadTime.find(est => est.codDer === derivation);
            let value = item ? (item.qtd_ped) : 0;
            if (boolformatar == 1){
                return item ? item.qtd_ped : 0;
            }
            if ( boolformatar == 2){
                    return value.toLocaleString('pt-BR');
            }
            return item ? (item.qtd_ped) : 0;
        },

        obterSomaDesvioPorcentagem(month, monthDerivacao, result, values) {  
            let somaFat = 0;
            let somaFore = 0;
            let forecasting = 0;
            let somaDesvio = 0;
            let totalDevio = 0;
            const itemsArray = Object.values(values);
    
            // Obtém os últimos 3 itens do array
            const ultimosTresItens = itemsArray.slice(-3);
            
            // Exibe os últimos 3 itens no console
            ultimosTresItens.forEach(itemV => {
                // console.log(itemV)
                Object.values(itemV.resultadoFat).forEach(fat => {
                    if(fat.codDer == monthDerivacao)
                    somaFat += fat.qtd_ped
                })
                Object.values(itemV.resultadoForecast).forEach(fore => {
                    if(fore.codDer == monthDerivacao){
                        somaFore += fore.qtd_ped
                        forecasting = fore.qtd_ped.toString().replace(/\./g, '');
                    }
                })
                
            });
            if (somaFat != 0 && somaFore != 0){
                somaFore = somaFore.toString().replace(/\./g, '');
                somaDesvio = (somaFat - somaFore)
            }
            totalDevio = somaFat - somaFore;
            totalDevio = (somaDesvio / forecasting);
            totalDevio = (totalDevio * 100).toFixed(1)
            totalDevio = totalDevio.replace('.', ',');
                // console.log("monthDerivacao = " + monthDerivacao);
                // console.log("somaFat = " + somaFat);
                // console.log("somaFore = " + somaFore);
                // console.log("forecasting = " + forecasting);
                // console.log("somaDesvio = " + somaDesvio);
                // console.log("totalDevio = " + totalDevio);
            
            return totalDevio;
        },

        ultimoResultadoSum(month, monthDerivacao, result, values) {
            let somaFat = 0;
            let somaFore = 0;
            let forecasting = 0;
            let somaDesvio = 0;
            let totalDevio = 0;
            let sumdesvioPercentualGeral = 0;
            const itemsArray = Object.values(values);
            
            const ultimosTresItens = itemsArray.slice(-3);

            ultimosTresItens[0].derivacao.forEach(der => {
                
                ultimosTresItens.forEach(itemV => {
                    Object.values(itemV.resultadoFat).forEach(fat => {
                        if (fat.codDer == der) {
                            somaFat += parseFloat(fat.qtd_ped);
                        }
                    });

                    Object.values(itemV.resultadoForecast).forEach(fore => {
                        if (fore.codDer == der) {
                            somaFore += parseFloat(fore.qtd_ped);
                            forecasting = parseFloat(fore.qtd_ped.toString().replace(/\./g, ''));
                        }
                    });
                });

                if (somaFat != 0 && somaFore != 0) {
                    somaFore = parseFloat(somaFore.toString().replace(/\./g, ''));
                    somaDesvio = (somaFat - somaFore);
                }

                if (forecasting != 0) {
                    totalDevio = (somaDesvio / forecasting) * 100;
                    totalDevio = parseFloat(totalDevio.toFixed(2));  
                } else {
                    totalDevio = 0;
                }

                sumdesvioPercentualGeral += totalDevio;

                totalDevio = 0;
                somaDesvio = 0;
                somaFore = 0;
                somaFat = 0;
            });

            let resultadoFormatado = sumdesvioPercentualGeral.toFixed(1).replace('.', ',');
            return resultadoFormatado;
        },

        calcularTotalDesvio(fore, fat){
            let forecast;
            let fatliq;
            if (typeof fore == 'string'){
                forecast = fore.replace('.', '');
                fatliq = fat.replace('.', '');
            }
 
            let value = (this.formatNumber(fatliq - forecast));
           return value;
  

        },
        isUltimoItem(index, array) {
            return index === array.length - 1;
        },
        obterQtdeDerivacoesDesvio(derivation, leadTime){
            const item = leadTime.find(est => est.codDer === derivation);
            let values = item ? item.qtd_ped : 0;
            return this.formatDecimal((values / 100) / 3, 2);
        },

        calcularTotalArrayDesvioSum(leadTime){
            const total = leadTime.reduce((acc, item) => {
                    return acc + ((item.qtd_ped / 100) / 3 || 0); // Se qtd_ped não existir, considera como 0
            }, 0);
            if(leadTime.leadTotal > 0) {
                return this.formatDecimal(total, 2);
            } else {
                return this.formatDecimal(total, 2);
            }
        },

        
        calcularTotalFinal(items) {
            let somaEstFinail = 0;

            items.map(item => {
                let res_entrada = 0;
                let res_item = 0;
                let res_realizar = 0;

                if(item.estplanejamento != 0){
                    let item_pla = item.estplanejamento.find(est => est.codDer === item.derivation);
                    res_item = item_pla ? item_pla.qtdEst : 0;
                } else {
                    res_item = 0;
                }

                if(item.realizar != 0){
                    let realizar = item.realizar.find(est => est.codDer === item.derivation); 
                    //res_realizar = realizar ? realizar.total : 0;
                    if(realizar.total == 0){
                        res_realizar = realizar.forecast;
                    } else {
                        res_realizar = realizar ? Number(realizar.total.toString().replace('.', '')) : 0; //336.859
                    }
                } else {
                    res_realizar = 0;
                }

                if(item.entrada != 0){
                    let entrada = item.entrada.find(est => est.codDer === item.derivation);
                    res_entrada = entrada ? entrada.total : 0;
                } else {
                    res_entrada = 0;
                }   

                somaEstFinail += ((res_item - res_realizar) + res_entrada);
            })
            
            return somaEstFinail.toLocaleString('pt-BR');
        },

        calcularTotalMFOR(items) {
            let totalForecasting = this.calcularTotalForecastFuturo(items);
            let totalestfinal = this.calcularTotalFinal(items);
            let total = 0;

            total = (totalestfinal / totalForecasting);

            return this.toFixedTrunc(parseFloat(total), 1);
        },

        calcularTotalEFMA(items) {
            const totalArray = items.map(item => {
            if(item.efma != 0){
                    item.efma.reduce((entrada, ent) => {
                        if (ent.codDer === item.derivation) {
                            let calc = parseFloat(ent.qtd_ped.replaceAll(".",""));
                            return parseFloat(entrada) + parseFloat(calc);
                        }
                    }, 0);

                    return item.efma.reduce((entrada, ent) => {
                        if (ent.codDer === item.derivation) {
                            let calc = parseFloat(ent.qtd_ped.replaceAll(".",""));
                            return parseFloat(entrada) + parseFloat(calc);
                        }
                        return entrada;
                    }, 0);
                } else {
                    return 0;
                }
            });

            return this.formatNumberEfma(totalArray.reduce((total, value) => total + value, 0));

        },

        calcularTotalEFMAT(items) {
            const totalArray = items.map(item => {
            if(item.efma != 0){
                return item.efma.reduce((efma, ent) => {
                    if (ent.codDer === item.derivation) {
                        let calc = parseFloat(ent.qtd_ped.replaceAll(".",""));
                        
                        return parseInt(efma) + parseInt(calc);
                    }
                    return efma;
                    }, 0);
                } else {
                return 0;
            }
            });

            return this.formatNumber(totalArray.reduce((total, value) => total + value, 0));
        },

        calcularTotalEFMAGroup(items) {
            const totalArray = items.map(item => {
            if(item.resultadoEFMA != 0){
                return item.resultadoEFMA.reduce((efma, ent) => {
                    if (ent.codDer === item.derivation) {
                        let calc = parseFloat(ent.qtd_ped.replaceAll(".",""));
                        
                        return parseInt(efma) + parseInt(calc);
                    }
                    return efma;
                    }, 0);
                } else {
                return 0;
            }
            });

            return this.formatNumber(totalArray.reduce((total, value) => total + value, 0));
        },

        obterQtdeDerEfmaGroup(derivation, item){
            let efmaAnterior = 0;
            let media3UltimosMeses = 0;
            let result = 0;
            let media3UltimosMeses2 = 0;

            if (item.dataNumeral == item.dataInicialGroup) {
                if(item.efmaMesAnterior != 0){
                    let efmaA = item.efmaMesAnterior.find(efma => efma.codDer === derivation);
                    let calc = parseFloat(efmaA.qtd_ped.replaceAll(".",""));
                    efmaAnterior = efmaA ? calc : 0;
                } else {
                    efmaAnterior = 0;
                }

                if(item.ultimos3Meses != 0){
                    let ultimos3Meses = item.ultimos3Meses.find(m3 => m3.codDer === derivation);
                    media3UltimosMeses = ultimos3Meses ? ultimos3Meses.media_qtd_ped : 0;
                } else {
                    media3UltimosMeses = 0;
                }

                result = parseInt(efmaAnterior) - parseInt(media3UltimosMeses);
                
                localStorage.setItem("efmaAnterior" + derivation, result);
                return this.formatNumber(result);
            } else {
                let efmaStorage = localStorage.getItem("efmaAnterior" + derivation);

                if (efmaStorage === null) {
                    efmaStorage = 0;
                }

                if(item.ultimos3Meses != 0){
                    let ultimos3Meses = item.ultimos3Meses.find(m3 => m3.codDer === derivation);
                    media3UltimosMeses2 = ultimos3Meses ? ultimos3Meses.media_qtd_ped : 0;
                } else {
                    media3UltimosMeses2 = 0;
                }

                result = parseInt(efmaStorage) - parseInt(media3UltimosMeses2);

                localStorage.setItem("efmaAnterior" + derivation, result);

                return this.formatNumber(result);
            }

        },

        obterQtdeDerMediaGroup(derivation, item){
            let efmaAnterior = 0;
            let media3UltimosMeses = 0;
            let media3UltimosMeses2 = 0;
            let result = 0;

            if (item.dataNumeral == item.dataInicialGroup) {
                if(item.efmaMesAnterior != 0){
                    let efmaA = item.efmaMesAnterior.find(efma => efma.codDer === derivation);
                    let calc = parseFloat(efmaA.qtd_ped.replaceAll(".",""));
                    efmaAnterior = efmaA ? calc : 0;
                } else {
                    efmaAnterior = 0;
                }

                if(item.ultimos3Meses != 0){
                    let ultimos3Meses = item.ultimos3Meses.find(m3 => m3.codDer === derivation);
                    media3UltimosMeses = ultimos3Meses ? ultimos3Meses.media_qtd_ped : 0;
                } else {
                    media3UltimosMeses = 0;
                }

                result = parseInt(efmaAnterior) - parseInt(media3UltimosMeses);
                if (result != 0) {
                    result = this.formatNumber(result) / this.formatNumber(media3UltimosMeses);
                    return this.toFixedTrunc(parseFloat(result), 1); 
                } else {
                    return result;
                }
                
            } else {
                let efmaStorage = localStorage.getItem("efmaAnterior" + derivation);

                if (efmaStorage === null) {
                    efmaStorage = 0;
                }

                if(item.ultimos3Meses != 0){
                    let ultimos3Meses = item.ultimos3Meses.find(m3 => m3.codDer === derivation);
                    media3UltimosMeses2 = ultimos3Meses ? ultimos3Meses.media_qtd_ped : 0;
                } else {
                    media3UltimosMeses2 = 0;
                }

                result = parseInt(efmaStorage);

                if (result != 0) {
                    result = this.formatNumber(result) / this.formatNumber(media3UltimosMeses2);
                    return this.toFixedTrunc(parseFloat(result), 1); 
                } else {
                    return result;
                }

            }

        },

        calcularTotalMMediaCopy(items) {
            console.log(items);
            const totalFinal = items.resultadoMed.reduce((total, item) => {
                return parseFloat(total) + parseFloat(item.total);
            }, 0);

            return parseFloat(this.formatNumber(totalFinal));
        },

        calcularTotalMForGroup(items) {
            let forecastTotal = 0;
            let estFinalTotal = 0;
            let result = 0;

            for (const item of items.derivacao) {
                estFinalTotal += parseInt(localStorage.getItem("estFinalAtual" + item));
                
                if(items.forecastProximoMes != 0){
                    let forecast = items.forecastProximoMes.find(est => est.codDer === item);
                    forecastTotal += forecast ? forecast.qtd : 0;
                } else {
                    forecastTotal += 0;
                } 
            }

            result = parseInt(estFinalTotal) / parseInt(forecastTotal);

            if (isNaN(result)) {
                result = 0;
            } 

            if (this.toFixedTrunc(result, 1) == 'Infinity.0') {
                result = 0;
            }

            if (this.toFixedTrunc(result, 1) == '-Infinity.0') {
                result = 0;
            }

            return this.toFixedTrunc(result, 1);
          
        },

        printObject(obj, prefix = '') {
            for (const key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    const value = obj[key];
                    if (Array.isArray(value)) {
                        console.log(`${prefix}${key}: [`);
                        value.forEach((item, index) => {
                            console.log(`${prefix}  [${index}]`);
                            this.printObject(item, `${prefix}    `);
                        });
                        console.log(`${prefix}]`);
                    } else if (typeof value === 'object' && value !== null) {
                        console.log(`${prefix}${key}: {`);
                        this.printObject(value, `${prefix}  `);
                        console.log(`${prefix}}`);
                    } else {
                        console.log(`${prefix}${key}: ${value}`);
                    }
                }
            }
        },

        calcularTotalMMedia(items) {
            let qntDerivacao = items.length;
            let totalMediaVendas = 0;
            console.log(items);
            items.map(i => {
                if(i.mmedia != 0 && i.is_active == 1){
                    for(let index = 0; index < i.mmedia.length; index++) {
                        const element = i.mmedia[index];
                        var total = element.mes1 + element.mes2 + element.mes3;
                        var mediaVendas = (total/3)
                        totalMediaVendas += mediaVendas;   
                    }
                }
            })
            totalMediaVendas = totalMediaVendas/qntDerivacao
            var efmaTotal = this.calcularTotalEFMA(items) 
            efmaTotal = parseFloat(efmaTotal);
            return this.toFixedTrunc(parseFloat((efmaTotal/totalMediaVendas)), 1);       
        },

        calcularTotalFechado(items) {
            const totalArray = items.map(item => {
            if(item.pedFechado != 0){

            return item.pedFechado.reduce((entrada, ent) => {
                if (ent.codDer === item.derivation) {
                    return parseInt(entrada) + parseInt(ent.qtd_ped);
                }
                return entrada;
                }, 0);
            } else {
                return 0;
            }
            });

            return parseFloat(this.formatNumber(totalArray.reduce((total, value) => total + value, 0)));
        },

        calcularTotalRealizar(items) {
            let realizarTotal = 0;
            const totalArray = items.map(item => {
            if(item.realizar != 0){

            return item.realizar.reduce((entrada, ent) => {
                if (ent.codDer === item.derivation) {
                    if (ent.total == 0) {
                        realizarTotal = ent.forecast; 
                    } else {
                        realizarTotal = ent.total; 
                    }
                     
                    return parseFloat(entrada) + parseFloat(realizarTotal);
                }
                return entrada;
                }, 0);
            } else {
                return 0;
            }
            });
            let result = totalArray.reduce((total, value) => total + value, 0);
            if (result < 1000) {
                    return result;
                } else {
                    return parseFloat(this.formatNumber(result));
                }

        },

        toggleCheckbox() {
            this.months = [...Array(this.qtd_months)].map((a, b) => moment(this.date).add(b, 'M').locale('pt').format("MMM, YYYY"))
            this.teste = this.values.map(function (product) {
                return product
            })
            let currentDate = moment(this.date);

            this.months_history = currentDate.subtract(0+1, 'months').locale('pt').format("MMM, YYYY");
            currentDate = moment(this.date);

            this.months_history2 = currentDate.subtract(1+1, 'months').locale('pt').format("MMM, YYYY");
            currentDate = moment(this.date);

            this.months_history3 = currentDate.subtract(2+1, 'months').locale('pt').format("MMM, YYYY");

            this.monthsPrevious = [...Array(18)].map((_, index) => {
                let currentDate = moment(this.date);
                return currentDate.subtract(index + 1, 'months').locale('pt').format("MMM, YYYY");
            })

            this.monthsNext = [...Array(19)].map((_, index) => {
                let currentDate = moment(this.date);
                return currentDate.add(index + 1, 'months').locale('pt').format("MMM, YYYY");
            })

        },
        goSuccess(message) {
            this.$swal({
                toast: true,
                position: "center",
                showConfirmButton: false,
                timer: 4000,
                icon: "success",
                title: "Sucesso!",
                text: message ? message : "Cadastro Atualizado.",
            });
        },

        goError(message) {
            this.$swal({
                toast: true,
                position: "center",
                showConfirmButton: true,
                icon: "error",
                title: "Atenção!",
                text: message ? message : "Já existe um usuário com este e-mail.",
            });
        },

        async getProducts() {
            await this.$http
                .get(`/s&op/productsWithAgroup`)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        this.products = response.data.products;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        async getParameters() {
            await this.$http
                .get(`/s&op/parameters`)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        this.parameters = response.data.parametersName;
                        this.estoque = JSON.parse(response.data.estoque.toLowerCase());
                        this.prog = JSON.parse(response.data.programacao.toLowerCase());
                        this.embal = JSON.parse(response.data.embalagem.toLowerCase());
                        this.infos = JSON.parse(response.data.infos.toLowerCase());
                        this.parametersNameStock = response.data.parametersStock;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        async getItemsSeop(item) {
            let formData = new FormData();
            if (item) {
                formData.append("Código", item.code);
            }

            await this.$http
                .post(`/s&op`, formData)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        this.items = response.data.total;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        },

        async findProduct(item, index, select_product) {
            this.$swal.showLoading();
            this.select_product[index] = select_product;
            if (this.select_product[index]) {
                this.itensSelecionados.push(item.code);
            } else {
                const index = this.itensSelecionados.indexOf(item);
                this.itensSelecionados.splice(index, 1);

                if (index !== -1) {
                    this.itensSelecionados.splice(index, 1);
                }
            }

            let formData = new FormData();
            formData.append("code", this.itensSelecionados);
            formData.append("date", moment(this.date).format("M/YYYY"));
            // console.log(moment(this.date).format("MM/YYYY"))

            await this.$http
                .post(`/s&op/result`, formData)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                        this.$swal.hideLoading();
                    } else {
                        this.$swal.hideLoading();
                        this.$swal.close();
                        this.result_forecast = response.data.products_new;
                        this.toggleItem(index); 
                        // console.log("response.data.products_new")
                        // console.log(response.data.products_new)
                        this.itensSelecionados.splice(0, 1)  
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },


        allSelect() {
            this.select_product = [];
        },

        selectDate() {
            this.months = [...Array(this.qtd_months)].map((a, b) => moment(this.date).add(b, 'M').locale('pt').format("MMM, YYYY"))
            this.isOpen = this.products.map(() => false)
            let currentDate = moment(this.date);
            this.months_history = currentDate.subtract(0+1, 'months').locale('pt').format("MMM, YYYY");
            currentDate = moment(this.date);

            this.months_history2 = currentDate.subtract(1+1, 'months').locale('pt').format("MMM, YYYY");
            currentDate = moment(this.date);

            this.months_history3 = currentDate.subtract(2+1, 'months').locale('pt').format("MMM, YYYY");

            this.monthsPrevious = [...Array(18)].map((_, index) => {
                let currentDate = moment(this.date);
                return currentDate.subtract(index + 1, 'months').locale('pt').format("MMM, YYYY");
            })

            this.monthsNext = [...Array(19)].map((_, index) => {
                let currentDate = moment(this.date);
                return currentDate.add(index + 1, 'months').locale('pt').format("MMM, YYYY");
            })

            this.products_history = [];

        },

        closeProducts() {
            this.isOpen = this.products.map(() => false)
        },

        addMonths() {
            this.qtd_months = 12
            this.months = [...Array(this.qtd_months)].map((a, b) => moment(this.date).add(b, 'M').locale('pt').format("MMM, YYYY"))
            let currentDate = moment(this.date);
            this.months_history = currentDate.subtract(0+1, 'months').locale('pt').format("MMM, YYYY");
            currentDate = moment(this.date);

            this.months_history2 = currentDate.subtract(1+1, 'months').locale('pt').format("MMM, YYYY");
            currentDate = moment(this.date);

            this.months_history3 = currentDate.subtract(2+1, 'months').locale('pt').format("MMM, YYYY");
            this.monthsPrevious = [...Array(18)].map((_, index) => {
                let currentDate = moment(this.date);
                return currentDate.subtract(index + 1, 'months').locale('pt').format("MMM, YYYY");
            })

        this.monthsNext = [...Array(19)].map((_, index) => {
                let currentDate = moment(this.date);
                return currentDate.add(index + 1, 'months').locale('pt').format("MMM, YYYY");
            })

        },
        async selectEstoque() {
            if (this.estoque == true) {
                this.estoque = "false";
            }
            if (this.estoque == 'false') {
                this.estoque = "true";
            }
            let formData = new FormData();
            formData.append("disabled", this.estoque);
            formData.append("group", 'Estoque');
            await this.$http
                .post(`/s&op/update_group`, formData)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        this.parameters = response.data.parameters_update;
                        this.estoque = JSON.parse(response.data.estoque.toLowerCase());
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        async selectProgramacao() {
            // this.prog = !this.prog;
            // console.log(this.prog)
            if (this.prog == true) {
                this.prog = "false";
            }
            if (this.prog == 'false') {
                this.prog = "true";
            }
            let formData = new FormData();
            formData.append("disabled", this.prog);
            formData.append("group", 'Programacao');
            await this.$http
                .post(`/s&op/update_group`, formData)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        this.parameters = response.data.parameters_update;
                        this.prog = JSON.parse(response.data.programacao.toLowerCase());
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        },
        async selectEmbalagem() {
            if (this.embal == true) {
                this.embal = "false";
            }
            if (this.embal == 'false') {
                this.embal = "true";
            }

            let formData = new FormData();
            formData.append("disabled", this.embal);
            formData.append("group", 'Embalagem');
            await this.$http
                .post(`/s&op/update_group`, formData)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        this.parameters = response.data.parameters_update;
                        this.embal = JSON.parse(response.data.embalagem.toLowerCase());
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        async selectInfo() {
            if (this.infos == true) {
                this.infos = "false";
            }
            if (this.infos == 'false') {
                this.infos = "true";
            }
            let formData = new FormData();

            formData.append("disabled", this.infos);
            formData.append("group", 'Infos');
            await this.$http
                .post(`/s&op/update_group`, formData)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        this.parameters = response.data.parameters_update;
                        this.infos = JSON.parse(response.data.infos.toLowerCase());
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        async historyProduct(item, index, event){
            this.$swal.showLoading();

            if (event.target.checked) {
                    this.products_history.push(item);
                    this.codes.push(item.code);
                    
            } else {
                const index1 = this.products_history.indexOf(item);
                const indexCodes = this.codes.indexOf(item.code);
                if (index1 > -1) {
                    this.products_history.splice(index1, 1);
                    // this.codes.splice(indexCodes, 1);
                }
                if (indexCodes > -1) {
                    this.codes.splice(indexCodes, 1);
                }
            }

            let formData = new FormData();
            formData.append("code_product", this.codes);
            formData.append("date", this.date);

            await this.$http
                .post(`/s&op/products_history`, formData)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                        this.$swal.hideLoading();
                    } else {
                        // console.log("response.data.histories")
                        // console.log(response.data.histories)
                        for (let key in localStorage) {
                                if (key.startsWith("DERIVACAO_")) {
                                    localStorage.removeItem(key);
                                }  
                                if (key.startsWith("DERIVATION_")) {
                                    localStorage.removeItem(key);
                                }
                            }
                        this.products_history = response.data.history_mes1
                        sessionStorage.setItem('countEstFinalMes', 0);
                        localStorage.setItem("counter", 0);
                        localStorage.setItem("contadorTotalFinal", 0);

                        // this.parameters = response.data.parameters_update;
                        // this.infos = JSON.parse(response.data.infos.toLowerCase());
                        this.$swal.hideLoading();
                        this.$swal.close();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },

    computed: {
        updatedFields() {
            const modifiedArray = [];
            // Percorre o array original
            for (let i = 0; i < this.parameters.length; i++) {
                const element = this.parameters[i].name;
                const elementId = this.parameters[i].id;

                // Substitui espaços por underscores e adiciona ao novo array
                // const modifiedElement = element.replace(/\./g, '').replace(/ /g, '_');

                modifiedArray.push({ 'key': elementId.toString(), 'label': element });
            }

            return [this.additionalColumn, ...modifiedArray];
        },

        fieldsProduct: function () {
            let fields = [
                {
                    key: "A Realizar",
                    label: "A Realizar",
                },

                {
                    key: "Forecasting",
                    label: "Forecasting",
                },

                {
                    key: "Fat.líquido",
                    label: "Fat.líquido",
                },

                {
                    key: "Ped.aberto",
                    label: "Ped.aberto",
                },

                {
                    key: "Logística",
                    label: "Logística",
                },

                {
                    key: "Est.planejamento",
                    label: "Est.planejamento",
                },

                {
                    key: "Entrada",
                    label: "Entrada",
                },

                {
                    key: "Est.final",
                    label: "Est.final",
                },

                {
                    key: "M.méd",
                    label: "M.méd",
                },

                {
                    key: "M.for",
                    label: "M.for",
                },

                {
                    key: "EFMA",
                    label: "EFMA",
                },

                {
                    key: "Volume",
                    label: "Volume",
                },

                {
                    key: "Data",
                    label: "Data",
                },

                {
                    key: "M_H",
                    label: "M_H",
                },

                {
                    key: "Largura",
                    label: "Largura",
                },

                {
                    key: "Altura",
                    label: "Altura",
                },

                {
                    key: "Comprimento",
                    label: "Comprimento",
                },

                {
                    key: "Peso",
                    label: "Peso",
                },

                {
                    key: "Quantidade",
                    label: "Quantidade",
                },

                {
                    key: "Mais informações",
                    label: "Mais informações",
                },

            ];
            return fields;
        },
        fieldsProductHistory: function () {
            let fields = [
                {
                    key: "forecasting",
                    label: "Forecasting",
                },
                {
                    key: "fat_liquido",
                    label: "Fat.líquido",
                },
                {
                    key: "desvio",
                    label: "Desvio",
                },
                {
                    key: "desvio_per",
                    label: "Desvio %",
                },
            ]

            return fields;
        },

        fieldsProductHistory3Meses: function () {
            let fields = [
                {
                    key: "sum_devio",
                    label: "Soma Desvio % - 3 meses",
                },
                {
                    key: "m_desvio",
                    label: "M_Desvio %",
                },
                {
                    key: "m_linear",
                    label: "M. Linear",
                }
            ]

            return fields;
        },

        // paginatedCards() {
        //     const { currentPage, perPage } = this;
        //     const start = (currentPage - 1) * perPage;
        //     const end = currentPage * perPage;

        //     return this.dolars.slice(start, end);
        // },

        filteredList() {
            return this.products.filter((product) => {
                const nameMatch = product.name.toLowerCase().includes(this.search.toLowerCase());
                const codeMatch = product.code.toString().toLowerCase().includes(this.search.toLowerCase());
                // Se você deseja filtrar por ambos name e code, use && (E lógico)
                // Se você deseja filtrar por name OU code, use || (OU lógico)
                return nameMatch || codeMatch;
            });
        },
        totalPages() {
            return Math.ceil(this.products.length / this.itemsPerPage);
        },


        contentStyles() {
            return {
                width: 'calc(100% - 0)'
            };
        },

        totalRows() {
            return this.products.length;
        },
        paginatedCards() {
            const { currentPage, perPage } = this;
            const start = (currentPage - 1) * perPage;
            const end = currentPage * perPage;

            return this.products.slice(start, end);
        },

        numeroDePaginas() {
            return Math.ceil(this.products.length / this.perPage);
        },

        totalItensFiltrados() {
			return this.itemsFiltrados.length;
		},
		itemsFiltrados() {
		// Filtrar a lista de itens com base no texto inserido no campo de filtro
			return this.products.filter(item => item.code.toLowerCase().includes(this.search.toLowerCase()) || item.name.toLowerCase().includes(this.search.toLowerCase()));
		},
		itemsFiltradosPaginados() {
			const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
			const fim = inicio + this.itensPorPagina;
			return this.itemsFiltrados.slice(inicio, fim);
		},
    },

    filters: {
        dateFormat: function (date) {
            return moment(date).format("DD.MM.YYYY");
        },
        truncarTexto(value, length) {
            if (!value) return '';
            if (value.length <= length) return value;
            return value.substring(0, length) + '...';
        }
    },
};
</script>

<style lang="scss" src="./style.scss" scoped />
