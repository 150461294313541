<template>
	<div class="component-tabs-quantity">
		<b-row>
			<b-col md="1" class="col-label">
				<span> Mês </span>
				<br />
				<span> Crescimento % </span>
			</b-col>
			<b-col class="col-months responsive">
				<div class="responsive table-responsive">
					<table class="table-import table responsive">
						<!-- Table headers -->
						<thead>
							<tr>
								<th scope="col" v-for="month in months" :key="month">{{ month }}</th>
							</tr>
						</thead>

						<!-- Table rows -->
						<tbody>
							<tr>
								<td v-for="(factor, index) in orderedFactorsGrowth" :key="index">
										<b-form-input
										v-model="resultado_growth[index].valor"
										@blur="saveGrowth(resultado_growth[index], resultado_growth[index])"

										></b-form-input>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</b-col>
		</b-row>
		<!-- GRAFICO DE CRESCIMENTO -->
		<b-row class="chart">
			<b-col md="4">
				<p> <strong> Tipos de projeção </strong> </p>
			</b-col>

			<b-col md="8">
				<line-chart
					:chart-data="dataAtingimento"
					:options="optionsPie"
					:width="50"
					:height="15"
				/>
			</b-col>
		</b-row>
		<!-- {{ orderedFactorsGrowth }} -->
		<!-- LISTA DE PRODUTOS E DERIVAÇÕES -->
		<b-row>
			<b-col md="12" class="title">
				<p><strong> Produtos </strong></p>
				<!-- <span style="margin-left: 10px">
					<input type="checkbox" name="" id="" /> Exibir somente produtos em falta
				</span> -->
			</b-col>
		</b-row>
		{{ valuesGrowthSell }}

		<b-row  class="table_products">
			<b-col md="12">
				<div v-for="(product, index) in itemsFiltradosPaginados" :key="index">
					<!-- v-b-toggle="'product-scenario-' + product.id" -->


				<div class="responsive table-responsive">
					<label
						for=""
						class="label-product" @click="findProduct(product, index)" 
					>
						<!-- <input type="checkbox" name="" id="" /> -->
						{{ product.name }}
							<font-awesome-icon :icon="['fas', 'arrow-circle-down']" />
					</label>
					<div 
						:id="'product-scenario-' + product.id"
						class=" item-content" :class="{ 'open': isOpen[index] }"
					>
						<table class="table-products table responsive">
							<thead>
								<tr>
									<th class="cod-der">
									</th>
									<th class="der"></th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th v-if="view_months"></th>
									<th v-if="view_months"></th>
									<th v-if="view_months"></th>
									<th v-if="view_months"></th>
									<th v-if="view_months"></th>
									<th v-if="view_months"></th>
									
								</tr>
							</thead>

							<tbody>
								<tr v-for="(item, itemIndex) in product.items" :key="itemIndex">
									<td class="cod-der">
										{{ product.code }}
									</td>
									<td class="der">{{ item.derivation }}</td>

									<!-- <td v-for="(factor) in orderedFactors" :key="factor">
										<span class="span_values" v-if="factor != ''">{{ factor }}</span>
										<span class="span_values" v-else>0</span>
									</td> -->
									<td v-for="(month, monthIndex) in Object.keys(values_sell)" :key="monthIndex">
										<span class="span_values" v-if="values_sell[month][itemIndex] !== undefined">{{ values_sell[month][itemIndex].total }}</span>
										<span class="span_values" v-else>0</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				</div>
			</b-col>

			<b-col>
				<b-pagination v-model="paginaAtual" :total-rows="totalItensFiltrados" :per-page="itensPorPagina"
					style="float: right;"></b-pagination>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import lineChart from "./LinesChart";
import moment from "moment";

export default {
	name: "SellSimulationComponent",
	components: {
		lineChart,
	},
	data() {
		return {
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			openCollapse: null,
			currentYear: new Date().getFullYear(),
			view_months: true,
			view_months_btn: false,
			view_months_btn_clear: false,
			productsCode: [],
			optionsPie: [],
			dataAtingimento: [],
			obj: this.$route.params,
			dataObj: {
				name: "",
				dateRef: "",
				dateCreate: new Date().toLocaleDateString(),
				user_id: "",
				observation: "",
				sell:{
					value_jan: "",
					value_feb: "",
					value_mar: "",
					value_apr: "",
					value_may: "",
					value_jun: "",
					value_jul: "",
					value_aug: "",
					value_sep: "",
					value_oct: "",
					value_nov: "",
					value_dec: "",
					id: "",
					cod_product: "",
					derivation: "",
					year: ""
				},
				growth: {
					value_jan: "",
					value_feb: "",
					value_mar: "",
					value_apr: "",
					value_may: "",
					value_jun: "",
					value_jul: "",
					value_aug: "",
					value_sep: "",
					value_oct: "",
					value_nov: "",
					value_dec: "",
				}
			},
			sells: [],
			active: null,
			disabled: false,
			openIndex: null,
			paginaAtual: 1,
			itensPorPagina: 10,
			growth: '',
			values_sell: [],
            isOpen: [],
			months: '',
			months_growth: '',
			date_atual: moment(),
			resultado: {},
			resultado_growth: {},
			derivations: [],
            mediaTotaisPorDerivacao: {}

		};
	},
	mounted() {
		this.getProductsCode();
		this.getSells();
		this.generateMonthNames();

		this.months = [...Array(12)].map((a, b) =>
				moment(this.date_atual).add(b, 'M').locale('pt').format('MMM, YYYY')
			);

		this.months_growth = [...Array(12)].map((a, b) =>
				moment(this.date_atual).add(b, 'M').format('MMM_YYYY').toLowerCase()
			);
	},
	methods: {
		generateMonthNames() {
			moment.locale('pt-br');

			const currentDate = moment().startOf('month'); // Iniciar no primeiro dia do mês atual
			const numberOfMonths = 12; // Pode ajustar conforme necessário

			for (let i = 0; i < numberOfMonths; i++) {
				const monthYear = currentDate.clone().add(i, 'month').format('MMM_YYYY').toLowerCase();
				const month = currentDate.clone().add(i, 'month').format('MMM').toLowerCase();
				const year = currentDate.clone().add(i, 'month').format('YYYY').toLowerCase();
				this.$set(this.values_sell, monthYear, 0);
				this.$set(this.resultado, monthYear, 0);
				this.$set(this.resultado_growth, monthYear, {valor:0, mes: month, ano: year});
			}
		},

		async findProduct(product, index){
			const isOpen = this.isOpen[index];

			if (isOpen) {
				this.isOpen = this.isOpen.map(() => false);
			} else {
				this.isOpen = this.isOpen.map(() => false);
				this.$set(this.isOpen, index, true);
			}

			let formData = new FormData();
			formData.append("product_code", product.code);
			formData.append("year", this.currentYear);

			this.$http
				.post(`/scenarios/sell/find_product`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.values_sell = response.data.findProduct
						this.derivations = response.data.derivacoes

						for (const month in this.values_sell) {
							if (Object.prototype.hasOwnProperty.call(this.values_sell, month)) {
								const arrayForMonth = this.values_sell[month];
								console.log(`Array para ${month}:`, arrayForMonth);
							}
						}

					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async saveGrowth(value, fieldName) {
			let formData = new FormData();

			formData.append("values_months", fieldName);
			formData.append("growth",fieldName.valor);
			formData.append("date", fieldName.mes);
			formData.append("simulation_id", this.$route.params.id_simulation);
			formData.append("year", fieldName.ano);

			// this.getChartAtingimento();

			this.$http
				.post(`/scenarios/store/sell/growth`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.getPurchaseGrowth();
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		async getPurchaseGrowth() {
			await this.$http
				// .get(`/products`)
				.get(`/scenarios/${this.$route.params.id_simulation}/sell/growth`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.growth = response.data.sell_growth

						this.getChartAtingimento();

					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async getProductsCode() {
			await this.$http
				// .get(`/products`)
                .get(`/s&op/products`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.productsCode = response.data.products;
						this.getPurchaseGrowth();
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		getChartAtingimento() {
			this.dataAtingimento = {
				labels: this.months.map((item) => item),

				datasets: [
					{
						label: "",
						data: this.values_chart.map((item)=>item),
						backgroundColor: "#f87979",
						borderColor: "#f87979",
						fill: false,
						pointStyle: "circle",
					},
				],
			};

			this.optionsPie = {
				responsive: true,
				legend: {
					display: false,
				},
			};
		},

		goSuccess(message) {
            this.$swal({
                toast: true,
                position: "center",
                showConfirmButton: false,
                timer: 4000,
                icon: "success",
                title: "Sucesso!",
                text: message ? message : "Cadastro Atualizado.",
            });
        },

        goError(message) {
            this.$swal({
                toast: true,
                position: "center",
                showConfirmButton: true,
                icon: "error",
                title: "Atenção!",
                text: message ? message : "Já existe um usuário com este e-mail.",
            });
        },

		async getSells() {
			await this.$http
				.get(`/scenarios/simulation/${this.obj.id}/sell`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						let sell = response.data.sell_simulation;
						this.sells = sell
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
	},

	computed: {
		getFormattedData() {
			const formattedData = {};

			this.growth.forEach(item => {
				const fieldName = `${item.month_year}`;
				formattedData[fieldName] = {valor: item.growth, mes: item.date, ano: item.year}; // Valor inicial de 0
			})

			return formattedData;
		},

		valuesGrowthSell(){
			return this.growth.forEach(item => {
				const fieldName = `${item.month_year}`;

				// Verifica se o item está presente em this.resultado_growth
				const resultadoItem = this.resultado_growth[fieldName];

				if (resultadoItem) {
					// Se estiver presente, substitui os valores pelos valores de this.growth
					resultadoItem.valor = item.growth;
					resultadoItem.mes = item.date;
					resultadoItem.ano = item.year;
				} else {
					// Se não estiver presente, adiciona o item de this.growth a this.resultado_growth
					this.$set(this.resultado_growth, fieldName, { valor: item.growth, mes: item.date, ano: item.year });
				}
			});
		},
		totalItensFiltrados() {
			return this.productsCode.length;
		},

		itemsFiltradosPaginados() {
			const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
			const fim = inicio + this.itensPorPagina;
			return this.productsCode.slice(inicio, fim);
		},
		orderedResultado() {
			const sortedKeys = Object.keys(this.resultado).sort((a, b) => {
				const dateA = moment(a, 'MMM_YYYY');
				const dateB = moment(b, 'MMM_YYYY');
				return dateA - dateB;
			});

			// Criar um novo objeto ordenado
			const ordered = {};
			sortedKeys.forEach(key => {
				ordered[key] = this.resultado[key];
			});

			return ordered;
		},

		orderedFactors() {
			let factors = 0;
			if(this.values_sell == ''){
				factors = this.resultado
			} else {
				factors = this.values_sell
			}
			const sortedKeys = Object.keys(factors).sort((a, b) => {
				const dateA = moment(a, 'MMM_YYYY');
				const dateB = moment(b, 'MMM_YYYY');
				return dateA - dateB;
			});

			// Criar um novo objeto ordenado
			const ordered = {};
			sortedKeys.forEach(key => {
				ordered[key] = factors[key];
			});

			return ordered;
		},

		orderedFactorsGrowth() {
			const sortedKeys = Object.keys(this.resultado_growth).sort((a, b) => {
				const dateA = moment(a, 'MMM_YYYY');
				const dateB = moment(b, 'MMM_YYYY');
				return dateA - dateB;
			});

			// Criar um novo objeto ordenado
			const ordered = {};
			sortedKeys.forEach(key => {
				ordered[key] = this.resultado_growth[key].valor;
			});

			return ordered;
		},

		values_chart() {
			const sortedKeys = Object.keys(this.getFormattedData).sort((a, b) => {
				const dateA = moment(a, 'MMM_YYYY');
				const dateB = moment(b, 'MMM_YYYY');
				return dateA - dateB;
			});

			// Criar um novo array ordenado apenas com os valores
			const orderedValues = sortedKeys.map(key => this.getFormattedData[key].valor);

			return orderedValues;
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
