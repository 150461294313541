<template>
	<div class="main">
		<PageComponent :title_nav="'Custos'">
			<div slot="slot-pages" class="content-pages">
				<div class="products">
					<div class="products-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'dashboard' }">
								<font-awesome-icon :icon="['fas', 'arrow-left']" />
								Custos
							</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->
						<!-- codigo teste 100905547
						Custo inicial = {{ costInitial }} -->
						<!-- <span v-for="n in 12"> - </span> -->

						<b-row>
							<b-col md="4" class="col-search">
								<b-card>
									<div class="filter_date">
										<div class="left-side">
											<span>Produtos</span>
										</div>

										<div class="right-side">
											<b-button class="btn-save button-padrao-add" @click="resultCost()"> Calcular
											</b-button>
										</div>
									</div>

									<div class="form">
										<b-form-input placeholder="Procurar" v-model="search"> </b-form-input>
										<font-awesome-icon :icon="['fas', 'search']" class="icon1" />
									</div>

									<p class="mt-4">
										<input type="checkbox" @change="allSelect()" name="" id="" v-model="all_select" />
										Todos
									</p>

									<div class="list_prods">
										<p v-for="item in filteredList" :key="item.code">
											<label for="" class="products-list">
												<input type="checkbox" name=""  :value="item.code" v-model="select_product"/>
												<label :for="item.code" v-b-toggle="'collapse-' + item.code" header-tag="header" role="tab" block style="margin-left: 10px; cursor: pointer;">
													{{ item.code }} <br />
													<small class="code">{{ item.name }}</small></label>
											</label>

										<b-collapse :id="'collapse-' + item.code" role="tabpanel"
											class="collapse-derivations">
											<b-card-body>
												<b-card-text v-for="der in item.derivations" :key="der">
													<label :for="der.derivation" class="derivations-list">
														<!-- <input type="checkbox" name="" :id="der.derivation" :value="der.derivation" v-model="select_product_derivation" /> -->
														{{ der.derivation }} 
														<br />
													</label>
												</b-card-text>
											</b-card-body>
										</b-collapse>
									</p>
									</div>
								</b-card>
							</b-col>
							<b-col md="8" class="">
								<div class="table-responsive table-products">
									<table class="table">
										<thead>
											<tr>
												<th scope="col" class="description" style="text-align: left;">Descrição</th>
												<th scope="col" v-for="month in months" :key="month">{{ month }}</th>
												<!-- <th scope="col" class="all_month" v-if="this.qtd_months == 6"> <b-button
														@click="addMonths()">+6 meses</b-button> </th> -->
											</tr>
										</thead>
										<tbody>
											<!-- <tr v-for="item in values" :key="item.id">
												<td scope="row" style="text-align: left"> <input type="checkbox" name=""
														id=""> {{item.description}} - {{ item.id }}</td>
											</tr> -->
											<template>
												<tr>
													<template v-if="costInitial.length == 0">
														<td scope="row" style="text-align: left">   <span>Custo inicial</span> </td>
														<td colspan="yourColspan"  v-for="n in 12" :key="n"> - </td>
													</template>
													<template v-else>
														<td scope="row" style="text-align: left">   <span>Custo inicial</span> </td>
														<td v-for="item in costInitial" :key="item">{{ item }}</td>
													</template>
												</tr>
											</template>

											<template>
												<tr>
													<template v-if="costEntry.length == 0">
														<td scope="row" style="text-align: left">   <span>Custo entrada</span> </td>
														<td colspan="yourColspan"  v-for="n in 12" :key="n"> - </td>
													</template>
													<template v-else>
														<td scope="row" style="text-align: left">   <span>Custo entrada</span> </td>
														<td v-for="item in costEntry" :key="item">{{ item }}</td>
													</template>
												</tr>
											</template>

											<template>
												<tr>
													<template v-if="cost_exit.length == 0">
														<td scope="row" style="text-align: left">   <span>Custo saída</span> </td>
														<td colspan="yourColspan"  v-for="n in 12" :key="n"> - </td>
													</template>
													<template v-else>
														<td scope="row" style="text-align: left">   <span>Custo saída</span> </td>
														<td v-for="item in cost_exit" :key="item">{{ item }}</td>
													</template>
												</tr>
											</template>

											<template>
												<tr>
													<template v-if="cost_prev.length == 0">
														<td scope="row" style="text-align: left">   <span>Custo previsto</span> </td>
														<td colspan="yourColspan"  v-for="n in 12" :key="n"> - </td>
													</template>
													<template v-else>
														<td scope="row" style="text-align: left">   <span>Custo previsto</span> </td>
														<td v-for="item in cost_prev" :key="item">{{ item }}</td>
													</template>
												</tr>
											</template>

											<template>
												<tr>
													<template v-if="costStockTransit.length == 0">
														<td scope="row" style="text-align: left">   <span> Custo estoque de trânsito</span> </td>
														<td colspan="yourColspan"  v-for="n in 12" :key="n"> - </td>
													</template>
													<template v-else>
														<td scope="row" style="text-align: left">   <span> Custo estoque de trânsito</span> </td>
														<td v-for="item in costStockTransit" :key="item">{{ item }}</td>
													</template>
												</tr>
											</template>

											<template>
												<tr>
													<template v-if="quantity_initial.length == 0">
														<td scope="row" style="text-align: left">   <span> Quantidade inicial</span> </td>
														<td colspan="yourColspan"  v-for="n in 12" :key="n"> - </td>
													</template>
													<template v-else>
														<td scope="row" style="text-align: left">   <span> Quantidade inicial</span> </td>
														<td v-for="item in quantity_initial" :key="item">{{ item }}</td>
													</template>
												</tr>
											</template>

											<template>
												<tr>
													<template v-if="quantity_entry.length == 0">
														<td scope="row" style="text-align: left">   <span> Quantidade entrada</span> </td>
														<td colspan="yourColspan"  v-for="n in 12" :key="n"> - </td>
													</template>
													<template v-else>
														<td scope="row" style="text-align: left">   <span> Quantidade entrada</span> </td>
														<td v-for="item in quantity_entry" :key="item">{{ item }}</td>
													</template>
												</tr>
											</template>

											<template>
												<tr>
													<template v-if="qtd_exit.length == 0">
														<td scope="row" style="text-align: left">   <span> Quantidade saída</span> </td>
														<td colspan="yourColspan"  v-for="n in 12" :key="n"> - </td>
													</template>
													<template v-else>
														<td scope="row" style="text-align: left">   <span> Quantidade saída</span> </td>
														<td v-for="item in qtd_exit" :key="item">{{ formatNumber(item) }}</td>
														
													</template>
												</tr>
											</template>

											<template>
												<tr>
													<template v-if="qtd_final_prev.length == 0">
														<td scope="row" style="text-align: left">   <span> Quantidade final previsto </span> </td>
														<td colspan="yourColspan"  v-for="n in 12" :key="n"> - </td>
													</template>
													<template v-else>
														<td scope="row" style="text-align: left">   <span> Quantidade final previsto </span> </td>
														<td v-for="item in qtd_final_prev" :key="item">{{ item }}</td>
													</template>
												</tr>
											</template>

											<template>
												<tr>
													<template v-if="dolar_tax.length == 0">
														<td scope="row" style="text-align: left">   <span> Dólar</span> </td>
														<td colspan="yourColspan"  v-for="n in 12" :key="n"> - </td>
													</template>
													<template v-else>
														<td scope="row" style="text-align: left">   <span> Dólar</span> </td>
														<td v-for="item in dolar_tax" :key="item">{{ item }}</td>
													</template>
												</tr>
											</template>

											<template>
												<tr>
													<template v-if="fob.length == 0">
														<td scope="row" style="text-align: left">   <span> FOB atual</span> </td>
														<td colspan="yourColspan"  v-for="n in 12" :key="n"> - </td>
													</template>
													<template v-else>
														<td scope="row" style="text-align: left">   <span> FOB atual</span> </td>
														<td v-for="item in fob" :key="item">{{ item }}</td>
													</template>
												</tr>
											</template>

											<!-- <template>
												<tr>
													<template v-if="fob_repo.length == 0">
														<td scope="row" style="text-align: left">   <span> FOB reposição</span> </td>
														<td colspan="yourColspan"  v-for="n in 12" :key="n"> - </td>
													</template>
													<template v-else>
														<td scope="row" style="text-align: left">   <span> FOB reposição</span> </td>
														<td v-for="item in costEntry" :key="item">{{ item }}</td>
													</template>
												</tr>
											</template> -->

											<template>
												<tr>
													<template v-if="factor_medio.length == 0">
														<td scope="row" style="text-align: left">   <span> Fator médio</span> </td>
														<td colspan="yourColspan"  v-for="n in 12" :key="n"> - </td>
													</template>
													<template v-else>
														<td scope="row" style="text-align: left">   <span> Fator médio</span> </td>
														<td v-for="item in factor_medio" :key="item">{{ item }}</td>
													</template>
												</tr>
											</template>

											<template>
												<tr>
													<template v-if="cost_medio.length == 0">
														<td scope="row" style="text-align: left">   <span> Custo médio</span> </td>
														<td colspan="yourColspan"  v-for="n in 12" :key="n"> - </td>
													</template>
													<template v-else>
														<td scope="row" style="text-align: left">   <span> Custo médio</span> </td>
														<td v-for="item in cost_medio" :key="item">{{ item }}</td>
													</template>
												</tr>
											</template>

											<template>
												<tr>
													<template v-if="cost_variation.length == 0">
														<td scope="row" style="text-align: left">   <span> Variação custo</span> </td>
														<td colspan="yourColspan"  v-for="n in 12" :key="n"> - </td>
													</template>
													<template v-else>
														<td scope="row" style="text-align: left">   <span> Variação custo</span> </td>
														<td v-for="item in cost_variation" :key="item">{{ item }} %</td>
													</template>
												</tr>
											</template>

											<template>
												<tr>
													<template v-if="average_gross_price.length == 0">
														<td scope="row" style="text-align: left">   <span> Preço médio bruto</span> </td>
														<td colspan="yourColspan"  v-for="n in 12" :key="n"> - </td>
													</template>
													<template v-else>
														<td scope="row" style="text-align: left">   <span> Preço médio bruto</span> </td>
														<td v-for="item in average_gross_price" :key="item">{{ item }}</td>
													</template>
												</tr>
											</template>

											<template>
												<tr>
													<template v-if="expected_margin.length == 0">
														<td scope="row" style="text-align: left">   <span> Margem prevista</span> </td>
														<td colspan="yourColspan"  v-for="n in 12" :key="n"> - </td>
													</template>
													<template v-else>
														<td scope="row" style="text-align: left">   <span> Margem prevista</span> </td>
														<td v-for="item in expected_margin" :key="item">{{ item }}</td>
													</template>
												</tr>
											</template>


											<!-- <tr v-for="item in values" :key="item.id"> -->
											<!-- <tr>
												<td scope="row" style="text-align: left"> <input type="checkbox" name=""
														id=""> <span>Custo inicial</span> </td>

												<td scope="col" v-for="item in costInitial" :key="item"> {{ item }} </td>
											</tr>
											<tr>
												<td scope="row" style="text-align: left"> <input type="checkbox" name=""
														id=""> <span>Custo entrada</span> </td>
												<td scope="col" v-for="item in costEntry" :key="item"> {{ item }} </td>
											</tr>
											<tr>
												<td scope="row" style="text-align: left"> <input type="checkbox" name=""
														id=""> <span>Custo saída</span> </td>
											</tr>
											<tr>
												<td scope="row" style="text-align: left"> <input type="checkbox" name=""
														id=""> <span>Custo previsto</span> </td>
											</tr>
											<tr>
												<td scope="row" style="text-align: left"> <input type="checkbox" name=""
														id=""> <span>Quantidade inicial</span> </td>
												<td scope="col" v-for="item in quantity_initial" :key="item"> {{ item }} </td>
											</tr>
											<tr>
												<td scope="row" style="text-align: left"> <input type="checkbox" name=""
														id=""> <span>Quantidade entrada</span> </td>
											</tr>
											<tr>
												<td scope="row" style="text-align: left"> <input type="checkbox" name=""
														id=""> <span>Quantidade saída</span> </td>
											</tr>
											<tr>
												<td scope="row" style="text-align: left"> <input type="checkbox" name=""
														id=""> <span>Custo estoque de trânsito</span> </td>
												<td scope="col" v-for="item in costStockTransit" :key="item"> {{ item }} </td>
											</tr>
											<tr>
												<td scope="row" style="text-align: left"> <input type="checkbox" name=""
														id=""> <span>Quantidade final previsto</span> </td>
											</tr>
											<tr>
												<td scope="row" style="text-align: left"> <input type="checkbox" name=""
														id=""> <span>Dólar</span> </td>
												<td scope="col" v-for="item in dolar_tax" :key="item"> {{ item }} </td>
											</tr>
											<tr>
												<td scope="row" style="text-align: left"> <input type="checkbox" name=""
														id=""> <span>FOB atual</span> </td>
											</tr>
											<tr>
												<td scope="row" style="text-align: left"> <input type="checkbox" name=""
														id=""> <span>FOB reposição</span> </td>
											</tr>
											<tr>
												<td scope="row" style="text-align: left"> <input type="checkbox" name=""
														id=""> <span>Fator médio</span> </td>
											</tr>
											<tr>
												<td scope="row" style="text-align: left"> <input type="checkbox" name=""
														id=""> <span>Custo médio</span> </td>
											</tr>
											<tr>
												<td scope="row" style="text-align: left"> <input type="checkbox" name=""
														id=""> <span>Variação custo</span> </td>
											</tr>
											<tr>
												<td scope="row" style="text-align: left"> <input type="checkbox" name=""
														id=""> <span>Preço médio bruto</span> </td>
											</tr>
											<tr>

												<td scope="row" style="text-align: left"> <input type="checkbox" name=""
														id=""> <span>Margem prevista</span> </td>
											</tr> -->
											<!-- <td scope="col"> {{ costInitial.total_cost ? costInitial.total_cost : '-' }}
											</td>
											<td scope="col">- </td>
											<td scope="col">- </td>
											<td scope="col">- </td>
											<td scope="col">- </td>
											<td scope="col">- </td>
											<td scope="col" v-if="qtd_months == 6"></td>
											<td scope="col" v-if="qtd_months == 12">- </td>
											<td scope="col" v-if="qtd_months == 12">- </td>
											<td scope="col" v-if="qtd_months == 12">- </td>
											<td scope="col" v-if="qtd_months == 12">- </td>
											<td scope="col" v-if="qtd_months == 12">- </td>
											<td scope="col" v-if="qtd_months == 12">- </td> -->
										</tbody>
									</table>

								</div>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</PageComponent>
	</div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";
import moment from "moment";
import json from "../index/jsondata"

export default {
	name: "CostIndexComponent",
	components: {
		PageComponent,
	},
	data() {
		return {
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			perPage: 6,
			currentPage: 1,
			search: "",
			products: [],
			date: "2023-01",
			all_select: false,
			select_product: [],
			select_product_derivation: [],

			teste: '',
			months: '',
			// qtd_months: 6,
			qtd_months: 12,
			values: json.products,
			date_atual: moment(),
			costInitial: [], //custo inicial
			costEntry: [], //custo entrada
			cost_exit: [], // custo saida
			cost_prev: [], // custo previsto
			quantity_initial: [], // quantidade inicial
			quantity_entry: [], // quantidade entrada
			qtd_exit: [], // quantidade saida
			costStockTransit: [], // custo est. transito
			qtd_final_prev: [], //quant. final prevista
			dolar_tax: [], //dolar
			fob: [], // fob atual
			fob_repo: [], //fob reposisão
			factor_medio: [], // fator medio
			cost_medio: [], // custo medio
			cost_variation: [], //custo variação
			average_gross_price: [], // preço med. bruto
			expected_margin: [], //margem prevista

		};
	},

	mounted() {
		this.getProducts();
		this.toggleCheckbox();
	},

	methods: {
		formatNumber(value) {
			let number = parseFloat(value).toFixed(2);
    
			let parts = number.split('.');
			
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
			
			return parts.join(',');
        },
		stringToNumber(str) {
		return parseFloat(str.replace(/\./g, '').replace(',', '.'));
		},
		numberToString(num) {
		return num.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
		},
		toggleCheckbox() {
			// this.months = [...Array(this.qtd_months)].map((a, b) => moment(this.date).add(b, 'M').locale('pt').format("MMM, YYYY"))
			this.teste = this.values.map(function (product) {
				return product
			})

			this.months = [...Array(this.qtd_months)].map((a, b) =>
				moment(this.date_atual).add(b, 'M').locale('pt').format('MMM, YYYY')
			);
		},

		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		async getProducts() {
			await this.$http
				.get(`/products`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.products = response.data.productsCode;
						//console.log(this.products)
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		// SELECIONAR TODOS
		allSelect() {
			// Se pelo menos um item não estiver selecionado, seleciona todos. Caso contrário, deseleciona todos.
			const atLeastOneNotSelected = this.products.some(item => !item.isSelected);

			this.products.forEach(item => {
				item.isSelected = atLeastOneNotSelected;
				// Se precisar manter um array separado de itens selecionados
				if (item.isSelected && !this.select_product.includes(item.code)) {
					this.select_product.push(item.code);
				} else if (!item.isSelected && this.select_product.includes(item.code)) {
					const index = this.select_product.indexOf(item.code);
					this.select_product.splice(index, 1);
				}
			});
		},

		selectDate() {
			this.months = [...Array(this.qtd_months)].map((a, b) => moment(this.date).add(b, 'M').locale('pt').format("MMM, YYYY"))
		},

		addMonths() {
			this.qtd_months = 12
			// this.months = [...Array(this.qtd_months)].map((a, b) => moment(this.date).add(b, 'M').locale('pt').format("MMM, YYYY"))
			this.months = [...Array(this.qtd_months)].map((a, b) =>
				moment(this.date_atual).add(b, 'M').locale('pt').format('MMM, YYYY')
			);
		},

		//RESULTADO DOS CALCULOS
		async resultCost() {
			const jsonString = JSON.stringify(this.select_product);
			if(this.select_product == ''){
				this.goError("Selecione um produto!");
			} else {
				let formData = new FormData();
				formData.append("products_code", jsonString);
				await this.$http
					.post(`/cost/initial`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							this.costInitial = response.data.costStock;
							// this.resultCostQuantityInitial(jsonString)
							this.resultCostEntry(jsonString);
						}
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		},

		//RESULTADO DOS CALCULOS -  Quantidade inicial
		async resultCostQuantityInitial(value) {
			let formData = new FormData();
			formData.append("products_code", value);
			await this.$http
				.post(`/cost/quantity/initial`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.quantity_initial = response.data.quantity_initial;
						this.resultCostQuantityEntry(value);
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		//RESULTADO DOS CALCULOS -  Quantidade inicial
		async resultCostQuantityEntry(value) {
			let formData = new FormData();
			formData.append("products_code", value);
			await this.$http
				.post(`/cost/quantity/entry`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.quantity_entry = response.data.quantity_entry;
						// this.resultCostDolar(value);
						this.resultQtdeExitCost(value); // quantidade saida
						
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		//RESULTADO DOS CALCULOS -  dolar
		async resultCostDolar(value) {
			let formData = new FormData();
			formData.append("products_code", value);
			await this.$http
				.post(`/cost/dolar`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.dolar_tax = response.data.dolar_tax;
						// this.resultCostEntry(value);
						this.resultFobCost(value); // fob atual
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		//RESULTADO DOS CALCULOS -  dolar
		async resultCostEntry(value) {
			let formData = new FormData();
			formData.append("products_code", value);
			await this.$http
				.post(`/cost/entry`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.costEntry = response.data.res_entry;
						// this.resultStockCost(value);
						this.resultCostExit(value); // por enquanto
						
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		//RESULTADO DOS CALCULOS -  custo saida
		async resultCostExit(value) {
			let formData = new FormData();
			formData.append("products_code", value);
			await this.$http
				.post(`/cost/exit`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.cost_exit = response.data.cost_saida;
						// this.resultStockCost(value);
						this.resultCostPrev(value); // por enquanto
						
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		//RESULTADO DOS CALCULOS -  custo previsto
		async resultCostPrev(value) {
			let formData = new FormData();
			formData.append("products_code", value);
			await this.$http
				.post(`/cost/prev`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.cost_prev = response.data.cost_prev;
						// this.resultStockCost(value);
						this.resultStockCost(value); // por enquanto

					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		//RESULTADO DOS CALCULOS -  Custo Estoque em Trânsito
		async resultStockCost(value) {
			let formData = new FormData();
			formData.append("products_code", value);
			await this.$http
				.post(`/cost/stock`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.costStockTransit = response.data.costStockTransit;
						// this.resultPriceMedioCost(value);
						this.resultCostQuantityInitial(value);
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		//RESULTADOS DOS CALCULOS - preço medio bruto
		async resultPriceMedioCost(value){
			let formData = new FormData();
			formData.append("products_code", value);
			await this.$http
				.post(`/cost/price`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.average_gross_price = response.data.forecast_pm;
						this.resultMarginCost(value); //Margem prevista	
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		//RESULTADOS DOS CALCULOS - margem prevista
		async resultMarginCost(value){
			let formData = new FormData();
			formData.append("products_code", value);
			await this.$http
				.post(`/cost/margin`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.expected_margin = response.data.forecast_margin;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		//RESULTADOS DOS CALCULOS - fator medio
		async resultFactorCost(value){
			let formData = new FormData();
			formData.append("products_code", value);
			await this.$http
				.post(`/cost/factor_medio`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.factor_medio = response.data.factor;
						// this.resultFobCost(value); 
						this.resultMedioCost(value); //Custo médio	
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		//RESULTADOS DOS CALCULOS - fob atual
		async resultFobCost(value){
			let formData = new FormData();
			formData.append("products_code", value);
			await this.$http
				.post(`/cost/fob`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.fob = response.data.fob;
						// this.resultMedioCost(value);
						this.resultFactorCost(value); //factor medio
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		//RESULTADOS DOS CALCULOS - custo medio
		async resultMedioCost(value){
			let formData = new FormData();
			formData.append("products_code", value);
			await this.$http
				.post(`/cost/medio`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.cost_medio = response.data.medioCost;
						// this.resultQtdeExitCost(value);
						this.resultVariationCost(value); //variação 
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		//RESULTADOS DOS CALCULOS - quantidade saida
		async resultQtdeExitCost(value){
			let formData = new FormData();
			formData.append("products_code", value);
			await this.$http
				.post(`/cost/quantity/exit`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.qtd_exit = response.data.forecast_sell;
						// this.resultVariationCost(value);
						this.resultQtdeFinalCost(value); //dolar
						
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		//RESULTADOS DOS CALCULOS - quantidade final previsto
		async resultQtdeFinalCost(value){
			let formData = new FormData();
			formData.append("products_code", value);
			await this.$http
				.post(`/cost/quantity/final`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.qtd_final_prev = response.data.qtd_final;
						// this.resultVariationCost(value);
						this.resultCostDolar(value); //dolar
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		//RESULTADOS DOS CALCULOS - variação
		async resultVariationCost(value){
			let formData = new FormData();
			formData.append("products_code", value);
			await this.$http
				.post(`/cost/variation`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.cost_variation = response.data.variation;
						this.resultPriceMedioCost(value); //Preço médio bruto	
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

	},
	
	computed: {
		fieldsProduct: function () {
			let fields = [
				{
					key: "description",
					label: "Descrição",
				},

				{
					key: "date_final",
					label: "Data final",
				},

				{
					key: "tax",
					label: "Taxa",
				},

				{
					key: "user",
					label: "Usuário",
				},

				{
					key: "date_register",
					label: "Data de cadastro",
				},

				{
					key: "automatically_changed",
					label: "Alterado automático",
				},

				{
					key: "actions",
					label: "",
				},
			];
			return fields;
		},

		paginatedCards() {
			const { currentPage, perPage } = this;
			const start = (currentPage - 1) * perPage;
			const end = currentPage * perPage;

			return this.dolars.slice(start, end);
		},

		filteredList() {
			return this.products.filter((product) => {
				const nameMatch = product.name.toLowerCase().includes(this.search.toLowerCase());
				const codeMatch = product.code.toString().toLowerCase().includes(this.search.toLowerCase());
				// Se você deseja filtrar por ambos name e code, use && (E lógico)
				// Se você deseja filtrar por name OU code, use || (OU lógico)
				return nameMatch || codeMatch;
			});
		},
	},

	filters: {
		dateFormat: function (date) {
			return moment(date).format("DD.MM.YYYY");
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
