<template>
    <div class="main">
        <PageComponent :title_nav="'S&OP - Simulação('+ name_simulation +') - Cenário(' + name_scenario.name +')'">
            <div slot="slot-pages" class="content-pages" style="width: 100% !important;">
                <div class="products">
                    <div class="products-header">
                        <!-- breadcrumb -->
                        <b-breadcrumb>
                            <b-breadcrumb-item :to="{ name: 'scenarios-simulations-index' }">
                                <font-awesome-icon :icon="['fas', 'arrow-left']" />
                                Cenários e Simulações
                            </b-breadcrumb-item>
                        </b-breadcrumb>

                        <!-- breadcrumb -->
                        <b-row>
                            <b-col md="4" class="col-search">
                                <b-card>
                                    <div class="filter_date row">
                                        <div class="col col-md-6 datepicker1">
                                            <b-form-input id="type-date" class="datepicker" type="month" v-model="date"
                                                placeholder="" @change="selectDate()"></b-form-input>
                                        </div>
                                        <div class="col col form">
                                            <b-form-input placeholder="Procurar" v-model="search"> </b-form-input>
                                            <font-awesome-icon :icon="['fas', 'search']" class="icon1" />
                                        </div>
                                    </div>
                                    <!-- <p class="mt-3">
                                        <input type="checkbox" @change="allSelect()" name="" id="" v-model="all_select" />
                                        Todos
                                    </p> -->
                                </b-card>
                            </b-col>
                            <!-- {{ result_forecast[0] }} -->
                            <b-col md="8" class="" :style="contentStyles">

                                <div class="checkbox-container">
                                    <input type="checkbox" id="Estoque" @change="selectEstoque()" v-model="estoque">
                                    <label for="Estoque" class="estoque">Estoque</label>

                                    <input type="checkbox" id="prog" @change="selectProgramacao()" v-model="prog">
                                    <label for="prog" class="prog">Programação</label>

                                    <input type="checkbox" id="Embalagem" @change="selectEmbalagem()" v-model="embal">
                                    <label for="Embalagem" class="embal">Embalagem</label>

                                    <input type="checkbox" id="info" @change="selectInfo()" v-model="infos">
                                    <label for="info" class="info">Mais
                                        informações</label>
                                </div>
                            </b-col>

                            <b-col md="12">
                                <div class="table-responsive table-products">
                                    <b-table :fields="updatedFields" :items="filteredList" class="table" :per-page="perPage"
                                        :current-page="currentPage">
                                        <template #head(produtos)="">
                                            <p class="newColumn"></p>
                                        </template>

                                        <!-- forecast -->
                                        <template #head(1)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- Fat.líquido -->
                                        <template #head(2)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- A_realizar -->
                                        <template #head(3)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- Ped_não_fechado -->
                                        <template #head(7)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- Pedaberto -->
                                        <template #head(8)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- Logística -->
                                        <template #head(9)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- Estplanejamento -->
                                        <template #head(12)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- Entrada -->
                                        <template #head(16)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- Estfinal -->
                                        <template #head(17)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- EFMA -->
                                        <template #head(20)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- Mméd -->
                                        <template #head(18)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- Mfor -->
                                        <template #head(19)="data">
                                            <p class="cells">{{ data.label }}</p>
                                        </template>
                                        <!-- Estdisponível -->
                                        <template #head(10)="data">
                                            <p class="estoque estoque-ativo">{{ data.label }}</p>
                                        </template>
                                        <!-- Poder_terceiros -->
                                        <template #head(4)="data">
                                            <p class="estoque estoque-ativo">{{ data.label }}</p>
                                        </template>
                                        <!-- Rejeitado -->
                                        <template #head(5)="data">
                                            <p class="estoque estoque-ativo">{{ data.label }}</p>
                                        </template>
                                        <!-- Embalagem -->
                                        <template #head(6)="data">
                                            <p class="estoque estoque-ativo">{{ data.label }}</p>
                                        </template>
                                        <!-- Quarentena -->
                                        <template #head(11)="data">
                                            <p class="estoque estoque-ativo">{{ data.label }}</p>
                                        </template>
                                        <!-- Volume -->
                                        <template #head(13)="data">
                                            <p class="prog prog-ativo">{{ data.label }}</p>
                                        </template>
                                        <!-- Data -->
                                        <template #head(14)="data">
                                            <p class="prog prog-ativo">{{ data.label }}</p>
                                        </template>
                                        <!-- M_H -->
                                        <template #head(15)="data">
                                            <p class="prog prog-ativo">{{ data.label }}</p>
                                        </template>
                                        <!-- Largura -->
                                        <template #head(23)="data">
                                            <p class="embal"> {{ data.label }}</p>
                                        </template>
                                        <!-- Comprimento -->
                                        <template #head(25)="data">
                                            <p class="embal">{{ data.label }}</p>
                                        </template>
                                        <!-- Peso -->
                                        <template #head(26)="data">
                                            <p class="embal">{{ data.label }}</p>
                                        </template>
                                        <!-- Peso_cubado -->
                                        <template #head(27)="data">
                                            <p class="embal"> {{ data.label }}</p>
                                        </template>
                                        <!-- Quantidade -->
                                        <template #head(28)="data">
                                            <p class="embal"> {{ data.label }}</p>
                                        </template>
                                        <!-- Altura -->
                                        <template #head(24)="data">
                                            <p class="embal">{{ data.label }}</p>
                                        </template>

                                        <!-- Estsegurança -->
                                        <template #head(21)="data">
                                            <p class="infos infos-ativo"> {{ data.label }}</p>
                                        </template>
                                        <!-- Lead_time -->
                                        <template #head(22)="data">
                                            <p class="infos infos-ativo"> {{ data.label }}</p>
                                        </template>

                                        <template v-slot:head()="data">
                                            <p v-if="data.field.key > '28'" class="estoque estoque-ativo">{{ data.label }}
                                            </p>
                                        </template>

                                        <template #cell(produtos)="data">
                                            <td style="max-width: 300px !important;">
                                                        <label :for="data.item.id" style="text-align: left; display: block" >
                                                    <b-button v-b-toggle="'prod-' + data.index" class="" v-b-tooltip.hover
                                                        @click="findProduct(data.item, data.index, true)">
                                                           {{ data.item.name }}
                                                    </b-button>
                                                </label>
                                            </td>
                                                <div class="item-content" :class="{ 'open': isOpen[data.index] }">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> {{ deri.code }} {{
                                                    deri.derivation
                                                }}</p>

                                                <p> <strong> SOMA: </strong> </p>
                                            </div>
                                        </template>
                                        <!-- forecast -->
                                        <template #cell(1)="data">
                                            <div class="item-content" :class="{ 'open': isOpen[data.index] }">
                                                <div  class="container">
                                                    <div v-if="result_forecast" class="container grid-list">
                                                        <p v-for="item in result_forecast" :key="item">
                                                            <span v-if="item.forecast != 0">
                                                                {{ obterQuantidadeForecast(item.derivation, item.forecast) }}
                                                            </span>
                                                            <span v-else>
                                                                0
                                                            </span>

                                                        </p>
                                                        <p> <strong> {{ calcularTotal(result_forecast) }} </strong> </p>

                                                    </div>
                                                    <div v-else class="container grid-list">
                                                        <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                        <p> <strong> 0 </strong> </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- fat liquido -->
                                        <template #cell(2)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div  class="container">
                                                    <div v-if="result_forecast" class="container grid-list">
                                                        <p v-for="item in result_forecast" :key="item">
                                                            <span v-if="item.liquido != 0">
                                                                {{ obterQuantidadeLiquido(item.derivation, item.liquido) }}
                                                            </span>
                                                            <span v-else>
                                                                0
                                                            </span>

                                                        </p>
                                                        <p> <strong> {{ calcularTotalLiquido(result_forecast) }} </strong> </p>

                                                    </div>
                                                    <div v-else class="container grid-list">
                                                        <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                        <p> <strong> 0 </strong> </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- a realizar -->
                                        <template #cell(3)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div  class="container">
                                                    <div v-if="result_forecast" class="container grid-list">
                                                        <p v-for="item in result_forecast" :key="item">
                                                            <span v-if="item.realizar != 0">
                                                                {{ obterQuantidadeRealizar(item.derivation, item.realizar) }}
                                                            </span>
                                                            <span v-else>
                                                                0
                                                            </span>
                                                        </p>
                                                        <p> <strong> {{ calcularTotalRealizar(result_forecast) }} </strong> </p>

                                                    </div>
                                                    <div v-else class="container grid-list">
                                                        <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                        <p> <strong> 0 </strong> </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- poder terceiros -->
                                        <template #cell(4)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- rejeitado -->
                                        <template #cell(5)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- embalagem -->
                                        <template #cell(6)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- ped nao fechado -->
                                        <template #cell(7)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">

                                                        <span v-if="item.pedFechado != 0">
                                                            {{ obterQtdeFechado(item.derivation, item.pedFechado) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>

                                                    </p>
                                                    <p> <strong> {{ calcularTotalFechado(result_forecast) }} </strong> </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- ped aberto -->
                                        <template #cell(8)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                        <span v-if="item.pedAberto != 0">
                                                            {{ obterQuantidadeAberto(item.derivation, item.pedAberto) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>
                                                    </p>
                                                    <p> <strong> {{ calcularTotalPedAberto(result_forecast) }} </strong>
                                                    </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>


                                            </div>
                                        </template>
                                        <!-- logistica -->
                                        <template #cell(9)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                        <span v-if="item.logistica != 0">
                                                            {{ obterQuantidadeLogistica(item.derivation, item.logistica) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>
                                                    </p>
                                                    <p> <strong> {{ calcularTotalLogistica(result_forecast) }} </strong>
                                                    </p>

                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- est disponivel -->
                                        <template #cell(10)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- quarentena -->
                                        <template #cell(11)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- est planejamento -->
                                        <template #cell(12)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                        <span v-if="item.estplanejamento != 0">
                                                            {{ obterQuantidadeDerivacao(item.derivation, item.estplanejamento) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>
                                                        <!-- <span v-for="est in item.estplanejamento" :key="est.codDer">
                                                            <span v-if="est.codDer == item.derivation">
                                                                {{ est.qtdEst }}
                                                            </span>
                                                        </span> -->

                                                     </p>
                                                    <p > <strong> {{ calcularTotalPlanejamento(result_forecast) }} </strong> </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- volume -->
                                        <template #cell(13)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                        <span v-if="item.volume != 0">
                                                            {{ obterQtdeDerVolume(item.derivation, item.volume) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>
                                                    </p>
                                                    <p> <strong> {{ calcularTotalVolume(result_forecast) }} </strong> </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- data -->
                                        <template #cell(14)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                        <span v-if="item.data != 0">
                                                            {{ obterQtdeDerData(item.derivation, item.data) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>
                                                    </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- M_H -->
                                        <template #cell(15)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- entrada -->
                                        <template #cell(16)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                        <span v-if="item.entrada != 0">
                                                            {{ obterQtdeDerEntrada(item.derivation, item.entrada) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>
                                                    </p>
                                                    <p> <strong> {{ calcularTotalEntrada(result_forecast) }} </strong>
                                                    </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!--Estfinal  -->
                                        <template #cell(17)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                        <span v-if="item.estfinal != 0">
                                                            {{ obterQtdeDerFinal(item.derivation, item) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>
                                                    </p>
                                                    <p> <strong> {{ calcularTotalFinal(result_forecast) }} </strong> </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>

                                            </div>
                                        </template>
                                        <!-- Mméd -->
                                        <template #cell(18)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                        <span v-if="item.mmedia != 0">
                                                            {{ obterQtdeDerMedio(item.derivation, item.mmedia) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>

                                                    </p>
                                                    <p> <strong> {{ calcularTotalMMedia(result_forecast) }} </strong> </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <!-- <div class="container grid-list"> -->
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- Mfor -->
                                        <template #cell(19)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                            <!-- {{ est.forecast_budget }}
                                                            {{ est.planejamento }} -->
                                                            <span v-if="item.mfor != 0">
                                                                {{ obterQtdeDerMfor(item.derivation, item.mfor) }}
                                                            </span>
                                                            <span v-else>
                                                                0
                                                            </span>
                                                    </p>
                                                    <p> <strong> {{ calcularTotalMFOR(result_forecast) }} </strong> </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                <!-- <div class="container grid-list"> -->
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- EFMA -->
                                        <template #cell(20)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                        <span v-if="item.efma != 0">
                                                            {{ obterQtdeDerEfma(item.derivation, item.efma) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>
<!--                                                         
                                                        <span v-for="est in item.efma" :key="est.codDer">
                                                            <span v-if="est.codDer == item.derivation">
                                                                {{ est.qtd_ped }}
                                                            </span>
                                                        </span> -->
                                                    </p>
                                                    <p> <strong> {{ calcularTotalEFMA(result_forecast) }} </strong> </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <!-- <div class="container grid-list"> -->
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- Estsegurança -->
                                        <template #cell(21)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                    
                                                        <span v-if="item.estSeguranca != 0">
                                                            {{ obterQtdeDerSeguranca(item.derivation, item.estSeguranca) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>
                                                    </p>
                                                    <p> <strong> {{ calcularTotalEstoque(result_forecast) }}
                                                        </strong> </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!--  Lead_time-->
                                        <template #cell(22)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                    
                                                        <span v-if="item.leadTime != 0">
                                                            {{ obterQuantidadeLeadTime(item.derivation, item.leadTime) }}
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>

                                                    </p>
                                                    <p><strong>{{ calcularTotalLeadTime(result_forecast) }}</strong></p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- Largura -->
                                        <template #cell(23)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- Altura -->
                                        <template #cell(24)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- Comprimento -->
                                        <template #cell(25)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!--Peso  -->
                                        <template #cell(26)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- Peso_cubado -->
                                        <template #cell(27)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">
                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>

                                        <!-- Quantidade -->
                                        <template #cell(28)="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }">

                                                <div class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id"> 0 </p>
                                                    <p> <strong> 0 </strong> </p>
                                                </div>
                                            </div>
                                        </template>

                                        <template v-for="(field, index) in parametersNameStock"
                                            #[`cell(${field.id})`]="data">
                                            <div class="item-content" :class="{ 'container open': isOpen[data.index] }" :key="index">
                                                <div v-if="result_forecast" class="container grid-list">
                                                    <p v-for="item in result_forecast" :key="item.code">
                                                        <span v-if="item.stock != 0">
                                                            {{ obterQtdeDerStock(item.derivation, item.stock, field.name) }}
                                                            <!-- <span v-if="hasValidStock(item.stock, item.derivation, field.name)">
                                                            <span v-for="stock in item.stock" :key="stock.codDer">
                                                            <span v-if="stock.total_qtd_ped > 0 && stock.codDer == item.derivation && stock.name == field.name">
                                                                {{ stock.total_qtd_ped }}
                                                            </span>
                                                            </span>
                                                    </span> -->
                                                        </span>
                                                        <span v-else>
                                                            0
                                                        </span>

                                                    <!-- Variável para controlar se algum item atende à condição -->
                                                    <!-- <span v-if="hasValidStock(item.stock, item.derivation, field.name)">
                                                        <span v-for="stock in item.stock" :key="stock.codDer">
                                                        <span v-if="stock.total_qtd_ped > 0 && stock.codDer == item.derivation && stock.name == field.name">
                                                            {{ stock.total_qtd_ped }}
                                                        </span>
                                                        </span>
                                                    </span>
                                                    <span v-else-if="item.stock && item.stock.length == 0">
                                                        0
                                                    </span> -->
                                                    </p>
                                                    <p v-if="calcularTotalStocks(result_forecast, field.name)">
                                                        <strong>{{ calcularTotalStocks(result_forecast, field.name) }}</strong>
                                                    </p>
                                                    <p v-else>
                                                        <strong>0</strong>
                                                    </p>
                                                </div>
                                                <div v-else class="container grid-list">
                                                    <p v-for="deri in data.item.items" :key="deri.id">0</p>
                                                    <p><strong>0</strong></p>
                                                </div>
                                                </div>


                                        </template>


                                    </b-table>

                                </div>
                            </b-col>
                        </b-row>

                        <b-row class="paginations-options">
                            <b-col md="6">
                                <span class="mt-3">Resultados {{ currentPage }} de {{ numeroDePaginas }}</span>
                            </b-col>
                            <b-col md="6">
                                <b-pagination pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage">
                                </b-pagination>
                            </b-col>
                        </b-row>


                    </div>
                </div>
            </div>
        </PageComponent>
    </div>
</template>

<script>
import PageComponent from "../page/PageComponent.vue";
import moment from "moment";
import { format } from 'date-fns'; // Importe as funções necessárias do date-fns

export default {
    name: "SimulationSeopComponent",
    components: {
        PageComponent,
    },
    data() {
        return {
            userLogged: localStorage.getItem("userLogged")
                ? JSON.parse(localStorage.getItem("userLogged"))
                : null,
            perPage: 10,
            currentPage: 1,
            search: "",
            products: [],
            date: format(new Date(), 'yyyy-MM'),
            all_select: false,
            select_product: [],
            teste: '',
            months: '',
            qtd_months: 6,
            parameters: [],
            estoque: false,
            embal: false,
            prog: false,
            infos: false,
            winWidth: 0,
            items: [],

            additionalColumn: { key: 'produtos', label: 'produtos' },
            itensSelecionados: [],
            itemsPerPage: 10,
            items1: ["Item 1", "Item 2", "Item 3", "Item 4"],
            showIndex: -1,
            isOpen: [],
            parametersNameStock: [],
            progresso: 0,
			itensProcessados: [],
			processando: false,
            isProgress: false,
            loadingIndex: null,
            loadingIndexes: [],
            loadingPercentages: Array.from({ length: 3 }, () => 0), // Inicializa a porcentagem para cada item
            result_forecast: [],
            totalStocks: 0,
            name_scenario: ''
        };
    },

    mounted() {
        this.$root.$emit('bv::toggle::collapse', 'sidebar', false);
        this.getScenario();
        this.getParameters();
        this.getProducts();
        this.getItemsSeop();

        this.toggleCheckbox();

    },

    methods: {
        async getScenario(){
            await this.$http
                .get(`/scenarios/${this.$route.params.id}/simulations/${this.$route.params.simulationId}/s&op`)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        this.name_scenario = response.data.name_scenario;
                        this.name_simulation = response.data.name_simulation;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        hasValidStock(stock, derivation, name) {
            return stock && stock.some(stockItem =>
                stockItem.total_qtd_ped > 0 &&
                stockItem.codDer == derivation &&
                stockItem.name == name
            );
        },

        toggleItem(index) {
            // Verificar se o card atual está aberto
            const isOpen = this.isOpen[index];

            // Fechar todos os cards se o card atual já estiver aberto
            if (isOpen) {
                this.isOpen = this.isOpen.map(() => false);
            } else {
                // Se o card atual não estiver aberto, fechar todos os cards e abrir o atual
                this.isOpen = this.isOpen.map(() => false);
                this.$set(this.isOpen, index, true);
            }
        },

        changePage(page) {
            this.currentPage = page;
        },

        calcularTotalLeadTime(items) {
            const totalArray = items.map(item => {
            if(item.leadTime != 0){
                return item.leadTime.reduce((leadTotal, lead) => {
                    if (lead.codDer === item.derivation) {
                        return parseInt(leadTotal) + parseInt(lead.qtd);
                    }
                    return leadTotal;
                    }, 0);
            } else {
                return 0;
            }
            });

            return parseFloat(totalArray.reduce((total, value) => total + value, 0)).toFixed(2);
        },

        calcularTotal(items) {
            const totalArray = items.map(item => {
            if(item.forecast != 0){
                return item.forecast.reduce((forecast, forec) => {
                    if (forec.codDer === item.derivation) {
                        return parseInt(forecast) + parseInt(forec.qtd);
                    }
                    return forecast;
                    }, 0);
            } else {
                return 0;
            }
            });

            return parseFloat(totalArray.reduce((total, value) => total + value, 0)).toFixed(2);
        },

        calcularTotalLiquido(items) {
            const totalArray = items.map(item => {
            if(item.liquido != 0){
                return item.liquido.reduce((forecast, forec) => {
                if (forec.codDer === item.derivation) {
                    return parseInt(forecast) + parseInt(forec.qtd_ped);
                }
                return forecast;
                }, 0);
            } else {
                return 0;
            }
            });

            return parseFloat(totalArray.reduce((total, value) => total + value, 0)).toFixed(2);
        },

        calcularTotalVolume(items) {
            const totalArray = items.map(item => {
            if(item.volume != 0){
                return item.volume.reduce((volume, vol) => {
                    if (vol.codDer === item.derivation) {
                        return parseInt(volume) + parseInt(vol.qtd_comprada);
                    }
                    return volume;
                    }, 0);
            } else {
                return 0;
            }
            });

            return parseFloat(totalArray.reduce((total, value) => total + value, 0)).toFixed(2);
        },

        calcularTotalEntrada(items) {
            const totalArray = items.map(item => {
            if(item.entrada != 0){
                return item.entrada.reduce((entrada, ent) => {
                    if (ent.codDer === item.derivation) {
                        return parseInt(entrada) + parseInt(ent.qtdIte);
                    }
                    return entrada;
                    }, 0);
                } else {
                return 0;
            }
            });

            return parseFloat(totalArray.reduce((total, value) => total + value, 0)).toFixed(2);
        },

        calcularTotalEstoque(items) {
            const totalArray = items.map(item => {
                if(item.estSeguranca != 0){

                return item.estSeguranca.reduce((entrada, ent) => {
                if (ent.codDer === item.derivation) {
                    return parseInt(entrada) + parseInt(ent.qtd);
                }
                return entrada;
                }, 0);
            } else {
                return 0;
            }
            });

            return parseFloat(totalArray.reduce((total, value) => total + value, 0)).toFixed(2);
        },


        calcularTotalStocks(result_forecast, field_name) {
            let total = 0;
            for (const item of result_forecast) {
                if(item.stock != 0){
                    for (const stock of item.stock) {
                        if (stock.codDer == item.derivation && stock.name == field_name) {
                            total += stock.total_qtd_ped;
                        }
                    }
                } else {
                    total = 0;
                }
                
            }
            return total;
        },

        calcularTotalPedAberto(items) {
            const totalArray = items.map(item => {
            if(item.pedAberto != 0){

            return item.pedAberto.reduce((entrada, ent) => {
                if (ent.codDer === item.derivation) {
                    return parseInt(entrada) + parseInt(ent.qtd_ped);
                }
                return entrada;
                }, 0);
            } else {
                return 0;
            }
            });

            return parseFloat(totalArray.reduce((total, value) => total + value, 0)).toFixed(2);
        },

        calcularTotalLogistica(items) {
            const totalArray = items.map(item => {
            if(item.logistica != 0){

            return item.logistica.reduce((entrada, ent) => {
                if (ent.codDer === item.derivation) {
                    return parseInt(entrada) + parseInt(ent.qtd_ped);
                }
                return entrada;
                }, 0);
            } else {
                return 0;
            }
            });

            return parseFloat(totalArray.reduce((total, value) => total + value, 0)).toFixed(2);
        },

        calcularTotalPlanejamento(items) {
            const totalArray = items.map(item => {
                if(item.estplanejamento != 0){
                    return item.estplanejamento.reduce((entrada, ent) => {
                    if (ent.codDer === item.derivation) {
                        return parseInt(entrada) + parseInt(ent.qtdEst);
                    }
                    return entrada;
                    }, 0);
                } else {
                    return 0;
                }
            });

            return parseFloat(totalArray.reduce((total, value) => total + value, 0)).toFixed(2);
        },

        temDerivacao(derivation, estplanejamento) {
            if(estplanejamento){
                return estplanejamento.some(est => est.codDer === derivation);
            }
        },

        obterQuantidadeDerivacao(derivation, estplanejamento) {
            const item = estplanejamento.find(est => est.codDer === derivation);
            return item ? item.qtdEst : 0;
        },

        obterQtdeDerEfma(derivation, efma) {
            const item = efma.find(est => est.codDer === derivation);
            return item ? item.qtd_ped : 0;
        },

        obterQtdeDerMfor(derivation, mfor) {
            const item = mfor.find(est => est.codDer === derivation);
            return item ? item.total : 0;
        },

        obterQtdeDerMedio(derivation, mfor) {
            const item = mfor.find(est => est.codDer === derivation);
            return item ? item.total : 0;
        },

        obterQtdeDerEntrada(derivation, entrada) {
            const item = entrada.find(est => est.codDer === derivation);
            return item ? item.total : 0;
        },

        obterQtdeDerData(derivation, data) {
            const item = data.find(est => est.codDer === derivation);
            return item ? item.data : 0;
        },

        obterQtdeDerVolume(derivation, volume) {
            const item = volume.find(est => est.codDer === derivation);
            return item ? item.qtd_comprada : 0;
        },

        obterQuantidadeForecast(derivation, forecast){
            const item = forecast.find(est => est.codDer === derivation);
            return item ? item.qtd : 0;
        },

        obterQuantidadeAberto(derivation, pedAberto){
            const item = pedAberto.find(est => est.codDer === derivation);
            return item ? item.qtd_ped : 0;
        },

        obterQuantidadeLogistica(derivation, logistica){
            const item = logistica.find(est => est.codDer === derivation);
            return item ? item.qtd_ped : 0;
        },

        obterQuantidadeRealizar(derivation, pedAberto){
            const item = pedAberto.find(est => est.codDer === derivation);
            return item ? item.total : 0;
        },

        obterQuantidadeLiquido(derivation, liquido){
            const item = liquido.find(est => est.codDer === derivation);
            return item ? item.qtd_ped : 0;
        },

        obterQuantidadeLeadTime(derivation, leadTime){
            const item = leadTime.find(est => est.codDer === derivation);
            return item ? item.qtd : 0;
        },

        obterQtdeDerSeguranca(derivation, estSeguranca){
            const item = estSeguranca.find(est => est.codDer === derivation);
            return item ? item.qtd : 0;
        },

        obterQtdeDerStock(derivation, stock, name){
            const item = stock.find(est => est.codDer === derivation && est.name == name);
            return item ? item.total_qtd_ped : 0;
        },

        obterQtdeDerFinal(derivation, item){
            let res_entrada = 0;
            let res_item = 0;
            let res_realizar = 0;

            if(item.estplanejamento != 0){
                let item_pla = item.estplanejamento.find(est => est.codDer === derivation);
                res_item = item_pla ? item_pla.qtdEst : 0;
            } else {
                res_item = 0;
            }

            if(item.realizar != 0){
                let realizar = item.realizar.find(est => est.codDer === derivation); 
                res_realizar = realizar ? realizar.total : 0;
            } else {
                res_realizar = 0;
            }

            if(item.entrada != 0){
                let entrada = item.entrada.find(est => est.codDer === derivation);
                res_entrada = entrada ? entrada.total : 0;
            } else {
                res_entrada = 0;
            }

            
            return (res_item - res_realizar) + res_entrada;
        },

        obterQtdeFechado(derivation, pedido){
            const item = pedido.find(est => est.codDer === derivation);
            return item ? item.qtd_ped : 0;
        },

        calcularTotalFinal(items) {
            const totalArray = items.map(item => {
                if (item.estplanejamento !== 0) {
                    return item.estplanejamento.reduce((entrada, ent) => {
                        if (ent.codDer === item.derivation) {
                            return parseInt(entrada) + parseInt(ent.qtdEst);
                        }
                        return entrada;
                    }, 0);
                } else {
                    return 0;
                }
            });

            const totalArrayRealizar = items.map(item => {
                if (item.realizar !== 0) {
                    return item.realizar.reduce((entrada, ent) => {
                        if (ent.codDer === item.derivation) {
                            return parseInt(entrada) + parseInt(ent.total);
                        }
                        return entrada;
                    }, 0);
                } else {
                    return 0;
                }
            });

            const totalArrayEntrada = items.map(item => {
                if (item.entrada !== 0) {
                    return item.entrada.reduce((entrada, ent) => {
                        if (ent.codDer === item.derivation) {
                            return parseInt(entrada) + parseInt(ent.total);
                        }
                        return entrada;
                    }, 0);
                } else {
                    return 0;
                }
            });

            let result_plan = parseFloat(totalArray.reduce((total, value) => total + value, 0)).toFixed(2);
            let result_real = parseFloat(totalArrayRealizar.reduce((total, value) => total + value, 0)).toFixed(2);
            let result_entr = parseFloat(totalArrayEntrada.reduce((total, value) => total + value, 0)).toFixed(2);

            // Convertendo os resultados numéricos para números antes de realizar a soma final
            result_plan = parseFloat(result_plan);
            result_real = parseFloat(result_real);
            result_entr = parseFloat(result_entr);

            return result_plan + result_real + result_entr;
        },
        
        calcularTotalMFOR(items) {
            const totalArray = items.map(item => {
            if(item.mfor != 0){
                return item.mfor.reduce((entrada, ent) => {
                    if (ent.codDer === item.derivation) {
                        return parseInt(entrada) + parseInt(ent.total);
                    }
                    return entrada;
                    }, 0);
            } else {
                return 0;
            }
            });

            return parseFloat(totalArray.reduce((total, value) => total + value, 0)).toFixed(2);
        },

        calcularTotalEFMA(items) {
            const totalArray = items.map(item => {
            if(item.efma != 0){

                return item.efma.reduce((entrada, ent) => {
                    if (ent.codDer === item.derivation) {
                        return parseFloat(entrada) + parseFloat(ent.qtd_ped);
                    }
                    return entrada;
                    }, 0);
                } else {
                    return 0;
                }
            });

            return parseFloat(totalArray.reduce((total, value) => total + value, 0)).toFixed(2);
        },

        calcularTotalMMedia(items) {
            const totalArray = items.map(item => {
            if(item.mmedia != 0){

                return item.mmedia.reduce((entrada, ent) => {
                    if (ent.codDer === item.derivation) {
                    return parseFloat(entrada) + parseFloat(ent.total);
                }
                return entrada;
                }, 0);
            } else {
                return 0;
            }
            });

            return parseFloat(totalArray.reduce((total, value) => total + value, 0)).toFixed(2);
        },

        calcularTotalFechado(items) {
            const totalArray = items.map(item => {
            if(item.pedFechado != 0){

            return item.pedFechado.reduce((entrada, ent) => {
                if (ent.codDer === item.derivation) {
                    return parseInt(entrada) + parseInt(ent.qtd_ped);
                }
                return entrada;
                }, 0);
            } else {
                return 0;
            }
            });

            return parseFloat(totalArray.reduce((total, value) => total + value, 0)).toFixed(2);
        },

        calcularTotalRealizar(items) {
            const totalArray = items.map(item => {
            if(item.realizar != 0){

            return item.realizar.reduce((entrada, ent) => {
                if (ent.codDer === item.derivation) {
                    return parseInt(entrada) + parseInt(ent.total);
                }
                return entrada;
                }, 0);
            } else {
                return 0;
            }
            });

            return parseFloat(totalArray.reduce((total, value) => total + value, 0)).toFixed(2);
        },

        toggleCheckbox() {
            this.months = [...Array(this.qtd_months)].map((a, b) => moment(this.date).add(b, 'M').locale('pt').format("MMM, YYYY"))
            this.teste = this.values.map(function (product) {
                return product
            })
        },
        goSuccess(message) {
            this.$swal({
                toast: true,
                position: "center",
                showConfirmButton: false,
                timer: 4000,
                icon: "success",
                title: "Sucesso!",
                text: message ? message : "Cadastro Atualizado.",
            });
        },

        goError(message) {
            this.$swal({
                toast: true,
                position: "center",
                showConfirmButton: true,
                icon: "error",
                title: "Atenção!",
                text: message ? message : "Já existe um usuário com este e-mail.",
            });
        },

        async getProducts() {
            await this.$http
                .get(`/simulation/s&op/productsWithAgroup`)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        this.products = response.data.products;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        async getParameters() {
            await this.$http
                .get(`/simulation/s&op/parameters`)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        this.parameters = response.data.parametersName;
                        this.estoque = JSON.parse(response.data.estoque.toLowerCase());
                        this.prog = JSON.parse(response.data.programacao.toLowerCase());
                        this.embal = JSON.parse(response.data.embalagem.toLowerCase());
                        this.infos = JSON.parse(response.data.infos.toLowerCase());
                        this.parametersNameStock = response.data.parametersStock;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        async getItemsSeop(item) {
            let formData = new FormData();
            if (item) {
                formData.append("Código", item.code);
            }

            await this.$http
                .post(`/simulation/s&op`, formData)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        this.items = response.data.total;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        },

        async findProduct(item, index, select_product) {
            this.$swal.showLoading();

            this.select_product[index] = select_product;
            if (this.select_product[index]) {
                this.itensSelecionados.push(item.code);
            } else {
                const index = this.itensSelecionados.indexOf(item);
                this.itensSelecionados.splice(index, 1);

                if (index !== -1) {
                    this.itensSelecionados.splice(index, 1);
                }
            }

            let formData = new FormData();
            formData.append("code", this.itensSelecionados);
            formData.append("date", moment(this.date).format("M/YYYY"));
            formData.append("simulationId", this.$route.params.simulationId);
            
            console.log(moment(this.date).format("MM/YYYY"))

            await this.$http
                .post(`/simulation/s&op/result`, formData)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                        this.$swal.hideLoading();
                    } else {
                        this.$swal.hideLoading();
                        this.$swal.close();

                        this.result_forecast = response.data.products_new;
                        this.toggleItem(index); 

                        console.log("response.data.products_new")
                        console.log(response.data.products_new)
                        this.itensSelecionados.splice(0, 1)  
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },


        allSelect() {
            this.select_product = [];
        },

        selectDate() {
            this.months = [...Array(this.qtd_months)].map((a, b) => moment(this.date).add(b, 'M').locale('pt').format("MMM, YYYY"))
            this.isOpen = this.products.map(() => false)
        },

        addMonths() {
            this.qtd_months = 12
            this.months = [...Array(this.qtd_months)].map((a, b) => moment(this.date).add(b, 'M').locale('pt').format("MMM, YYYY"))
        },
        async selectEstoque() {
            if (this.estoque == true) {
                this.estoque = "false";
            }
            if (this.estoque == 'false') {
                this.estoque = "true";
            }
            let formData = new FormData();
            formData.append("disabled", this.estoque);
            formData.append("group", 'Estoque');
            await this.$http
                .post(`/simulation/s&op/update_group`, formData)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        this.parameters = response.data.parameters_update;
                        this.estoque = JSON.parse(response.data.estoque.toLowerCase());
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        async selectProgramacao() {
            // this.prog = !this.prog;
            console.log(this.prog)
            if (this.prog == true) {
                this.prog = "false";
            }
            if (this.prog == 'false') {
                this.prog = "true";
            }
            let formData = new FormData();
            formData.append("disabled", this.prog);
            formData.append("group", 'Programacao');
            await this.$http
                .post(`/simulation/s&op/update_group`, formData)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        this.parameters = response.data.parameters_update;
                        this.prog = JSON.parse(response.data.programacao.toLowerCase());
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        },
        async selectEmbalagem() {
            if (this.embal == true) {
                this.embal = "false";
            }
            if (this.embal == 'false') {
                this.embal = "true";
            }

            let formData = new FormData();
            formData.append("disabled", this.embal);
            formData.append("group", 'Embalagem');
            await this.$http
                .post(`/simulation/s&op/update_group`, formData)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        this.parameters = response.data.parameters_update;
                        this.embal = JSON.parse(response.data.embalagem.toLowerCase());
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        async selectInfo() {
            if (this.infos == true) {
                this.infos = "false";
            }
            if (this.infos == 'false') {
                this.infos = "true";
            }
            let formData = new FormData();

            formData.append("disabled", this.infos);
            formData.append("group", 'Infos');
            await this.$http
                .post(`/simulation/s&op/update_group`, formData)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        this.parameters = response.data.parameters_update;
                        this.infos = JSON.parse(response.data.infos.toLowerCase());
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },

    computed: {
        updatedFields() {
            const modifiedArray = [];
            // Percorre o array original
            for (let i = 0; i < this.parameters.length; i++) {
                const element = this.parameters[i].name;
                const elementId = this.parameters[i].id;

                // Substitui espaços por underscores e adiciona ao novo array
                // const modifiedElement = element.replace(/\./g, '').replace(/ /g, '_');

                modifiedArray.push({ 'key': elementId.toString(), 'label': element });
            }

            return [this.additionalColumn, ...modifiedArray];
        },

        fieldsProduct: function () {
            let fields = [
                {
                    key: "A Realizar",
                    label: "A Realizar",
                },

                {
                    key: "Forecasting",
                    label: "Forecasting",
                },

                {
                    key: "Fat.líquido",
                    label: "Fat.líquido",
                },

                {
                    key: "Ped.aberto",
                    label: "Ped.aberto",
                },

                {
                    key: "Logística",
                    label: "Logística",
                },

                {
                    key: "Est.planejamento",
                    label: "Est.planejamento",
                },

                {
                    key: "Entrada",
                    label: "Entrada",
                },

                {
                    key: "Est.final",
                    label: "Est.final",
                },

                {
                    key: "M.méd",
                    label: "M.méd",
                },

                {
                    key: "M.for",
                    label: "M.for",
                },

                {
                    key: "EFMA",
                    label: "EFMA",
                },

                {
                    key: "Volume",
                    label: "Volume",
                },

                {
                    key: "Data",
                    label: "Data",
                },

                {
                    key: "M_H",
                    label: "M_H",
                },

                {
                    key: "Largura",
                    label: "Largura",
                },

                {
                    key: "Altura",
                    label: "Altura",
                },

                {
                    key: "Comprimento",
                    label: "Comprimento",
                },

                {
                    key: "Peso",
                    label: "Peso",
                },

                {
                    key: "Quantidade",
                    label: "Quantidade",
                },

                {
                    key: "Mais informações",
                    label: "Mais informações",
                },

            ];
            return fields;
        },

        // paginatedCards() {
        //     const { currentPage, perPage } = this;
        //     const start = (currentPage - 1) * perPage;
        //     const end = currentPage * perPage;

        //     return this.dolars.slice(start, end);
        // },

        filteredList() {
            return this.products.filter((product) => {
                const nameMatch = product.name.toLowerCase().includes(this.search.toLowerCase());
                const codeMatch = product.code.toString().toLowerCase().includes(this.search.toLowerCase());
                // Se você deseja filtrar por ambos name e code, use && (E lógico)
                // Se você deseja filtrar por name OU code, use || (OU lógico)
                return nameMatch || codeMatch;
            });
        },
        totalPages() {
            return Math.ceil(this.products.length / this.itemsPerPage);
        },


        contentStyles() {
            return {
                width: 'calc(100% - 0)'
            };
        },

        totalRows() {
            return this.products.length;
        },
        paginatedCards() {
            const { currentPage, perPage } = this;
            const start = (currentPage - 1) * perPage;
            const end = currentPage * perPage;

            return this.products.slice(start, end);
        },

        numeroDePaginas() {
            return Math.ceil(this.products.length / this.perPage);
        },

    },

    filters: {
        dateFormat: function (date) {
            return moment(date).format("DD.MM.YYYY");
        },
        truncarTexto(value, length) {
            if (!value) return '';
            if (value.length <= length) return value;
            return value.substring(0, length) + '...';
        }
    },
};
</script>

<style lang="scss" src="./style.scss" scoped />
