<template>
	<div class="component-tabs-quantity">
		<b-row>
			<b-col class="col-months responsive" md="12">
				<div class="responsive table-responsive">
					<table class="table-import table responsive">
						<!-- Table headers -->
						<thead>
							<tr>
								<th>Mês</th>
								<th scope="col" v-for="month in months" :key="month">{{ month }}</th>
							</tr>
						</thead>

						<!-- Table rows -->
						<tbody>
							<tr>
								<td> <span>Crescimento %</span> </td>
								<td v-for="(factor, index) in orderedFactorsGrowth" :key="index">
										<b-form-input
										v-model="resultado_growth[index].valor"
										@blur="saveGrowth(resultado_growth[index], resultado_growth[index])"
										></b-form-input>
								</td>
							</tr>
							<tr>
								<td> <span>Dólar</span> </td>
								<td><b-form-input v-model="dataObj.dolar_jan"></b-form-input></td>
								<td><b-form-input v-model="dataObj.dolar_feb"></b-form-input></td>
								<td><b-form-input v-model="dataObj.dolar_mar"></b-form-input></td>
								<td><b-form-input v-model="dataObj.dolar_apr"></b-form-input></td>
								<td><b-form-input v-model="dataObj.dolar_may"></b-form-input></td>
								<td><b-form-input v-model="dataObj.dolar_jun"></b-form-input></td>
								<td><b-form-input v-model="dataObj.dolar_jul"></b-form-input></td>
								<td><b-form-input v-model="dataObj.dolar_aug"></b-form-input></td>
								<td><b-form-input v-model="dataObj.dolar_sep"></b-form-input></td>
								<td><b-form-input v-model="dataObj.dolar_oct"></b-form-input></td>
								<td><b-form-input v-model="dataObj.dolar_nov"></b-form-input></td>
								<td><b-form-input v-model="dataObj.dolar_dec"></b-form-input></td>
							</tr>
						</tbody>
					</table>
				</div>
			</b-col>
		</b-row>
		<b-row style="margin-top: 20px;" class="buttons">

			<b-col md="8" class="btn-cancel">
				<b-button class="button" style="margin-left: 5px;" @click="cancel()">
					Cancelar
				</b-button>
			</b-col>

			<b-col md="4" class="btn-save">
				<b-button class="button" @click="saveDolar()" style="margin-left: 5px;" v-if="!dataObj.id">
					Salvar
				</b-button>
				
				<b-button class="button" @click="updateDolar()" style="margin-left: 5px;" v-if="dataObj.id">
					Atualizar
				</b-button>

			</b-col>
		</b-row>
	</div>
</template>

<script>
import moment from "moment";

export default {
	name: "SellSimulationComponent",
	components: {
	},
	data() {
		return {
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			currentYear: new Date().getFullYear(),
			obj: this.$route.params.id,
			dataObj: {
				id_simulation: "",
				dolar_jan: "",
				dolar_feb: "",
				dolar_mar: "",
				dolar_apr: "",
				dolar_may: "",
				dolar_jun: "",
				dolar_jul: "",
				dolar_aug: "",
				dolar_sep: "",
				dolar_oct: "",
				dolar_nov: "",
				dolar_dec: "",
				id: "",
				year: new Date().getFullYear(),
				growth: {
					value_jan: "",
					value_feb: "",
					value_mar: "",
					value_apr: "",
					value_may: "",
					value_jun: "",
					value_jul: "",
					value_aug: "",
					value_sep: "",
					value_oct: "",
					value_nov: "",
					value_dec: "",
				}
			},
			months_growth: '',
			resultado: {},
			resultado_growth: {},
		};
	},
	mounted() {
		this.getDolar();
		this.generateMonthNames();

		this.months = [...Array(12)].map((a, b) =>
				moment(this.date_atual).add(b, 'M').locale('pt').format('MMM, YYYY')
			);
		this.months_growth = [...Array(12)].map((a, b) =>
				moment(this.date_atual).add(b, 'M').format('MMM_YYYY').toLowerCase()
			);
		this.getPurchaseGrowth();
	},
	methods: {
		generateMonthNames() {
			moment.locale('pt-br');

			const currentDate = moment().startOf('month'); // Iniciar no primeiro dia do mês atual
			const numberOfMonths = 12; // Pode ajustar conforme necessário

			for (let i = 0; i < numberOfMonths; i++) {
				const monthYear = currentDate.clone().add(i, 'month').format('MMM_YYYY').toLowerCase();
				const month = currentDate.clone().add(i, 'month').format('MMM').toLowerCase();
				const year = currentDate.clone().add(i, 'month').format('YYYY').toLowerCase();
				this.$set(this.resultado_growth, monthYear, {valor:0, mes: month, ano: year});
			}
		},
		async saveGrowth(value, fieldName) {
			let formData = new FormData();

			formData.append("values_months", fieldName);
			formData.append("growth",fieldName.valor);
			formData.append("date", fieldName.mes);
			formData.append("simulation_id", this.$route.params.id_simulation);
			formData.append("year", fieldName.ano);


			this.$http
				.post(`/scenarios/store/dolar/growth`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.getPurchaseGrowth();
						// this.getPurchaseQtds();
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		async getPurchaseGrowth() {
			await this.$http
				// .get(`/products`)
				.get(`/scenarios/${this.$route.params.id_simulation}/dolar/growth`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.growth = response.data.dolar_growth
						this.growth.forEach(item => {
							const fieldName = `${item.month_year}`;

							// Verifica se o item está presente em this.resultado_growth
							const resultadoItem = this.resultado_growth[fieldName];

							if (resultadoItem) {
								// Se estiver presente, substitui os valores pelos valores de this.growth
								resultadoItem.valor = item.growth;
								resultadoItem.mes = item.date;
								resultadoItem.ano = item.year;
							} else {
								// Se não estiver presente, adiciona o item de this.growth a this.resultado_growth
								this.$set(this.resultado_growth, fieldName, { valor: item.growth, mes: item.date, ano: item.year });
							}
						});
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		async saveDolar() {
			const formData = new FormData();
			formData.append("id_simulation", this.obj);
			formData.append("dolar_jan", this.dataObj.dolar_jan);
			formData.append("dolar_feb", this.dataObj.dolar_feb);
			formData.append("dolar_mar", this.dataObj.dolar_mar);
			formData.append("dolar_apr", this.dataObj.dolar_apr);
			formData.append("dolar_may", this.dataObj.dolar_may);
			formData.append("dolar_jun", this.dataObj.dolar_jun);
			formData.append("dolar_jul", this.dataObj.dolar_jul);
			formData.append("dolar_aug", this.dataObj.dolar_aug);
			formData.append("dolar_sep", this.dataObj.dolar_sep);
			formData.append("dolar_oct", this.dataObj.dolar_oct);
			formData.append("dolar_nov", this.dataObj.dolar_nov);
			formData.append("dolar_dec", this.dataObj.dolar_dec);
			formData.append("year", this.dataObj.year);

			this.$http
				.post(`/scenarios/store/dolar`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response);
					} else {
						this.goSuccess('Dólar cadastrado com sucesso!');
						this.getDolar();
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async updateDolar() {
			const formData = new FormData();
			formData.append("id_simulation", this.obj);
			formData.append("id", this.dataObj.id);
			formData.append("dolar_jan", this.dataObj.dolar_jan);
			formData.append("dolar_feb", this.dataObj.dolar_feb);
			formData.append("dolar_mar", this.dataObj.dolar_mar);
			formData.append("dolar_apr", this.dataObj.dolar_apr);
			formData.append("dolar_may", this.dataObj.dolar_may);
			formData.append("dolar_jun", this.dataObj.dolar_jun);
			formData.append("dolar_jul", this.dataObj.dolar_jul);
			formData.append("dolar_aug", this.dataObj.dolar_aug);
			formData.append("dolar_sep", this.dataObj.dolar_sep);
			formData.append("dolar_oct", this.dataObj.dolar_oct);
			formData.append("dolar_nov", this.dataObj.dolar_nov);
			formData.append("dolar_dec", this.dataObj.dolar_dec);
			formData.append("year", this.dataObj.year);

			this.$http
				.post(`/scenarios/update/dolar`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response);
					} else {
						this.goSuccess('Dólar atualizado com sucesso!');
						this.getDolar();
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		async getDolar() {
			await this.$http
				.get(`/scenarios/dolar/simulation/${this.obj}`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						let dolar = response.data.dolar_simulation;
						this.dataObj.dolar_dec = dolar.dolar_dec;
						this.dataObj.dolar_nov = dolar.dolar_nov;
						this.dataObj.dolar_oct = dolar.dolar_oct;
						this.dataObj.dolar_sep = dolar.dolar_sep;
						this.dataObj.dolar_aug = dolar.dolar_aug;
						this.dataObj.dolar_jul = dolar.dolar_jul;
						this.dataObj.dolar_jun = dolar.dolar_jun;
						this.dataObj.dolar_may = dolar.dolar_may;
						this.dataObj.dolar_apr = dolar.dolar_apr;
						this.dataObj.dolar_mar = dolar.dolar_mar;
						this.dataObj.dolar_feb = dolar.dolar_feb;
						this.dataObj.dolar_jan = dolar.dolar_jan;
						this.dataObj.year = dolar.year ? dolar.year : this.currentYear;
						this.dataObj.id = dolar.id;
						this.dataObj.id_simulation = dolar.id_simulation;
						this.values_sell = []
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		cancel() {
			this.$router.push("/scenarios/simulations/create/" + this.obj);
		}
	},
	computed: {
		valuesGrowthDolar(){
			return this.growth.forEach(item => {
				const fieldName = `${item.month_year}`;

				// Verifica se o item está presente em this.resultado_growth
				const resultadoItem = this.resultado_growth[fieldName];

				if (resultadoItem) {
					// Se estiver presente, substitui os valores pelos valores de this.growth
					resultadoItem.valor = item.growth;
					resultadoItem.mes = item.date;
					resultadoItem.ano = item.year;
				} else {
					// Se não estiver presente, adiciona o item de this.growth a this.resultado_growth
					this.$set(this.resultado_growth, fieldName, { valor: item.growth, mes: item.date, ano: item.year });
				}
			});
		},

		orderedFactorsGrowth() {
			const sortedKeys = Object.keys(this.resultado_growth).sort((a, b) => {
				const dateA = moment(a, 'MMM_YYYY');
				const dateB = moment(b, 'MMM_YYYY');
				return dateA - dateB;
			});

			// Criar um novo objeto ordenado
			const ordered = {};
			sortedKeys.forEach(key => {
				ordered[key] = this.resultado_growth[key].valor;
			});

			return ordered;
		},
	}
};
</script>

<style lang="scss" src="./style.scss" scoped />
