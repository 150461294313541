<template>
	<div class="main">
		<PageComponent :title_nav="'Budget/ForeCast'">
			<div slot="slot-pages" class="content-pages">
				<div class="budget">
					<div class="budget-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'budget' }">
								<font-awesome-icon :icon="['fas', 'arrow-left']" />
								Budget e ForeCast
							</b-breadcrumb-item>

							<b-breadcrumb-item active> Novo Forecast </b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->
						<b-row>
							<b-col md="4" class="col-title">
								<p><strong> ForeCast {{ name_month | capitalize }}/{{ forecast.year }} </strong></p>
							</b-col>
							<b-col md="10" class="col-btns">
								<b-button class="btn-export" @click="getDownload()">
									<font-awesome-icon :icon="['fas', 'file-download']" /> Download
									planilha
								</b-button>
							</b-col>
							<b-col md="2">
								<div class="input-file-container">
									<input type="file" id="csv_file" name="csv_file" :disabled="disabledImport" :style="fieldStyle"
										class="form-control input-file btn-import" @change="handleFileChange" accept=".csv" />
									<label for="csv_file" class="control-label input-file-trigger" :style="fieldStyle">
										<font-awesome-icon :icon="['fas', 'file-upload']" />Importar
										planilha</label>
								</div>
							</b-col>
							<b-col md="12">
								<b-form-input class="input-search" placeholder="Pesquisar" v-model="search"></b-form-input>
							</b-col>
							<b-col md="12">
								<div class="info-forecast">
									<b-row>
										<b-col>
											<span> Dias úteis: {{ valueDays ? valueDays : 0 }} </span>
										</b-col>

										<!-- <b-col>
											<span> -10% </span>
										</b-col> -->
									</b-row>
								</div>
								<div class="panel" v-if="arquivosProntos">
									<div class=" fix-width">
										<table class="table table-responsive">
											<thead>
												<tr>
													<th>Ações</th>
													<th>Código</th>
													<th>Derivação</th>
													<th>Descrição</th>
													<th>Qtde.</th>
													<th>Qtde. <br> Reduzida </th>
													<th>PM</th>
													<th>Reajuste <br> de preço</th>
													<th>PM Novo</th>
													<th>PM LQ</th>
													<th>Custo <br> Ponderado</th>
													<th>Faturamento</th>
													<th>Fat. Liquido</th>
													<th>Custo Total</th>
													<th>Margem Bunzl</th>
													<th>%</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(csv, index) in itemsFiltradosPaginados" :key="index" :class="{ active: active === index }"
													style="cursor: pointer;">

													<td>	
														<b-button style="text-decoration: none; color: black"
															@click="editProduct(index, index, column, csv)" v-if="editingIndex !== index"
															class="btn-edit">
															<h6><font-awesome-icon :icon="['fas', 'edit']" /></h6>
														</b-button>

														<b-button class="btn-check-circle" style="text-decoration: none; color: black"
															@click="saveItem(csv, index)" v-else>
															<h6><font-awesome-icon :icon="['fas', 'check-circle']" /></h6>
														</b-button>

														<b-button class="btn-circle" style="text-decoration: none; color: black" @click="cancelEdit()"
															v-if="editingIndex === index">
															<h6><font-awesome-icon :icon="['fas', 'times-circle']" /></h6>
														</b-button>

													</td>

													<td colspan="">
														<div class="accordion">
															<div header-tag="header" class="p-1" role="tab">
																<b-button block variant="info" class="info-csv">
																	{{ csv.Código }}
																</b-button>
															</div>
														</div>
													</td>
													<td><label for="" class="descricao">{{ csv.Derivação }}</label>
													</td>
													<td>
														<label for="">
															{{ csv.Descrição }}
														</label>
													</td>
													<td>
														<label for="">
															<span v-if="editingIndex !== index">{{ csv.Qtde ? csv.Qtde : '0'
															}}</span>
															<b-form-input v-else v-model="csv.Qtde"></b-form-input>
														</label>
													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{
															csv.Qtde_Reduzida ?
															csv.Qtde_Reduzida : '0' }}</span>
															<b-form-input v-else v-model="csv.Qtde_Reduzida"></b-form-input></label>
													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{ csv.PM ?
															csv.PM : '0' }}</span>
															<b-form-input v-else v-model="csv.PM"></b-form-input></label>
													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{
															csv.Reajuste_preço ?
															csv.Reajuste_preço : '0' }}</span>
															<b-form-input v-else v-model="csv.Reajuste_preço"></b-form-input></label>
													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{ csv.PM_Novo ?
															csv.PM_Novo : '0' }}</span>
															<b-form-input v-else v-model="csv.PM_Novo"></b-form-input></label>

													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{ csv.PM_LQ ?
															csv.PM_LQ : '0' }}</span>
															<b-form-input v-else v-model="csv.PM_LQ"></b-form-input></label>
													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{
															csv.Custo_Ponderado ?
															csv.Custo_Ponderado : '0' }}</span>
															<b-form-input v-else v-model="csv.Custo_Ponderado"></b-form-input></label>
													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{
															csv.Faturamento ?
															csv.Faturamento : '0' }}</span>
															<b-form-input v-else v-model="csv.Faturamento"></b-form-input></label>
													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{
															csv.Fat_Liquido ?
															csv.Fat_Liquido : '0' }}</span>
															<b-form-input v-else v-model="csv.Fat_Liquido"></b-form-input></label>
													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{
															csv.Custo_Total ?
															csv.Custo_Total : '0' }}</span>
															<b-form-input v-else v-model="csv.Custo_Total"></b-form-input></label>
													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{
															csv.Margem_Bunzl ?
															csv.Margem_Bunzl : '0' }}</span>
															<b-form-input v-else v-model="csv.Margem_Bunzl"></b-form-input></label>
													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{ csv.percentage
															?
															csv.percentage : '0' }}</span>
															<b-form-input v-else v-model="csv.percentage"></b-form-input></label>
													</td>

												</tr>
											</tbody>
										</table>
									</div>
								</div>
							
								<div v-else>
									<div class=" fix-width">
										<table class="table table-responsive">
											<thead>
												<tr>
													<th>Ações</th>
													<th>Código</th>
													<th>Derivação</th>
													<th>Descrição</th>
													<th>Qtde.</th>
													<th>Qtde. <br> Reduzida </th>
													<th>PM</th>
													<th>Reajuste <br> de preço</th>
													<th>PM Novo</th>
													<th>PM LQ</th>
													<th>Custo <br> Ponderado</th>
													<th>Faturamento</th>
													<th>Fat. Liquido</th>
													<th>Custo Total</th>
													<th>Margem Bunzl</th>
													<th>%</th>
												</tr>
											</thead>
											<tbody>
												<tr></tr>
											</tbody>
										</table>
									</div>
								</div>
							</b-col>
						</b-row>
						<b-row class="paginations-options1 mt-4" v-if="arquivosProntos">
							<!-- <b-col md="6">
								<span class="mt-3">Resultados {{ currentPage }} de {{ numeroDePaginas }}</span>
							</b-col> -->
							<b-col md="12">
								<b-pagination v-model="paginaAtual" :total-rows="totalItensFiltrados" :per-page="itensPorPagina" @change="paginaMudada" style="float: right;"></b-pagination>
							</b-col>
						</b-row>

						<b-row class="paginations-options1 mt-4" v-else>
							<!-- <b-col md="6">
								<span class="mt-3">Resultados 1 de 0</span>
							</b-col> -->
							<b-col md="12">
								<b-pagination pills>
								</b-pagination>
							</b-col>
						</b-row>


						<b-modal ref="import-sell-progress" hide-footer title="Importar" hide-header-close id="ModalProgress">
							<!-- <div v-if="progresso < 100"> -->
							<div>
								<p> <span class="loader"></span> Processando...</p>
								<div class="progress-bar" style="background-color:rgb(156, 157, 160)">
									<div :style="{ width: progresso + '%' }" style="background-color:rgb(75, 114, 221)"> <span> {{ progresso
									}}% </span> </div>
								</div>
							</div>
						</b-modal>
					</div>
				</div>
			</div>
		</PageComponent>
	</div>
</template>
<style>
.loader {
	width: 20px !important;
	height: 20px !important;
	border: 5px solid rgb(105, 68, 240) !important;
	border-bottom-color: rgb(243, 243, 243) !important;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

/* Estilo para a barra de progresso */
.progress-bar {
	width: 100%;
	height: 20px;
	background-color: #f0f0f0;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-top: 10px;
}

/* Estilo para a parte preenchida da barra de progresso */
.progress-bar div {
	height: 100%;
	background-color: #007bff;
	border-radius: 5px;
	transition: width 0.3s ease-in-out;
	color: white;
}

.import_manual {
	/* padding: 5px !important; */
	width: 100%;
	cursor: pointer !important;
}

#import_stock {
	/* padding: 15px 10px; */
	/* padding: 5px !important; */
	cursor: pointer !important;
	border-radius: 5px;
	border: 1px solid #198754;
	width: 100%;
	color: #198754;
	padding: 6px 2px;
}

#file-small {
	display: none;
	padding: 5px 4px;
	cursor: pointer !important;
}

.custom-file-input {
	cursor: pointer !important;
}

.form-file-text {
	cursor: pointer !important;
	pointer-events: visible !important;
}

.label_import {
	padding: 6px 10px;
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid #198754;
	color: #198754;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>
<script>
import PageComponent from "../page/PageComponent.vue";
import moment from 'moment'

export default {
	name: "ExportBudgetComponent",
	components: {
		PageComponent,
	},
	data() {
		return {
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			perPage: 6,
			currentPage: 1,
			data: "",
			collapsed: true,
			channel_name: "",
			channel_fields: [],
			channel_entries: [],
			parse_header: [],
			parse_csv: [],
			sortOrders: {},
			sortKey: "",
			active: null,
			editingIndex: -1,
			disabled: false,
			openIndex: null,
			budgetId: '',
			itemsPerPage: 5, // Quantidade de itens por página
			result: 0,
			per: 0,
			disabledImport: false,
			budget_id: this.$route.params.id,
			forecast_id: this.$route.params.id,
			forecast: "",
			name_month: "",
			qtde: "",
			qtde_reduzida: "",
			pm: "",
			pm_novo: "",
			pm_lq: "",
			reajuste_preco: "",
			custo_ponderado: "",
			faturamento: "",
			fat_liquido: "",
			custo_total: "",
			Margem_Bunzl: "",
			percentage: "",
			valueDays: 0,
			code_prod: "",
			der_prod: "",
			desc_prod: "",
			progresso: 0,
			itensProcessados: [],
			processando: false,
			currentYear: new Date().getFullYear(),
			arquivosProntos: false,
			paginaAtual: 1,
			itensPorPagina: 5,
			search: ''
		}

	},

	mounted() {
		this.getForecastProducts();
		this.findForecast();
		// this.savePlanilha().then(() => {
		// 	this.arquivosProntos = true;
		// });
	},

	methods: {
		async findForecast() {
			if (this.forecast_id) {
				await this.$http
					.get(`/forecast/${this.forecast_id}`)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							this.forecast = response.data.forecast;
							this.name_month = moment(this.forecast.month, 'M').locale('pt-BR').format('MMMM');
							this.findDaysMonthBudget(this.forecast.year);
						}
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		},

		async findDaysMonthBudget(forecast) {
			await this.$http
				.get(`/forecast/${forecast}/budget/months`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						console.log("response.data.days_months")
						let mes = 'n_' + moment(this.forecast.month, 'M').locale('pt-BR').format('MMM')
						let days_months = response.data.days_months;

						this.valueDays = days_months[mes]

						console.log(this.valueDays)
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		itemIsOpen(index) {
			return this.openIndex === index;
		},

		editItem(index) {
			this.editingIndex = index;
		},

		editProduct(item, index, column, prod) {
			this.editingIndex = index;
			this.disabled = true;
			console.log(prod);
		},

		cancelEdit() {
			this.editingIndex = -1;
			this.disabled = false;
		},

		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		async getDownload() {
			await this.$http
				.get(`/forecast/downloadCsvForecast`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						// const data = response.data;
						const dataWithCustomColumns = this.addCustomColumns(response.data);
						this.downloadCSV(dataWithCustomColumns);

						console.log(response.data);
						this.data = response.data;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		downloadCSV(data) {
			const csvData = this.convertToCSV(data);
			const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
			const link = document.createElement("a");
			link.setAttribute("href", URL.createObjectURL(blob));
			link.setAttribute("download", "FORECAST - Planilha para importação.csv");
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},

		convertToCSV(data) {
			const csvArray = [];
			const columnHeaders = Object.keys(data[0]);
			csvArray.push(columnHeaders.join(";"));

			data.forEach((item) => {
				const values = columnHeaders.map((header) => item[header]);
				csvArray.push(values.join(";"));
			});

			return csvArray.join("\n");
		},

		addCustomColumns(data) {
			return data.map((item) => ({
				...item,
				"Qtde": ``,
				"Qtde. Reduzida": ``,
				"PM": ``,
				"Reajuste de preço": ``,
				"PM Novo": ``,
				"PM LQ": ``,
				"Custo Ponderado": ``,
				"Faturamento": ``,
				"Fat. Liquido": ``,
				"Custo Total": ``,
				"Margem Bunzl": ``,
				"%": ``,
			}));
		},

		sortBy: function (key) {
			var vm = this;
			vm.sortKey = key;
			vm.sortOrders[key] = vm.sortOrders[key] * -1;
		},

		csvJSON(csv) {
			var vm = this;
			var lines = csv.split("\n");
			var result = [];
			var headers = lines[0].split(";");
			vm.parse_header = lines[0].split(";");
			lines[0].split(";").forEach(function (key) {
				vm.sortOrders[key] = 1;
			});

			lines.map(function (line, indexLine) {
				if (indexLine < 1) return;

				var obj = {};
				var currentline = line.split(";");

				headers.map(function (header, indexHeader) {
					obj[header] = currentline[indexHeader];
				});

				result.push(obj);
			});

			result.pop();

			console.log("result")
			console.log(result)

			result.forEach((item) => {
				this.qtde = item.Qtde;
				this.qtde_reduzida = item['Qtde. Reduzida'] ? item['Qtde. Reduzida'] : '0';
				this.pm = item['PM'] ? item['PM'] : '0';
				this.reajuste_preco = item['Reajuste de preço'] ? item['Reajuste de preço'] : '0';
				this.pm_lq = item['PM LQ'] ? item['PM LQ'] : '0';
				this.pm_novo = item['PM Novo'] ? item['PM Novo'] : '0';
				this.custo_ponderado = item['Custo Ponderado'] ? item['Custo Ponderado'] : '0';
				this.faturamento = item['Faturamento'] ? item['Faturamento'] : '0';
				this.fat_liquido = item['Fat. Liquido'] ? item['Fat. Liquido'] : '0';
				this.custo_total = item['Custo Total'] ? item['Custo Total'] : '0';
				this.Margem_Bunzl = item['Margem Bunzl'] ? item['Margem Bunzl'] : '0';
				this.percentage = item['%'] ? item['%'] : '0';


				let formData = new FormData();

				formData.append("Código", item.Código);
				formData.append("name", item.Descrição);
				formData.append("Derivação", item.Derivação);
				formData.append("Descrição", item.Descrição);
				formData.append("Qtde", item['Qtde'] ? item['Qtde'] : 0);
				formData.append("Qtde_Reduzida", item['Qtde. Reduzida'] ? item['Qtde. Reduzida'] : 0);
				formData.append("Reajuste_preço", item['Reajuste de Preço'] ? item['Reajuste de Preço'] : 0);
				formData.append("PM_Novo", item['PM Novo'] ? item['PM Novo'] : 0);
				formData.append("PM_LQ", item['PM LQ'] ? item['PM LQ'] : 0);
				formData.append("PM", item['PM'] ? item['PM'] : 0);
				formData.append("Custo_Ponderado", item['Custo Ponderado'] ? item['Custo Ponderado'] : 0);
				formData.append("Faturamento", item['Faturamento'] ? item['Faturamento'] : 0);
				formData.append("Fat_Liquido", item['Fat. Liquido'] ? item['Fat. Liquido'] : 0);
				formData.append("Custo_Total", item['Custo Total'] ? item['Custo Total'] : 0);
				formData.append("Margem_Bunzl", item['Margem Bunzl'] ? item['Margem Bunzl'] : 0);
				formData.append("percentage", item['%'] ? item['%'] : 0);

				formData.append("forecast_id", this.forecast.id);
				formData.append("budget_id", this.forecast.budget_id);
				formData.append("user", this.userLogged.id);

				this.$http
					.post(`/forecast/store/product`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							this.getForecastProducts();
							// this.getBudgets();
							// this.goSuccess(response.data.message);
						}
					})
					.catch(function (error) {
						console.log(error);
					});
			})

			this.goSuccess("Planilha importada com sucesso!!");


			return result;
		},
		loadCSV(e) {
			var vm = this;
			if (window.FileReader) {
				var reader = new FileReader();
				reader.readAsText(e.target.files[0]);
				// Handle errors load
				reader.onload = function (event) {
					var csv = event.target.result;
					vm.parse_csv = vm.csvJSON(csv);
				};
				reader.onerror = function (evt) {
					if (evt.target.error.name == "NotReadableError") {
						alert("Canno't read file !");
					}
				};
			} else {
				alert("FileReader are not supported in this browser.");
			}
		},
        readFile(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = event => resolve(event.target.result);
                reader.onerror = error => reject(error);
                reader.readAsText(file);
            });
        },
		async handleFileChange(event) {
            const file = event.target.files[0];
            if (!file) return;
			this.showModalSellImpo();

            const text = await this.readFile(file);
            const lines = text.split('\n');

            if (lines.length > 0) {
                this.columns = lines[0].split(';');
                this.rows = lines.slice(1).map(line => line.split(';'));

                this.rows.forEach((item) => {
                    item.forEach((rows, inx) => {

                        if (inx == 0) {
                            this.code_prod = rows
                        }

                        if (inx == 1) {
                            this.der_prod = rows
                        }
                        if (inx == 2) {
                            this.desc_prod = rows
                        }
                        if (inx == 3) {
                            this.qtde = rows
                        }
                        if (inx == 4) {
                            this.qtde_reduzida = rows
                        }
                        if (inx == 5) {
                            this.pm = rows
                        }
                        if (inx == 6) {
                            this.reajuste_preco = rows
                        }
                        if (inx == 7) {
                            this.pm_novo = rows
                        }
                        if (inx == 8) {
                            this.pm_lq = rows
                        }
                        if (inx == 9) {
                            this.custo_ponderado = rows
                        }
                        if (inx == 10) {
                            this.faturamento = rows
                        }
                        if (inx == 11) {
                            this.fat_liquido = rows
                        }
                        if (inx == 12) {
                            this.custo_total = rows
                        }
                        if (inx == 13) {
                            this.Margem_Bunzl = rows
                        }
                        if (inx == 14) {
                            this.percentage = rows
                        }
                    })

                    // if (item[0] != '' || item[1] != '' || item[2] != '' || item[3] != '' || item[4] != '' || item[5] != '' || item[6] != '' || item[7] != '' || item[8] != '' || item[9] != '' || item[10] != '' ||
					// item[11] != '' || item[12] != '' || item[13] != '' || item[14] != '') {
                        this.parse_csv.push({
                            'Código': this.code_prod, 'Derivação': this.der_prod, 'Descrição': this.desc_prod, 'Qtde': this.qtde,
                            'Qtde. Reduzida': this.qtde_reduzida, 'PM': this.pm, 'Reajuste de preço': this.reajuste_preco, 'PM Novo': this.pm_novo,
                            'PM LQ': this.pm_lq, 'Custo Ponderado': this.custo_ponderado, 'Faturamento': this.faturamento, 'Fat. Liquido': this.fat_liquido, 'Custo Total': this.custo_total,
							'Margem Bunzl': this.Margem_Bunzl, '%': this.percentage
                        });

						// console.log(this.parse_csv.length)

                    // }
                    // console.log("this.parse_csv--------------")
                    // console.log(this.parse_csv)
                })


				for (let i = 0; i < this.parse_csv.length -1; i++) {
					const sell = this.parse_csv[i];
					this.savePlanilha(sell);

					this.progresso = 0;
					this.itensProcessados = [];
					this.processando = true;

					const totalItens = this.parse_csv.length;

					const processarItem = (index) => {
						if (index >= totalItens) {
							this.progresso = 100;
							this.processando = false;
							return;
						}

						setTimeout(() => {
							this.itensProcessados.push(this.parse_csv[index]);
							this.progresso = Math.round(((index + 1) / totalItens) * 100);
							processarItem(index + 1);

							if(this.progresso == 100){
								this.arquivosProntos = true;
								this.$refs["import-sell-progress"].hide();
								this.getForecastProducts();
							}
						}, 1000);
					};
					processarItem(0);
				}

				// this.parse_csv.forEach((item) => {
				// 		this.savePlanilha(item, this.parse_csv.length);
				// });
            }
        },
		showModalSellImpo() {
			this.$refs["import-sell-progress"].show();
		},
		async savePlanilha(item) {

			// items.forEach((item) => {
				this.qtde = item.Qtde;
				this.qtde_reduzida = item['Qtde. Reduzida'] ? item['Qtde. Reduzida'] : '0';
				this.pm = item['PM'] ? item['PM'] : '0';
				this.reajuste_preco = item['Reajuste de preço'] ? item['Reajuste de preço'] : '0';
				this.pm_lq = item['PM LQ'] ? item['PM LQ'] : '0';
				this.pm_novo = item['PM Novo'] ? item['PM Novo'] : '0';
				this.custo_ponderado = item['Custo Ponderado'] ? item['Custo Ponderado'] : '0';
				this.faturamento = item['Faturamento'] ? item['Faturamento'] : '0';
				this.fat_liquido = item['Fat. Liquido'] ? item['Fat. Liquido'] : '0';
				this.custo_total = item['Custo Total'] ? item['Custo Total'] : '0';
				this.Margem_Bunzl = item['Margem Bunzl'] ? item['Margem Bunzl'] : '0';
				this.percentage = item['%'] ? item['%'] : '0';

				let formData = new FormData();

				formData.append("Código", item.Código);
				formData.append("name", item.Descrição);
				formData.append("Derivação", item.Derivação);
				formData.append("Descrição", item.Descrição);
				formData.append("Qtde", item['Qtde'] ? item['Qtde'] : 0);
				formData.append("Qtde_Reduzida", item['Qtde. Reduzida'] ? item['Qtde. Reduzida'] : 0);
				formData.append("Reajuste_preço", item['Reajuste de Preço'] ? item['Reajuste de Preço'] : 0);
				formData.append("PM_Novo", item['PM Novo'] ? item['PM Novo'] : 0);
				formData.append("PM_LQ", item['PM LQ'] ? item['PM LQ'] : 0);
				formData.append("PM", item['PM'] ? item['PM'] : 0);
				formData.append("Custo_Ponderado", item['Custo Ponderado'] ? item['Custo Ponderado'] : 0);
				formData.append("Faturamento", item['Faturamento'] ? item['Faturamento'] : 0);
				formData.append("Fat_Liquido", item['Fat. Liquido'] ? item['Fat. Liquido'] : 0);
				formData.append("Custo_Total", item['Custo Total'] ? item['Custo Total'] : 0);
				formData.append("Margem_Bunzl", item['Margem Bunzl'] ? item['Margem Bunzl'] : 0);
				formData.append("percentage", item['%'] ? item['%'] : 0);

				formData.append("forecast_id", this.forecast.id);
				formData.append("budget_id", this.forecast.budget_id);
				formData.append("user", this.userLogged.id);

				this.$http
					.post(`/forecast/store/product`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {

							// this.goSuccess(response.data.message);
						}
					})
					.catch(function (error) {
						console.log(error);
					});
			// })

			// this.goSuccess("Planilha importada com sucesso!!");
		},

		async getForecastProducts() {
			await this.$http
				.get(`/forecast/products/${this.forecast_id}`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						if (response.data.forecast_products.length > 0) {
							this.disabledImport = true;
							this.arquivosProntos = true;
						} else {
							this.disabledImport = false;
						}

						this.parse_csv = response.data.forecast_products;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async saveItem(item) {
			this.editingIndex = -1;

			this.disabled = false;

			console.log(item)
			let formData = new FormData();

			formData.append("Código", item.Código);
			formData.append("name", item.Descrição);
			formData.append("Derivação", item.Derivação);
			formData.append("Descrição", item.Descrição);
			formData.append("Qtde", item['Qtde'] ? item['Qtde'] : 0);
			formData.append("Qtde_Reduzida", item.Qtde_Reduzida ? item.Qtde_Reduzida : 0);
			formData.append("Reajuste_preço", item.Reajuste_preço ? item.Reajuste_preço : 0);
			formData.append("PM_Novo", item.PM_Novo ? item.PM_Novo : 0);
			formData.append("PM_LQ", item.PM_LQ ? item.PM_LQ : 0);
			formData.append("PM", item.PM ? item.PM : 0);
			formData.append("Custo_Ponderado", item.Custo_Ponderado ? item.Custo_Ponderado : 0);
			formData.append("Faturamento", item.Faturamento ? item.Faturamento : 0);
			formData.append("Fat_Liquido", item.Fat_Liquido ? item.Fat_Liquido : 0);
			formData.append("Custo_Total", item.Custo_Total ? item.Custo_Total : 0);
			formData.append("Margem_Bunzl", item.Margem_Bunzl ? item.Margem_Bunzl : 0);
			formData.append("percentage", item.percentage ? item.percentage : 0);

			formData.append("id", item.id);
			formData.append("id_forecast", item.forecast_id);

			this.$http
				.post(`/forecast/update/product`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
						this.getForecastProducts();
					}
				})
				.catch(function (error) {
					console.log(error);
				});

		},

		changePage(page) {
			this.currentPage = page;
		},

	},

	computed: {
		totalPages() {
			return Math.ceil(this.parse_csv.length / this.itemsPerPage);
		},
		paginatedItems() {
			const startIndex = (this.currentPage - 1) * this.itemsPerPage;
			const endIndex = startIndex + this.itemsPerPage;
			return this.parse_csv.slice(startIndex, endIndex);
		},
		fieldStyle() {
			return this.disabledImport ? { backgroundColor: 'lightgray !important', color: 'gray' } : {};
		},

		fieldsBudget: function () {
			let fields = [
				{
					key: "code",
					label: "Código",
				},

				{
					key: "description",
					label: "Descrição",
				},

				{
					key: "forecast",
					label: "Forecast",
				},

				{
					key: "jan",
					label: "Jan",
				},

				{
					key: "fev",
					label: "Fev",
				},

				{
					key: "mar",
					label: "Mar",
				},

				{
					key: "abr",
					label: "Abr",
				},

				{
					key: "mai",
					label: "Mai",
				},

				{
					key: "jun",
					label: "Jun",
				},

				{
					key: "jul",
					label: "Jul",
				},

				{
					key: "ago",
					label: "Ago",
				},

				{
					key: "set",
					label: "Set",
				},

				{
					key: "out",
					label: "Out",
				},

				{
					key: "nov",
					label: "Nov",
				},

				{
					key: "dez",
					label: "Dez",
				},

				{
					key: "actions",
					label: "Ações",
				},
			];
			return fields;
		},

		colSpan() {
			return this.parse_csv.length + 1;
		},
		numeroDePaginas() {
			return Math.ceil(this.parse_csv.length / this.perPage);
		},

		totalItensFiltrados() {
			return this.itemsFiltrados.length;
		},
		itemsFiltrados() {
		// Filtrar a lista de itens com base no texto inserido no campo de filtro
			return this.parse_csv.filter(item => item.Código.toLowerCase().includes(this.search.toLowerCase()));
		},
		itemsFiltradosPaginados() {
			const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
			const fim = inicio + this.itensPorPagina;
			return this.itemsFiltrados.slice(inicio, fim);
		},
	},
	filters: {
		capitalize: function (str) {
			return str.charAt(0).toUpperCase() + str.slice(1);
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
