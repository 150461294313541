<template>
  <div class="stock">
    <!-- PRIMEIRO CARD COM GRAFICO -->
    <b-card class="card-chart1">
      <b-row>
        <b-col class="chart-stock">
          <chartComponentVue   style="display: none;"
            :chart-data="datacollection"
            :options="options"
            width="10px"
            height="10px"
          />
          <p>Custo total do estoque</p>
          <p class="value"><span>{{ totalStock }}</span></p>
        </b-col>

        <b-col class="chart-stock">
          <chartComponentVue   style="display: none;"
            :chart-data="dataCobertura"
            :options="options"
            width="10px"
            height="10px"
          />
          <p>Nível de cobertura atual</p>
          <p class="value">
            <span v-if="totalMedia"> {{ totalMedia | format_number }} meses </span>
          </p>
        </b-col>
      </b-row>
    </b-card>
    <!-- SEGUNDO CARD COM GRAFICO -->
    <b-card class="card-chart2">
      <b-row>
        <b-col md="8" class="title-card">
          <div v-if="isLoading" class="spinner-container">
            <div class="spinner"></div>
          </div>
          <p>Distribuição atual do estoque</p>
          <span class="text-muted">Atualização em {{ lastUpdateStock.dt_approved | dateFormat}}</span>
        </b-col>

        <b-col md="6">
          <p class="deposito">Depósito</p>
          <!-- {{dataDistribuicao.colors}} -->
          <!-- <p class="legend" v-for="item in listDeposit" :key="item">
            <span class="item-color" v-for="item in colors" :key="item" :style="{ backgroundColor: item }">.</span>
            <span>
              {{ item }}
            </span>
          </p> -->

          <div class="legend">
            <div v-for="(data, index) in chartData" :key="index" class="legend-item">
              <div class="legend-color" :style="{ backgroundColor: data.color }"></div>
              <div class="legend-label">{{ data.label }}</div>
            </div>
          </div>
          <p class="legend" v-for="(item, index) in liststocks" :key="index">
            <span class="item-color" :style="{ backgroundColor: colors[index] }">.</span>
            <span>{{ item.name_collator }}</span>
          </p>
        </b-col>

        <b-col md="6">
          <pieChart
            :chart-data="dataDeposit"
            :options="optionsPie"
            height="100"
            width="250"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- TERCEIRO CARD COM GRAFICO -->
    <b-card class="card-chart3">
      <b-row>
        <b-col md="6">
          <p>Cobertura média do estoque</p>
        </b-col>

        <b-col md="6">
          <p class="value-estoque" v-if="totalMedia">{{ totalMedia | format_number }} meses</p>
        </b-col>
      </b-row>

      <!-- <p class="value-percent"><font-awesome-icon :icon="['fas', 'chevron-up']" />  +4,6%</p>
      <span class="text-muted"> Maior que mês passado</span> -->
      <br />
      <span class="month-currenty"> <label for="">.</label> Mês atual</span>
      <barChart
        :chart-data="dataCoberturaMedia"
        :options="optionsBar"
        height="100"
      />
    </b-card>
  </div>
</template>

<script>
import chartComponentVue from "./charts/chartComponent";
import pieChart from "./charts/pieChartComponent";
import barChart from "./charts/barChartComponent";
import dataCusto from "./jsons/custo.json";
import dataCobertura from "./jsons/cobertura.json";
import dataDistribuicao from "./jsons/distribuicao.json";
import dataCoberturaMedia from "./jsons/coberturaMedia.json";
import moment from "moment";

export default {
  name: "StockComponent",
  components: {
    chartComponentVue,
    pieChart,
    barChart,
  },

  data() {
    return {
      datacollection: dataCusto,
      dataCobertura: dataCobertura,
      dataDistribuicao: dataDistribuicao,
      dataCoberturaMedia: [],
      dataDeposit: [],

      textLabels: dataDistribuicao.legend,

      options: {
        responsive: true,
        legend: {
          display: false,
          position: "bottom",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: () => "",
              },
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: () => "",
              },

              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
        },

        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              return data["labels"][tooltipItem[0]["index"]];
            },
            label: function (tooltipItem, data) {
              return data["datasets"][0]["data"][tooltipItem["index"]];
            },
          },
          backgroundColor: "#FFF",
          titleFontSize: 16,
          titleFontColor: "#0066ff",
          bodyFontColor: "#000",
          bodyFontSize: 14,
          displayColors: false,
        },
      },

      selected: "porcentagem",

      optionsBar: dataCoberturaMedia.optionsBar,
      myData: [],
      myColors: "",

      lastUpdateStock: "",
      listDeposit: [],
      colors: [],
      liststocks: [],
      totalStock: "",
      medias: [],
      totalMedia: '',

      isLoading: false
    };
  },

  mounted() {
    this.isLoading = true;
    this.getDepositQtde();
    // this.getDeposit();
    this.getChartMedia();
    this.getLastStock();
    this.getTotalStock();
  },

  methods: {
    generateRandomColor() {
      // Gera uma cor aleatória hexadecimal
      return "#" + Math.floor(Math.random() * 16777215).toString(16);
    },
    getChartCoberturaMedia() {
      this.dataCoberturaMedia = {
        labels: this.medias.map((item) => item.data),

        datasets:[
          {
            "data": this.medias.map((item) => item.valor),
            "label": "Cobertura média do estoque",
            "fill": false,
            "borderWidth": 2,
            "backgroundColor": ["#343C87", "#343C87", "#343C87", "#F6AC33", "#42C6BE", "#42C6BE", "#42C6BE", "#42C6BE", "#42C6BE", "#42C6BE", "#42C6BE", "#42C6BE", "#42C6BE", "#42C6BE", "#42C6BE", "#42C6BE"]
          }
        ]
      };
    },

    async getTotalStock(){
      await this.$http
        .get(`/processes/total_stock`)
        .then((response) => {
          this.totalStock = response.data.total_stock;
          })
        .catch((error) => {
          console.log(error.response);
                  });
    },

    async getLastStock(){
      await this.$http
        .get(`/processes/last_update`)
        .then((response) => {
          this.lastUpdateStock = response.data.last_stock;
        })
        .catch((error) => {
          console.log(error.response);
                  });
    },

    async getDeposit(){
      await this.$http
        .get(`/stockCollator/deposits`)
        .then((response) => {
          this.listDeposit = response.data.listDeposit;
          this.getChartDeposits();
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    async getDepositQtde(){
      await this.$http
        .get(`/stockCollator/quantity`)
        .then((response) => {
          this.liststocks = response.data.liststocks;
          this.getDeposit();
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    async getChartMedia() {
      let formData = new FormData();

      await this.$http
        .post(`/processes/chart/stock/media`, formData)
        .then((response) => {
          this.medias = response.data.medias;
          this.totalMedia = response.data.totalMedia;
          this.getChartCoberturaMedia();
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    getChartDeposits() {
      this.colors = this.liststocks.map(() => this.generateRandomColor());

      this.dataDeposit = {
        labels: this.liststocks.map((item) => item.name_collator),

        datasets: [
          {
            "data": this.liststocks.map((item) => item.quantidade_total),
            "label": "Depósito",
            "backgroundColor": this.colors,
            "fill": false,
            "borderWidth": 7,
            "datalabels": {
              "font": {
                "size": 16
              },
              "color": "black"
            }
          }
        ],
    
      };
      this.isLoading = false;

    },
  },
  filters: {
    dateFormat: function (date) {
        return moment(date).format("DD/MM/YYYY");
    },
    format_number: function (value){
        return parseInt(value).toLocaleString()
    },
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />
