<template>
	<div class="main">
		<PageComponent :title_nav="'Cenários e simulações'">
			<div slot="slot-pages" class="content-pages">
				<div class="products">
					<div class="products-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'dashboard' }" active>
								<font-awesome-icon :icon="['fas', 'arrow-left']" />
								Cenários e simulações
							</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->

						<b-row class="mt-5">
							<b-col md="7">
								<h5>Listagem de cenários</h5>
							</b-col>

							<b-col md="" class="col-add">
								<b-form-select @change="orderProducts" class="select-sort" v-model="selectedSort"
									:options="options"></b-form-select>
							</b-col>

							<b-col md="2" class="col-add" v-if="userLoggedPermission.permissionscenariom == 1 || userLogged.role == 'Administrador'">
								<b-button class="button-padrao-add" :to="{ name: 'scenarios-simulations-create' }">
									Novo cenário
								</b-button>
							</b-col>

							<b-col md="12" class="col-search">
								<div class="form">
									<b-form-input placeholder="Procurar" v-model="search"></b-form-input>
									<button type="submit">Search</button>
								</div>
							</b-col>

							<b-col md="12" class="col-table">
								<b-card>
									<b-table :items="itemsFiltradosPaginados" :fields="fieldsProduct" responsive="" :per-page="perPage"
										:current-page="currentPage" class="table-participants">

										<template #cell(name)="data">
											<p>
												{{ data.item.name }}
											</p>
										</template>


										<template #cell(dateRef)="data">
											<p>
												{{ data.item.dateRef | dateFormat }}
											</p>
										</template>


										<template #cell(user)="data">
											<p>
												{{ data.item.user }}
											</p>
										</template>

										<template #cell(dateCreate)="data">
											<p>
												{{ data.item.dateCreate | dateFormat }}
											</p>
										</template>

										<template #cell(actions)="data">
											<p v-if="userLoggedPermission.permissionscenariom == 1 || userLogged.role == 'Administrador'"> 
												<b-button @click="editScenario(data.item)"
													style="text-decoration: none; color: black">
													<h6><font-awesome-icon :icon="['fas', 'edit']" /></h6>
												</b-button>

												<b-button @click="deleteScenario(data.item.id)">
													<h6><font-awesome-icon :icon="['fas', 'fa-trash-alt']" /></h6>
												</b-button>

												<b-button @click="cloneScenario(data.item)" v-b-tooltip.hover
													:title="'Duplicar'">
													<h6><font-awesome-icon :icon="['fas', 'fa-clone']" /></h6>
												</b-button>

												<b-button @click="playSimulation(data.item)">
													<h6><font-awesome-icon :icon="['fas', 'fa-play']" /></h6>
												</b-button>

											</p>

										</template>
									</b-table>
								</b-card>
							</b-col>

						</b-row>

						<b-rown class="paginations-options1 mt-4" :hidden="hiddenList">
							<!-- <b-col>
								<span class="mt-3">Resultados {{ currentPage }} de {{ numeroDePaginas }}</span>
							</b-col> -->
							<b-col md="12" class=" mt-4">
								<b-pagination v-model="paginaAtual" :total-rows="totalItensFiltrados" :per-page="itensPorPagina" @change="paginaMudada" style="float: right;"></b-pagination>
							</b-col>
						</b-rown>

					</div>
				</div>
			</div>
		</PageComponent>

	</div>
</template>

<script>
import PageComponent from "../page/PageComponent.vue";
import moment from "moment";

export default {
	name: "ScenariosIndexComponent",
	components: {
		PageComponent,
	},
	data() {
		return {
			selected: {},

			currentRouteName: "white",
			selectedSort: null,
			perPage: 6,
			currentPage: 1,
			search: '',
			scenarios: [],
			options: [
				{ value: null, text: 'Ordenar por' },
				{ value: 'desc', text: 'Mais recentes' },
				{ value: 'asc', text: 'Mais antigos' },
			],
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			userLoggedPermission: localStorage.getItem("userPermission")
            ? JSON.parse(localStorage.getItem("userPermission"))
            : null, 
			paginaAtual: 1,
			itensPorPagina: 5,
		};
	},

	mounted() {
		this.getScenarios()
	},

	methods: {
		async playSimulation(simulation){
			console.log(simulation);
			if(simulation.simulation_id == ''){
				this.goError("Sem simulação padrão cadastrado!");
			} else {
				this.$router.push(`/scenarios/${simulation.id}/simulations/${simulation.simulation_id}/s&op`);
			}
		},
		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		noView(item) {
			console.log(item)
		},

		sendEst(item) {
			this.$router.push(`/products/${item.id}/est-seg`);
		},

		sendLeadProduct(item) {
			this.$router.push(`/products/${item.id}/config/lead`);
		},

		async orderProducts() {
			const formData = new FormData();
			formData.append("order", this.selectedSort);

			this.$http
				.post(`/scenarios/filter`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
						this.scenarios = response.data.scenarios;
					}
				})
				.catch((error) => {
					this.goError(error.response.data.message);
					console.log(error.response.data.message);
				});

		},
		async getScenarios() {
			await this.$http
				.get(`/scenarios`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.scenarios = response.data.scenarios;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async editScenario(item) {
			console.log(item)
			this.$router.push(`/scenarios/simulations/edit/${item.id}`);
		},

		async deleteScenario(id) {
			const formData = new FormData();
			formData.append("id", id);

			this.$http
				.post(`/scenarios/delete`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
						this.getScenarios();
					}
				})
				.catch((error) => {
					this.goError(error.response.data.message);
					console.log(error.response.data.message);
				});
		},

		async cloneScenario(item) {
			const formData = new FormData();
			formData.append("name", item.name);
			formData.append("observation", item.observation);
			formData.append("user_id", this.userLogged.id);
			formData.append("dateRef", item.dateRef);
			formData.append("dateCreate", moment(item.dateCreate).format("DD/MM/YYYY"));

			this.$http
				.post(`/scenarios/store`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
						this.getScenarios();
						this.dataObj = {}
					}
				})
				.catch((error) => {
					this.goError(error.response.data.message);
					console.log(error.response.data.message);
				});
		}

	},

	computed: {
		filteredList() {
			return this.scenarios.filter((stock) => {
				return stock.name.toLowerCase().includes(this.search.toLowerCase());
			});
		},
		filteredListStock() {
			return this.stockCollators.filter((stock) => {
				return stock.name.toLowerCase().includes(this.searchAdd.toLowerCase());
			});
		},
		fieldsProduct: function () {
			let fields = [
				{ key: "name", label: "Nome" },
				{ key: "dateRef", label: "Data de referência" },
				{ key: "user", label: "Usuário" },
				{ key: "dateCreate", label: "Data de criação" },
				{ key: "actions", label: "Ações" },
			];
			return fields;
		},

		fields: function () {
			let fields = [
				{
					key: "name",
					label: "Nome",
				},
				{ key: "sort", label: "Ordem" },
				{ key: "observation", label: "Observação" },
				{ key: "action", label: "" },
			];
			return fields;
		},

		paginatedCards() {
			const { currentPage, perPage } = this;
			const start = (currentPage - 1) * perPage;
			const end = currentPage * perPage;

			return this.scenarios.slice(start, end);
		},
		numeroDePaginas() {
			return Math.ceil(this.scenarios.length / this.perPage);
		},

		totalItensFiltrados() {
			return this.itemsFiltrados.length;
		},
		itemsFiltrados() {
		// Filtrar a lista de itens com base no texto inserido no campo de filtro
			return this.scenarios.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()));
		},
		itemsFiltradosPaginados() {
			const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
			const fim = inicio + this.itensPorPagina;
			return this.itemsFiltrados.slice(inicio, fim);
		},
	},

	filters: {
		dateFormat: function (date) {
			return moment(date).format("DD/MM/YYYY");
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
