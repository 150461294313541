/** @format */

import Vue from "vue";
import axios from "axios";

//axios.defaults.baseURL = "https://api-volk.gebit.io/api";
//axios.defaults.baseURL = "http://127.0.0.1:8000/api/";
axios.defaults.baseURL = "https://api.volkdobrasil.com.br/api";

Vue.use({
  install(Vue) {
    Vue.prototype.$http = axios;
    const token = localStorage.getItem("authToken");
    if (token) {
      Vue.prototype.$http.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      Vue.prototype.$http.defaults.headers.common["Content-Type"] =
        "application/json";
      Vue.prototype.$http.defaults.headers.common["Content-Type"] =
        "multipart/form-data";
      Vue.prototype.$http.defaults.headers.common["Content-Type"] =
        "application/xml";
      Vue.prototype.$http.defaults.headers.common[
        "Access-Control-Allow-Headers"
      ] =
        "Origin, Accept, Content-Type, Authorization, Access-Control-Allow-Origin";
      Vue.prototype.$http.defaults.headers.common[
        "Access-Control-Allow-Origin"
      ] = "*";
      Vue.prototype.$http.defaults.headers.common["allowedMethods"] = "*";
      Vue.prototype.$http.defaults.headers.common["X-Requested-With"] =
        "XMLHttpRequest";
    }
  },
});
